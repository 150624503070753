<template>
  <basic-container>
    <avue-crud :option="option" :data="data" :page.sync="page" ref="crud" v-model="form"
      :permission="permissionList"  :before-open="beforeOpen"
      @search-change="searchChange" @search-reset="searchReset" @refresh-change="refreshChange"
      @selection-change="selectionChange" @on-load="onLoad">
      <!-- <template slot="menuLeft">
        <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.qa_qa_delete"
          @click="handleDelete">删 除
        </el-button>
      </template> -->
      <template slot-scope="scope" slot="menu">
        <el-button type="text" icon="el-icon-s-operation" size="small" @click.native="toItem(scope.row)">内容编辑
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getListTypeTemplate, getDetail } from '@/api/sys/cookbook'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      params: {},
      selectionList: [],
      option: {
        addBtn: false,
        editBtn: false,
        addRowBtn: false,
        cellBtn: true,
        tip: false,
        delBtn: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        menuWidth: 300,
        column: [
          {
            label: '食谱名称',
            prop: 'recipeName',
            cell: true,
            rules: [{
              required: true,
              message: '请输入模板名称'
            }]
          },
          {
            label: '类型名称',
            prop: 'typeName',
            cell: true,
            rules: [{
              required: true,
              message: '请输入方式名称'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            cell: false
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qa_add,
        viewBtn: this.permission.qa_qa_view,
        // delBtn: this.permission.qa_qa_delete,
        editBtn: this.permission.qa_qa_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  created () {
    this.params.parentRecipeId = this.$route.query.parentRecipeId
  },
  methods: {
    toItem (row) {
      this.$router.push({
        path: '/sys/cookbook_details',
        query: {
          parentRecipeId: row.parentRecipeId,
          type: row.type,
          typeName: row.typeName,
          recipeName: row.recipeName
        }
      })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad () {
      getListTypeTemplate(this.params.parentRecipeId).then(res => {
        const data = res.data
        this.data = data
        console.log('测试')
        console.log(res)
      })
    }
  }
}
</script>

<style></style>
