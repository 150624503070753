<template>
  <el-container style="height: 100vh; border: 1px solid #eee">
    <el-container style="background: #fff;padding: 20px; overflow-y: auto">
      <avue-form ref="form" v-model="reportForm" :option="reportOption" @submit="submit">
        <el-form-item slot="weight">
          <el-input v-model="reportForm.weight"></el-input>
        </el-form-item>

        <el-form-item slot="height">
          <el-input v-model="reportForm.height"></el-input>
        </el-form-item>

        <el-form-item slot="bmi" disabled="true">
          <el-input v-model="bmi"></el-input>
        </el-form-item>

        <template slot="cdFamilyHistory">
          <el-radio v-model="radio" label="0">有</el-radio>
          <el-radio v-model="radio" label="1">无</el-radio>
        </template>
      </avue-form>
    </el-container>
  </el-container>
</template>

<script>

import { getSubmit, getDetail } from '@/api/health/historytaking'
import {
  mapGetters
} from 'vuex'

export default {
  components: {},
  data () {
    return {
      radio: '1',
      reportId: 0,
      sizeValue: 'small',
      reportForm: {}
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    bmi () {
      const realVal = parseFloat(this.reportForm.weight / ((this.reportForm.height / 100) * (this.reportForm.height / 100))).toFixed(2)
      return realVal
    },
    reportOption () {
      return {
        radio: '1',
        size: this.sizeValue,
        submitText: '录入',
        emptyBtn: false,
        column: [
          {
            label: '姓名',
            prop: 'name',
            detail: true
          },
          {
            label: '性别',
            prop: 'sex',
            type: 'select',
            dicData: [{
              label: '男',
              value: '1'
            }, {
              label: '女',
              value: '2'
            }],
            detail: true
          },
          {
            label: '年龄',
            prop: 'age',
            detail: true
          },
          {
            label: '腰围(cm)',
            prop: 'waistline'
          },
          {
            label: '体重(kg)',
            prop: 'weight'
          },
          {
            label: '身高(cm)',
            prop: 'height'
          },
          {
            // label: this.weight / (this.height * this.height),
            label: 'BMI(kg/m²)',
            disabled: true,
            prop: 'bmi'
          },
          {
            label: '联系电话',
            prop: 'phone'
          },
          {
            label: '早发心血管病家族史',
            prop: 'cdFamilyHistory',
            formslot: true,
            minRows: 10,
            span: 24
          }
        ],
        group: [
          {
            icon: 'el-icon-info',
            label: '现病史',
            collapse: false,
            prop: 'hpiList',
            column: [
              {
                label: '现病史',
                prop: 'hpiList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    // done({
                    //   reportId: this.reportId
                    // })
                    done()
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    {
                      width: 350,
                      label: '疾病',
                      prop: 'disease'
                    },
                    {
                      width: 200,
                      label: '病程（年）',
                      prop: 'cd'
                    }
                  ]
                }
              }
            ]
          },
          {
            icon: 'el-icon-info',
            label: '当前用药方案',
            collapse: false,
            prop: 'cmrList',
            column: [
              {
                label: '当前用药方案',
                prop: 'cmrList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    console.log('---')
                    // done({
                    //   reportId: this.reportId
                    // })
                    done()
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    // {
                    //   width: 200,
                    //   label: '药物',
                    //   prop: 'drug'
                    // },
                    {
                      width: 200,
                      label: '化学名',
                      prop: 'chemical'
                    },
                    {
                      width: 200,
                      label: '商品名',
                      prop: 'product'
                    },
                    {
                      width: 200,
                      label: '规格',
                      prop: 'specifications'
                    },
                    {
                      width: 200,
                      label: '单次剂量',
                      prop: 'singleDose'
                    },
                    {
                      width: 200,
                      label: '服用频次',
                      prop: 'takingFrequency'
                    }
                  ]
                }
              }
            ]
          },
          {
            icon: 'el-icon-info',
            label: '既往手术史',
            collapse: false,
            prop: 'operationHistoryList',
            column: [
              {
                label: '既往手术史',
                prop: 'operationHistoryList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    //
                    done()
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    {
                      width: 200,
                      label: '手术',
                      prop: 'operation'
                    }
                  ]
                }
              }
            ]
          },
          {
            icon: 'el-icon-info',
            label: '生活方式',
            collapse: false,
            prop: 'liftstyleList',
            column: [
              {
                label: '吸烟史',
                prop: 'smokingList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    done()
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    {
                      width: 200,
                      label: '年数',
                      prop: 'smokingYearNumber'
                    },
                    {
                      width: 200,
                      label: '支/天',
                      prop: 'smokingDaysCount'
                    }
                  ]
                }
              },
              {
                label: '饮酒史',
                prop: 'dringkingList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    done({
                      reportId: this.reportId
                    })
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    {
                      width: 200,
                      label: '年数',
                      prop: 'drinkingYearNumber'
                    },
                    {
                      width: 200,
                      label: '次/周',
                      prop: 'drinkingTimesWeek'
                    },
                    {
                      width: 200,
                      label: '两/次',
                      prop: 'drinkingTwoTimes'
                    }
                  ]
                }
              },
              {
                label: '运动',
                prop: 'exerciseList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    done({
                      reportId: this.reportId
                    })
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    {
                      width: 200,
                      label: '次/周',
                      prop: 'exerciseTimesWeek'
                    },
                    {
                      width: 200,
                      label: '分钟/次',
                      prop: 'exerciseMinutesTime'
                    }
                  ]
                }
              }
            ]
          },
          {
            icon: 'el-icon-info',
            label: '家族史',
            collapse: false,
            prop: 'familyHistoryList',
            column: [
              {
                label: '家族史',
                prop: 'familyHistoryList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    // done({
                    //   reportId: this.reportId
                    // })
                    done()
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    {
                      width: 200,
                      label: '家庭成员',
                      prop: 'mf'
                    },
                    {
                      width: 350,
                      label: '疾病1',
                      type: 'textarea',
                      prop: 'diseaseA'
                    },
                    {
                      width: 350,
                      label: '疾病2',
                      type: 'textarea',
                      prop: 'diseaseB'
                    }
                  ]
                }
              }
            ]
          },
          {
            icon: 'el-icon-info',
            label: '备注补充',
            collapse: false,
            prop: 'noteAddList',
            column: [
              {
                label: '备注补充',
                prop: 'noteAddList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    // done({
                    //   reportId: this.reportId
                    // })
                    done()
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    {
                      width: 350,
                      label: '描述',
                      type: 'textarea',
                      prop: 'ms'
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    }
  },
  filters: {
    sex (d) {
      if (d === '1') {
        return '男'
      } else {
        return '女'
      }
    }
  },
  created () {
    this.reportForm.name = this.$route.query.jkName
    this.reportForm.age = this.$route.query.jkAge
    this.reportForm.sex = this.$route.query.jkSex
    this.reportForm.wxUserId = this.$route.query.wxUserId
    this.onLoad()
  },
  methods: {
    submit (form, done) {
      this.reportForm.cdFamilyHistory = this.radio
      if (this.bmi === 'NaN') {
        this.reportForm.bmi = 0
      } else {
        this.reportForm.bmi = this.bmi
      }
      if (this.reportForm.height === undefined) {
        this.reportForm.height = 0
      }
      if (this.reportForm.weight === undefined) {
        this.reportForm.weight = 0
      }
      getSubmit(this.reportForm).then(() => {
        done()
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        done()
      })
    },
    onLoad () {
      getDetail(this.reportForm.wxUserId).then(res => {
        this.reportForm = res.data
      })
    }
  }

}
</script>
