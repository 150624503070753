<template>
  <div class="phoneReport">
    <div class="phoneContent">
  <!--    报告头部-->
<!--  <div class="rp_top">-->
<!--    <img class="bgImg" src="./images/boy.png" />-->
<!--    <div class="bgColor" >-->
<!--      <div class="bgColor_item" :style="{height:(detailData.jkzt * 20)+'%'}"></div>-->
<!--    </div>-->
<!--    <div class="rp_top_box">-->
<!--      <div class="rp_top_lf">-->
<!--        <img class="rtl_img" src="./images/title.png" />-->
<!--        <div class="rtl_user">{{ detailData.jkName }}<span></span>-->
<!--          {{detailData.jkSex=='1'?'男':'女'}}<span></span>{{detailData.jkAge}}岁</div>-->
<!--      </div>-->
<!--      <div class="rp_top_rt">-->
<!--        <div class="rtr-top">-->
<!--          <img class="rt" src="./images/rt.png" />-->
<!--          <div>健康状况</div>-->
<!--        </div>-->
<!--        <div class="rtr-bottom">{{ detailData.jkzt==1?'病危': detailData.jkzt==2? '不健康' : detailData.jkzt==3? '良好' : detailData.jkzt==4? '健康' : detailData.jkzt==5? '很健康' : '&#45;&#45;' }}</div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

      <div class="jzjl">
        <img class="jzjl_tit" src="./images/jbxx.png" />
        <div class="jzjl_content">
          <div class="jbxx">
            <div class="userInfo">
              <img class="ui_bg" src="./images/card.png" />
              <div class="ui_info">
                <div class="user">{{detailData.jkName}}<span> {{detailData.jkSex=='1'?'男':'女'}}</span>{{detailData.jkAge}}岁</div>
                <div class="bs">既往史：<span>{{detailData.jws}}</span></div>
                <div class="bs">药物史：<span>{{detailData.yws}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <!--    综合报告-->
    <div class="zhbg">
      <img class="zhbg_tit" src="./images/zhbg.png" />
      <div class="CanvasContainer">
        <div  id="reportCanvas" ref="reportCanvas" :style="{width:'100%', height: '300px'}"></div>
      </div>
    </div>
    <div class="zhbg_jd">
      <div class="zj_title">您的<span class="main">主要健康风险</span>存在于：</div>
      <div v-for="(item,index) in zJkfx" :key="index">
        <div class="zj_li">
          <div class="zj_li_tit_box">
            <div class="zj_li_tit">
              <div class="wz">{{item.zdxt}}</div>
              <div class="bg"></div>
            </div>
          </div>
          <div class="zj_li_content">{{item.fxms}}</div>
<!--          <div class="zj_li_zd">诊断：<span>{{item.zd}}</span></div>-->
        </div>
      </div>
      <div class="zj_title">您的<span class="minor">次要健康风险</span>存在于：</div>
      <div v-for="(item,index) in cJkfx" :key="index">
        <div class="zj_li">
          <div class="zj_li_tit_box">
            <div class="zj_li_tit">
              <div class="wz">{{item.zdxt}}</div>
              <div class="bg"></div>
            </div>
          </div>
          <div class="zj_li_content">{{item.fxms}}</div>
<!--          <div class="zj_li_zd">诊断：<span>{{item.zd}}</span></div>-->
        </div>
      </div>
    </div>

      <!--    名词解释-->
      <div class="mcjs">
        <img class="mcjs_tit" src="./images/yczb.png" />

        <div class="gxzb">
          <div class="gxzb_box">
            <div class="jzjl_content">

              <div class="jbxx">
                <div class="jc_tit">
                  <div class="lfLine"></div>
                  <div class="round"></div>
                  <div class="tit">异常项目</div>
                  <div class="round"></div>
                  <div class="rtLine"></div>
                </div>
                <div class="jc_content">
                  <div v-for="(item,index) in cgxm" :key="index">
                    <div class="jc_li">
                      <div class="jc_li_lf">{{item.zdylx}}</div>
                      <div class="jc_li_rt">
                        <div class="jc_li_rt_li">
                          <div class="jc_li_rt_li_lf">{{item.lxx}}</div>
                          <div class="jc_li_rt_li_rt">{{item.lxz}}</div>
                        </div>
                        <div v-for="(item,i) in item.child" :key="i">
                          <div class="jc_li_rt_li">
                            <div class="jc_li_rt_li_lf">{{item.lxx}}</div>
                            <div class="jc_li_rt_li_rt">{{item.lxz}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="jbxx">
                <div class="jc_tit">
                  <div class="lfLine"></div>
                  <div class="round"></div>
                  <div class="tit">名词解释</div>
                  <div class="round"></div>
                  <div class="rtLine"></div>
                </div>
        <div v-for="(item,index) in mcjs" :key="index">
          <div class="mcjs_li">
            <div class="ml_tit">
              <div class="ml_tit_name">{{item.mc}}</div>
              <img class="ml_tit_img" src="./images/lf.png" />
              <div class="ml_tit_line"></div>
            </div>
            <div class="zj_li_content">{{item.ms}}</div>
          </div>
        </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    <!--    诊疗建议-->
    <div class="zljy">
      <img class="jzjl_tit" src="./images/jzjl.png" />

      <div class="gxzb">
        <div class="gxzb_box">
          <div class="jzjl_content">
            <div class="jbxx">
              <div class="jc_tit">
                <div class="lfLine"></div>
                <div class="round"></div>
                <div class="tit">非药物方案</div>
                <div class="round"></div>
                <div class="rtLine"></div>
              </div>
              <div class="zljy">
                <div class="zj_li">
              <div class="zj_li_content">
                <div v-for="(item,index) in fywfa" :key="index">
                  <div class="zj_li_content_li">{{item}}</div>
                </div>
              </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

<!--      <div class="zj_li">-->
<!--        <div class="zj_li_tit_box">-->
<!--          <div class="zj_li_tit">-->
<!--            <div class="wz">非药物方案</div>-->
<!--            <div class="bg"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="zj_li_content">-->
<!--          <div v-for="(item,index) in fywfa" :key="index">-->
<!--            <div class="zj_li_content_li">{{item}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="zj_li">-->
<!--        <div class="zj_li_tit_box">-->
<!--          <div class="zj_li_tit">-->
<!--            <div class="wz">药物方案</div>-->
<!--            <div class="bg"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="zj_li_content">-->
<!--          <div v-for="(item,index) in ywfa" :key="index">-->
<!--            <div class="zj_li_content_li">{{item}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="zj_li">-->
<!--        <div class="zj_li_tit_box">-->
<!--          <div class="zj_li_tit">-->
<!--            <div class="wz">随诊指标</div>-->
<!--            <div class="bg"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="zj_li_content">-->
<!--          <div v-for="(item,index) in szzb" :key="index">-->
<!--            <div class="zj_li_content_li">{{item}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <!--    精准解疗-->
<!--    <div class="jzjl">-->
<!--      <img class="jzjl_tit" src="./images/jzjl.png" />-->
<!--      <div class="jzjl_content">-->
<!--          <div class="jbxx">-->
<!--            <div class="jc_tit">-->
<!--              <div class="lfLine"></div>-->
<!--              <div class="round"></div>-->
<!--              <div class="tit">基本信息</div>-->
<!--              <div class="round"></div>-->
<!--              <div class="rtLine"></div>-->
<!--            </div>-->
<!--            <div class="userInfo">-->
<!--              <img class="ui_bg" src="./images/card.png" />-->
<!--              <div class="ui_info">-->
<!--                <div class="user">{{detailData.jkName}}<span> {{detailData.jkSex=='1'?'男':'女'}}</span>{{detailData.jkAge}}岁</div>-->
<!--                <div class="bs">既往史：<span>{{detailData.jws}}</span></div>-->
<!--                <div class="bs">既药物史：<span>{{detailData.yws}}</span></div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--      </div>-->
<!--    </div>-->

    <!--    各项指标-->
    <div class="gxzb">
      <div class="gxzb_box">
        <div class="jzjl_content">
          <div>
            <div class="jbxx">
              <div class="jc_tit">
                <div class="lfLine"></div>
                <div class="round"></div>
                <div class="tit">诊疗信息</div>
                <div class="round"></div>
                <div class="rtLine"></div>
              </div>

            </div>

            <div v-for="(item,index) in zlxx" :key="index">
              <div class="jbxx">
                <div class="jc_tit">
                  <div class="lfLine"></div>
                  <div class="round"></div>
                  <div class="tit">{{item.type}}</div>
                  <div class="round"></div>
                  <div class="rtLine"></div>
                </div>
                <div class="jc_content">

                  <div class="jc_li">
                    <div class="jc_li_lf">诊断</div>
                    <div class="jc_li_rt_li">
                      <div>
                        <div class="jc_li_rt_list">{{item.zd}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="jc_li">
                    <div class="jc_li_lf">诊疗策略</div>
                    <div class="jc_li_rt">
                      <div v-for="(item,i) in item.zlcl" :key="i">
                        <div class="jc_li_rt_list">{{item}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="jc_li">
                    <div class="jc_li_lf">诊疗建议</div>
                    <div class="jc_li_rt">
                      <div v-for="(item,i) in item.zljy" :key="i">
                        <div class="jc_li_rt_list">{{item}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="jc_li">
                    <div class="jc_li_lf">药物方案</div>
                    <div class="jc_li_rt_li">
                      <div>
                        <div class="jc_li_rt_list">{{item.yczb}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'PhoneReport',
  props: {
    reportForm: {
      type: Object
    },
    size: String
  },
  data () {
    return {
      jkzt: 2,
      innerTags: this.value ? this.value.split(';') : [],
      zJkfx: [],
      cJkfx: [],
      ywfa: [],
      fywfa: [],
      szzb: [],
      mcjs: [],
      cgxm: [],
      zlxx: [],
      detailData: {}
    }
  },
  watch: {
    reportForm: {
      handler (newName, oldName) {
        this.detailData = newName
        this.getOption()
        this.jkfxData(this.detailData)
        this.ywfaData(this.detailData)
        this.fywfaData(this.detailData)
        this.szzbData(this.detailData)
        this.zlxxData(this.detailData)
        this.mcjs = this.detailData.mcjsList
        this.cgxm = this.detailData.cgList
      },
      deep: true
    }
  },
  methods: {
    // 药物方案
    ywfaData (data) {
      const t = this
      const ywfaStr = data.ywfa
      if (ywfaStr !== '') {
        const ywfaArr = ywfaStr ? ywfaStr.split(';') : []
        t.ywfa = ywfaArr
      }
    },
    // 非药物方案
    fywfaData (data) {
      const t = this
      const fywfaStr = data.fywfa
      if (fywfaStr !== '') {
        const fywfaArr = fywfaStr ? fywfaStr.split(';') : []
        t.fywfa = fywfaArr
      }
    },
    // 随诊指标
    szzbData (data) {
      const t = this
      const szzbStr = data.szzb
      if (szzbStr !== '') {
        const szzbArr = szzbStr ? szzbStr.split(';') : []
        t.szzb = szzbArr
      }
    },
    // 诊疗分类
    zlxxData (data) {
      const t = this
      const zlxxStr = data.zlList
      const zlxxArr = []
      if (zlxxStr.length) {
        for (let i = 0; i < zlxxStr.length; i++) {
          const info = zlxxStr[i]
          const newArr = []
          newArr.type = zlxxStr[i].type
          newArr.yczb = zlxxStr[i].yczb
          newArr.zd = zlxxStr[i].zd
          if (info.zlcl !== '') {
            newArr.zlcl = zlxxStr[i].zlcl ? zlxxStr[i].zlcl.split(';') : []
          }
          if (info.zljy !== '') {
            newArr.zljy = zlxxStr[i].zljy ? zlxxStr[i].zljy.split(';') : []
          }
          zlxxArr.push(newArr)
        }
      }
      t.zlxx = zlxxArr
    },
    // 健康风险
    jkfxData (data) {
      const t = this
      const zArr = []
      const cArr = []
      for (let i = 0; i < data.jkfxList.length; i++) {
        if (data.jkfxList[i].type === 1) {
          zArr.push(data.jkfxList[i])
        } else {
          cArr.push(data.jkfxList[i])
        }
      }
      t.zJkfx = zArr
      t.cJkfx = cArr
    },
    // 获取数据
    getOption: function () {
      const that = this
      // 初始化图表
      const myChart = this.$refs.reportCanvas
      const detailData = that.detailData
      const Chart = this.$echarts.init(myChart)
      // 前台配置折线线条表示属性
      Chart.setOption({
        radar: [
          {
            name: {
              fontFamily: 'SourceHanSansCN-Bold',
              formatter: function (value) {
                const list = value.split('')
                let result = ''
                for (let i = 1; i <= list.length; i++) {
                  if (!(i % 7) && list[i] !== undefined) {
                    result += list[i - 1] + '\n'
                  } else {
                    result += list[i - 1]
                  }
                }
                return result
              },
              rich: {
                a: {
                  align: 'center'
                }
              }

            },
            // ['循环系统', '血液肿瘤系统', '消化系统', '泌尿生殖系统', '心理', '眼、耳、鼻、喉', '运动系统', '代谢内分泌系统', '呼吸系统']
            indicator: [
              { text: '{a|循环系统}', max: 5, axisLabel: { show: false }, color: detailData.xhxt >= 4 ? 'rgba(248, 110, 110, 1)' : 'rgba(138, 163, 204, 1)' },
              { text: '{a|血液肿瘤系统}', max: 5, axisLabel: { show: false }, color: detailData.xyzlxt >= 4 ? 'rgba(248, 110, 110, 1)' : 'rgba(138, 163, 204, 1)' },
              { text: '{a|消化系统}', max: 5, axisLabel: { show: false }, color: detailData.xhuaxt >= 4 ? 'rgba(248, 110, 110, 1)' : 'rgba(138, 163, 204, 1)' },
              { text: '{a|泌尿生殖系统}', max: 5, axisLabel: { show: false }, color: detailData.mnxt >= 4 ? 'rgba(248, 110, 110, 1)' : 'rgba(138, 163, 204, 1)' },
              { text: '心理', max: 5, axisLabel: { show: false }, color: detailData.xl >= 4 ? 'rgba(248, 110, 110, 1)' : 'rgba(138, 163, 204, 1)' },
              { text: '{a|眼、耳、鼻、喉}', max: 5, axisLabel: { show: false }, color: detailData.yebh >= 4 ? 'rgba(248, 110, 110, 1)' : 'rgba(138, 163, 204, 1)' },
              { text: '运动系统', max: 5, axisLabel: { show: false }, color: detailData.ydxt >= 4 ? 'rgba(248, 110, 110, 1)' : 'rgba(138, 163, 204, 1)' },
              { text: '{a|代谢内分泌系统}', max: 5, axisLabel: { show: false }, color: detailData.dxnfm >= 4 ? 'rgba(248, 110, 110, 1)' : 'rgba(138, 163, 204, 1)' },
              { text: '呼吸系统', max: 5, color: detailData.hxxt >= 4 ? 'rgba(248, 110, 110, 1)' : 'rgba(138, 163, 204, 1)' }
            ],
            center: ['50%', '50%'],
            radius: '50%',
            startAngle: 110,
            splitNumber: 4,
            splitArea: {
              areaStyle: {
                color: ['rgba(0, 162, 255, .3)',
                  'rgba(11, 203, 251, 0.3)', 'rgba(11, 203, 251, 0.2)',
                  'rgba(3, 199, 255, 0.1)', 'rgba(3, 199, 255, 0.05)'],
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 1
              }
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(0, 162, 255, .6)',
                type: 'dashed',
                width: 0.7
              }
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(0, 162, 255, .6)',
                width: 0.5
              }
            },
            axisLabel: {
              show: true,
              // rotate: 90,
              fontStyle: 'SourceHanSansCN-Regular',
              align: 'right',
              rich: {
                a: {
                  fontSize: 10
                }
              },
              formatter: function (value, index) {
                let texts
                if (index === 0) {
                  texts = '暂无'
                }
                if (index === 1) {
                  texts = '正常'
                }
                if (index === 2) {
                  texts = '低风险'
                }
                if (index === 3) {
                  texts = '高风险'
                }
                if (index === 4) {
                  texts = ''
                }
                return texts
              },
              color: 'rgba(51, 51, 51, 1)',
              verticalAlign: 'top'
              // padding: [0, 0, 0, 20],

            }
          }
        ],
        series: [
          {
            name: '综合报告',
            type: 'radar',
            // areaStyle: {normal: {}},

            data: [
              {
                value: [detailData.xhxt ? detailData.xhxt : 0, detailData.xyzlxt ? detailData.xyzlxt : 0, detailData.xhuaxt ? detailData.xhuaxt : 0, detailData.mnxt ? detailData.mnxt : 0, detailData.xl ? detailData.xl : 0, detailData.yebh ? detailData.yebh : 0, detailData.ydxt ? detailData.ydxt : 0, detailData.dxnfm ? detailData.dxnfm : 0, detailData.hxxt ? detailData.hxxt : 0],

                label: {
                  show: false,
                  formatter: function (params) {
                    return params.value
                  }
                },
                itemStyle: {
                  color: 'rgba(0, 162, 255, 1)'
                },
                name: '综合报告',
                lineStyle: {
                  color: 'rgba(0, 162, 255, .6)',
                  width: 0.8
                },
                areaStyle: {
                  opacity: 0.4,
                  color: new this.$echarts.graphic.RadialGradient(0.1, 0.5, 1, [
                    {
                      color: '#0BCBFB',
                      offset: 0
                    },
                    {
                      color: '#8FFCA0',
                      offset: 1
                    }
                  ])
                }

              }
            ]
          }
        ]

      })
    }

  },
  created () {
    console.log(this.reportForm, '111111')
  }
}
</script>

<style scoped lang="scss">
.phoneReport{
  width: 362px;
  height: 720px;
  overflow: hidden;
  padding: 44px 24px;
  background-image: url(./images/phone.png);
  background-size:cover;
  background-repeat:no-repeat;
  background-color: #fff;
  border-radius: 20px;
}
.phoneReport::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.phoneReport {
  scrollbar-width: none;
}
.phoneContent{
  margin: 0 auto;
  height: 636px;
  overflow-y: auto;
  overflow-x: hidden;
}
.phoneContent::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.rp_top{
  width: 100%;
  height: 170px;
  overflow: hidden;
  position: relative;
  background: #33494D;
}
.bgImg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:2;

}
.bgColor{
  bottom:13px;
  top: 17px;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  z-index: 1;
}
.bgColor_item{
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(#01F8B5, #00A1E4);
}

.rp_top_box{
  height: 170px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 3;
  display: flex;
  align-items: center;
  padding: 0 25px;
}
.rp_top_rt{

}

.rp_top_lf .rtl_img{
  width: 180px;
  height: 24.5px;
  margin-bottom: 20.5px;
}
.rp_top_lf .rtl_user{
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  display:flex;
  align-items: center;
  height: 20px;
}
.rp_top_lf .rtl_user span{
  display: inline-block;
  height: 9px;
  width: 1px;
  background: #fff;
  margin: 0 10px;
}
.rp_li{
  margin: 10px;
  border-radius: 6px;
  background-color: #fff;
  padding: 20px;
}
.rp_top_rt{
  flex: 1;
}

.rp_top_rt .rtr-top{
  display: flex;
  align-items: center;
  float: right;
}
.rp_top_rt .rtr-bottom{
  clear: both;
  float: right;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  margin-top: 25px;
}
.rp_top_rt .rtr-top .rt{
  width: 20px;
  height: 3px;
  margin-right: 3px;
}
.rp_top_rt .rtr-top div{
  color: #fff;
  font-size: 12.5px;
}

.rp_li_top_t{
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.rp_li_top_b{
  width: 315px;
  height: 236px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
}
.rp_li_top_bto{

}

.zhbg{
  padding: 20px 12.5px 28px 12.5px;
  background: #E9FBFF;
  overflow: hidden;
}
.mcjs{
  padding: 20px 12.5px 28px 12.5px;
  background: #fff;
  overflow: hidden;
}
.jzjl{
  padding: 20px 12.5px 28px 12.5px;
  background: #fff;
  overflow: hidden;
}
.zljy{
  padding: 20px 12.5px 28px 12.5px;
  background: #E9FBFF;
  overflow: hidden;
}
.zj_title{
  font-size: 15.5px;
  color: #333333;
  font-weight: bold;
}
.zhbg_jd{
  padding: 20px 12.5px 28px 12.5px;
  background: #fff;
}
.zhbg .canvas{
  width:100%;
  height: 300px;
  margin: 35px auto 0 auto;
}
.zhbg_tit{
  width: 210px;
  height: 39px;
  display: block;
  margin: 0 auto;
}
.zljy_tit{
  width: 210px;
  height: 39px;
  display: block;
  margin: 0 auto 25px auto;
}
.mcjs_tit{
  width: 201px;
  height: 39px;
  display: block;
  margin: 0 auto 10px auto;
}
.jzjl_tit{
  width: 244px;
  height: 45px;
  display: block;
  margin: 0 auto 20px auto;
}
.zj_title{
  font-size: 15.5px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  margin-top: 5px;
}
.zj_title .main{
  color: #FF5151;
  font-size: 15.5px;
  font-weight: bold;
}
.zj_title .minor{
  color: #9061EF;
  font-size: 15.5px;
  font-weight: bold;
}
.zj_li{
  margin-bottom: 20px;
}
.zj_li_tit_box{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}
.zj_li .zj_li_tit{
  display: inline-block;
  height: 25px;
  padding: 5px 0px;
}
.zj_li_tit .wz{
  color: #1872FF;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 25px;
  z-index: 1;
}

.zj_li_tit .bg{
  background: rgba(24, 114, 255, 0.15);
  border-radius: 50%;
  height: 13.5px;
  margin-top: -12px;
  z-index: 0;
}
.zj_li_content{
  font-size: 12.5px;
  color: #666;
  line-height: 24px;
  text-indent: 2em;
}
.zj_li_content .zj_li_content_li{
  font-size: 12.5px;
  color: #666;
  line-height: 28px;
}
.zj_li_zd{
  margin-top: 15px;
  color: #333;
  font-size: 12.5px;
  font-weight: bold;
}
.zj_li_zd span{
  font-size: 12.5px;
  font-weight: bold;
  color: #FF5151;
}

.mcjs_li{
  margin-bottom: 20px;
}

.mcjs_li .ml_tit{
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.mcjs_li .ml_tit .ml_tit_name{
  font-weight: bold;
  font-size: 16.5px;
  color: #1872FF;
}
.mcjs_li .ml_tit .ml_tit_img{
  width: 8.5px;
  height: 9px;
  display: block;
  margin: 0 9px 0 10px;
}

.mcjs_li .ml_tit .ml_tit_line{
  flex: 1;
  height: 1px;
  background:linear-gradient(244deg,rgba(24, 114, 255, 0) 0%,rgba(24, 114, 255, 1) 50%);

}

.bottomBg{
  position: fixed;
  height: 141px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}
.bottomBg .img{
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.bottomBg div{
  color:  #03849C;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  text-align: center;
  bottom: 39px;
}

.jzjl_content{
  /*margin: 0 35rpx;*/
}
.jzjl_content .jbxx{
  /*background: #fff;*/
  overflow: hidden;
}
.jzjl_content .jc_tit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 22.5px;
}
.jzjl_content .jc_tit .tit{
  color: #333;
  font-size: 14px;
  margin: 0 6px;
  font-weight: bold;
}
.jzjl_content .jc_tit .round{
  width: 3px;
  height: 3px;
  background: #1872FF;
}
.jzjl_content .jc_tit .lfLine{
  flex: 1;
  height: 1px;
  background:linear-gradient(244deg, rgba(24, 114, 255, 1) 50%,rgba(24, 114, 255, 0) 100%);
}
.jzjl_content .jc_tit .rtLine{
  flex: 1;
  height: 1px;
  background:linear-gradient(244deg,rgba(24, 114, 255, 0) 0%,rgba(24, 114, 255, 1) 50%);
}
.jzjl_content .userInfo{
  width: 340.5px;
  height: 117.5px;
  overflow: hidden;
  position: relative;
  margin: 0 auto 20px auto;
}
.jzjl_content .userInfo .ui_bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.jzjl_content .userInfo .ui_info{
  height: 100%;
  position: absolute;
  padding: 17.5px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
}
.jzjl_content .userInfo .ui_info .user{
  margin-bottom: 15px;
}
.jzjl_content .userInfo .ui_info .bs{
  margin-bottom: 10px;
  font-size: 12.5px;
}

.jc_content{
  margin: 17.5px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.jc_li{
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
}
.jc_li:last-child{
  border-bottom: none !important;
}
.jc_li .jc_li_lf{
  color: #1872FF;
  font-size: 12.5px;
  font-weight: bold;
  margin-right: 45px;
}
.jc_li .jc_li_rt{
  flex: 1;
}
.jc_li .jc_li_rt .jc_li_rt_li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1rpx solid #eee;
}
.jc_li .jc_li_rt .jc_li_rt_list{
  color: #666;
  font-size:12.5px;
  margin-bottom: 10px;
}
.jc_li .jc_li_rt_list{
  color: #666;
  font-size:12.5px;
  margin-bottom: 10px;
}
.jc_li .jc_li_rt .jc_li_rt_list:last-child{
  margin-bottom: 0 !important;
}
.jc_li .jc_li_rt .jc_li_rt_li:first-child{
  padding-top: 0px !important;
}

.jc_li .jc_li_rt .jc_li_rt_li:last-child{
  border-bottom: none !important;
}

.jc_li .jc_li_rt .jc_li_rt_li .jc_li_rt_li_lf{
  font-size: 12.5px;
  color: #666;
}

.jc_li .jc_li_rt .jc_li_rt_li .jc_li_rt_li_rt{
  font-size: 12.5px;
  color: #333;
}

.CanvasContainer {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 290px;
}

ec-canvas {
  width: 100%;
  height: 100%;
}

</style>
