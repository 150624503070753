<template>
  <basic-container>
    <avue-crud :option="option" :data="data" :page.sync="page" ref="crud" @row-del="rowDel" v-model="form"
      :permission="permissionList" @row-update="rowUpdate" @row-save="rowSave" :before-open="beforeOpen"
      @search-change="searchChange" @search-reset="searchReset" @refresh-change="refreshChange"
      @selection-change="selectionChange" @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.qa_qa_delete"
          @click="handleDelete">删 除
        </el-button>
        <el-upload style="float: left" class="filter-item" :show-file-list="false" :action="handleImport()"
                   :headers="uploadHeaders()" :on-success="uploadSuc">
          <el-button type="primary" icon="upload">导入</el-button>
        </el-upload>
        <div style="float: left">
          <el-button id="files" size="small" type="primary" @click="downModule()">下载模版</el-button>
        </div>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getList, submit, remove, getDetail, moduleExcel } from '@/api/sys/drug_management'
import { mapGetters } from 'vuex'
import FileSaver from 'file-saver'
import { getToken } from '@/util/auth'
import { baseUrl } from '@/config'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        addBtn: false,

        addRowBtn: true,
        cellBtn: true,
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        editBtn: true,
        selection: true,
        menuWidth: 200,
        column: [
          {
            label: '药品名称',
            prop: 'drugName',
            cell: true,
            rules: [{
              required: true,
              message: '请输入药品名称'
            }]
          },
          {
            label: '商品名称',
            prop: 'tradeName',
            cell: true,
            rules: [{
              required: true,
              message: '请输入商品名称'
            }]
          },
          {
            label: '适用症状',
            prop: 'indications',
            cell: true,
            rules: [{
              required: true,
              message: '请输入药品名称'
            }]
          },
          {
            label: '服用剂量',
            prop: 'dosageTaken',
            cell: true,
            rules: [{
              required: true,
              message: '请输入服用剂量'
            }]
          },
          {
            label: '服用频次',
            prop: 'medicationFrequency',
            cell: true,
            rules: [{
              required: true,
              message: '请输入服用频次'
            }]
          },
          {
            label: '服用时间',
            prop: 'medicationTime',
            cell: true,
            rules: [{
              required: true,
              message: '请输入服用时间'
            }]
          },
          {
            label: '注意事项',
            prop: 'note',
            cell: true,
            rules: [{
              required: false,
              message: '请输入注意事项'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            hide: true,
            addDisplay: true,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            hide: true,
            addDisplay: true,
            cell: false
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qa_add,
        viewBtn: this.permission.qa_qa_view,
        delBtn: this.permission.qa_qa_delete,
        editBtn: this.permission.qa_qa_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    // excle导入
    uploadHeaders () {
      return { token: getToken() }
    },
    handleImport () {
      return baseUrl + '/health/drugManagement/excel/save'
    },
    uploadSuc (response) {
      if (response.success) {
        this.$message({ message: '操作成功', type: 'success' })
        this.onLoad(this.page)
      } else {
        this.$message({ message: '操作失败', type: 'error' })
      }
    },
    // 模版
    downModule () {
      moduleExcel().then((response) => {
        console.log('csssss')
        console.log(response)
        FileSaver.saveAs(response, '导入模版' + '.xlsx')
      })
    },
    rowSave (row, done, loading) {
      row.isUsing = '1'
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = {}) {
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        console.log('测试')
        console.log(res)
      })
    }
  }
}
</script>

<style></style>
