import { request } from '@/util/http'

export const getListTemplate = () => {
  return request({
    url: '/health/cookbook/listTemplate',
    method: 'get',
    params: {
    }
  })
}

export const getListTypeTemplate = (parentRecipeId) => {
  return request({
    url: '/health/cookbook/listTypeTemplate',
    method: 'get',
    params: {
      parentRecipeId
    }
  })
}

export const getListTypeTemplateDetails = (params) => {
  return request({
    url: '/health/cookbook/listTypeTemplateDetails',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const submitTemplate = (row) => {
  return request({
    url: '/health/cookbook/submitTemplate',
    method: 'post',
    data: row
  })
}

export const removeTemplate = (ids) => {
  return request({
    url: '/health/cookbook/removeTemplate',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submitDetails = (row) => {
  return request({
    url: '/health/cookbook/submitDetails',
    method: 'post',
    data: row
  })
}

export const removeDetails = (ids) => {
  return request({
    url: '/health/cookbook/removeDetails',
    method: 'post',
    params: {
      ids
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/health/cookbook/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/health/cookbook/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/health/cookbook/submit',
    method: 'post',
    data: row
  })
}
