<template>
  <basic-container>
    <div>
      <avue-crud
          :option="option"
          :data="data"
          :page.sync="page"
          v-model="form"
          @row-del="rowDel"
          :permission="permissionList"
          @row-update="rowUpdate"
          @row-save="rowSave"
          :before-open="beforeOpen"
          @on-load="onLoad">
      </avue-crud>
    </div>
  </basic-container>
</template>

<script>
// import { getList, submit, remove, getDetail } from '@/api/sys/speech_template'
import { getList, getDetail, remove, submit } from '@/api/health/user_cr'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},

      wxUserId: 0,
      boolean: false,
      id: 0,
      show: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      page1: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        addBtn: true,
        menuWidth: 200,
        column: [
          {
            label: '结论',
            prop: 'conclusion',
            cell: true,
            rules: [{
              required: true,
              message: '请输入结论'
            }]
          },
          {
            label: '建议',
            prop: 'recommendation',
            cell: true,
            rules: [{
              required: true,
              message: '请输入建议'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            cell: false
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qa_add,
        viewBtn: this.permission.qa_qa_view,
        delBtn: this.permission.qa_qa_delete,
        editBtn: this.permission.qa_qa_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  created () {
    this.wxUserId = this.$route.query.wxUserId
    this.id = this.$route.query.id
    if (this.$route.query.id === '2') {
      this.boolean = true
    }
  },
  methods: {
    toItem (row) {
      this.$router.push({
        path: '/health/health_plan_details',
        query: {
          wxUserId: this.wxUserId,
          id: row.id
        }
      })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    rowSave (row, done, loading) {
      row.isUsing = '1'
      row.wxUserId = this.wxUserId
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      row.wxUserId = this.wxUserId
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad () {
      getList(this.wxUserId).then(res => {
        const data1 = res.data.records
        this.page1.total1 = data1.total
        this.data = data1
      })
    }
  }
}
</script>

<style></style>
