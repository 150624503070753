import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/wx/user/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/wx/user/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/wx/user/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/wx/user/submit',
    method: 'post',
    data: row
  })
}

export const getBpDataList = (current, size, params) => {
  return request({
    url: '/wx/user/bpDataList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getBpWeekList = (current, size, params) => {
  return request({
    url: 'health/bpMeterWeekRecord/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const removeBpWeekList = (params) => {
  return request({
    url: 'health/bpMeterWeekRecord/remove',
    method: 'post',
    params: {
      ...params
    }
  })
}

export const getBsDataList = (current, size, params) => {
  return request({
    url: '/wx/user/bsDataList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getBoDataList = (current, size, params) => {
  return request({
    url: '/wx/user/boDataList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getBtDataList = (current, size, params) => {
  return request({
    url: '/wx/user/btDataList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getMoDataList = (current, size, params) => {
  return request({
    url: '/wx/user/moDataList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getEcgDataList = (current, size, params) => {
  return request({
    url: '/wx/user/ecgDataList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
