<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<template>
  <div>
    <img src="img/ketang.png" class="wximg" />
    <div class="gotext" @click="goApp()">点击跳转</div>
  </div>
</template>
<script>

// import {
//   getList
// } from '@/api/health/test'
// import { request } from '@/util/http'

export default {
  name: 'kindex',
  data () {
    return {
      requestHeadUrl: 'https://dbadmin.dbzdjk.com',
      // requestHeadUrl: 'https://douyin.kequbang.com',
    //   requestHeadUrl: 'http://localhost:8180',
      // isOpenVipModular: false,
      // showModal: false, // 是否展示弹窗
      // isVip: 0,
      // storeId: '',
      // userId: '',
      // isMiniProgram: true,
      appLink: ''
    }
  },
  created () {
    const thisObj = this
    thisObj.onLoad()
  },
  methods: {
    onLoad: function () {
      // getList().then(res => {
      //   thisObj.appLink = res.data
      //   location.href=res.data
      // })
      // var rr = window.location.href.split('?')
      // console.log(rr[rr.length-1])
      // // alert(rr[rr.length-1])
      // var ciphertext = rr[rr.length-1].substring(0,6)
      const thisObj = this
      thisObj.$api.get(thisObj.requestHeadUrl + '/admin/fy/rest/third/getopenlink', {}).then(res => {
        // console.log(res)
          thisObj.appLink = res.data
          location.href=res.data;
      }).catch(function (error) {
      })
    },
    goApp() {
      location.href=this.appLink
    },
    // 页面跳转
    pageJump (uri, item) {
      const thisObj = this
      let queryData = {
      }
      thisObj.$router.push({
        path: uri,
        query: queryData
      })
    },
    // 页面跳转
    pageJumpCate (uri) {
      const thisObj = this
      let queryData = {
      }
      thisObj.$router.push({
        path: uri,
        query: queryData
      })
    },
  }
}
</script>

<style>
.btn {
    padding: 12px;
    width: 200px;
    height: 50px;
}
.wximg {
  position: fixed;
  width: 30%;
  left: 35%;
  top: 30%;
}
.gotext {
  position: fixed;
  bottom: 20%;
  left: 30%;
  width: 40%;
  height: 80px;
  background: rgb(40, 196, 69);
  color: #ffffff;
  text-align: center;
  font-size: 35px;
  padding: 15px;
  border-radius: 15px;
}
</style>
