<template>
    <el-container style="height: 100vh; border: 1px solid #eee">
        <el-container style="background: #fff;padding: 20px; overflow-y: auto">
            <avue-form ref="form" v-model="reportForm" :option="reportOption">
            </avue-form>
        </el-container>
    </el-container>
</template>

<script>
import { getListDiary } from '@/api/health/sleepImprovementGuidance'
import {
  mapGetters
} from 'vuex'
export default {
  data () {
    return {
      reportId: 0,
      reportForm: {},
      wxUserId: 0,
      params: {
        wxUserId: ''
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      }
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    reportOption () {
      return {
        size: 10,
        labelWidth: 500,
        emptyBtn: false,
        submitBtn: false,
        column: [
          {
            label: '您什么时间上床?',
            prop: 'question1',
            detail: true
          },
          {
            label: '昨晚大概入睡(睡着)时间',
            prop: 'question2',
            detail: true
          },
          {
            label: '您花了多长时间入睡?（分钟/次）',
            prop: 'question3',
            detail: true
          },
          {
            label: '不计算最后一次醒来，您醒了几次?（分钟/次）',
            prop: 'question4',
            detail: true
          },
          {
            label: '这些醒来的时间总共大概多久?（分钟/次）',
            prop: 'question5',
            detail: true
          },
          {
            label: '您最后一次醒来是什么时候?',
            prop: 'question6',
            detail: true
          },
          {
            label: '您起床是什么时候?',
            prop: 'question7',
            detail: true
          },
          {
            label: '您如何评估自己的睡眠质量?',
            prop: 'question8',
            detail: true
          },
          {
            label: '其他补充（分钟/次）',
            prop: 'question9',
            detail: true
          },
          {
            label: '今天白天觉得困么?',
            prop: 'question10',
            detail: true
          },
          {
            label: '白天打盹/午睡了多长时间?（分钟/次）',
            prop: 'question11',
            detail: true
          },
          {
            label: '锻炼身体多长时间?（分钟/次）',
            prop: 'question12',
            detail: true
          },
          {
            label: '下午4点后饮酒、饮茶或咖啡了么?',
            prop: 'question13',
            detail: true
          },
          {
            label: '白天服用了什么药?',
            prop: 'question14',
            detail: true
          }
        ]
      }
    }
  },
  created () {
    this.wxUserId = this.$route.query.wxUserId
    this.params.wxUserId = this.$route.query.wxUserId || ''
    this.onLoad()
  },
  methods: {
    onLoad () {
      console.log('test wxUserId')
      console.log(this.wxUserId)
      getListDiary(this.page.currentPage, this.page.pageSize, this.params).then(res => {
        console.log('test')
        console.log(res)
        const data = res.data
        this.page.total = data.total
        this.reportForm = data.records[0]
        if (this.reportForm.question13 === 'false') {
          this.reportForm.question13 = '否'
        } else if (this.reportForm.question13 === 'true') {
          this.reportForm.question13 = '是'
        }
      })
    }
  }

}
</script>

