<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot-scope="scope" slot="menu">
        <el-button type="text" size="small"
                   @click.stop="handleDetail(scope.row,scope.index)">体温查询</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getBtDataList } from '@/api/wx/wxuser'
import { mapGetters } from 'vuex'
import func from '@/util/func'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      params: {
        meterDate: func.format(new Date(), 'YYYY-mm-dd')
      },
      selectionList: [],
      option: {
        searchMenuSpan: 12,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        selection: false,
        column: [
          {
            label: '测量时间',
            prop: 'meterDate',
            type: 'week',
            format: 'yyyy 第 WW 周',
            valueFormat: 'yyyy-MM-dd',
            hide: true,
            search: true,
            searchValue: func.format(new Date(), 'YYYY-mm-dd'),
            change: ({ value }) => {
              this.params.meterDate = value
              if (this.params.meterDate === undefined) {
                return
              }
              this.onLoad(this.page)
            }
          },
          {
            label: '姓名',
            prop: 'jkName',
            detail: true,
            search: true
          },
          {
            label: '年龄',
            prop: 'jkAge',
            detail: true
          },
          {
            label: '性别',
            prop: 'jkSex',
            type: 'select',
            dicUrl: '/sys/dict/code/sex',
            detail: true
          },
          {
            label: '状态',
            prop: 'jkStatus',
            type: 'select',
            search: true,
            searchValue: '',
            detail: true,
            dicUrl: '/sys/dict/code/bp_report_status',
            rules: [{
              required: true,
              message: '请输入状态'
            }],
            change: ({ value }) => {
              this.params.jkStatus = value
              this.onLoad(this.page)
            }
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.wx_user_add,
        viewBtn: this.permission.wx_user_view,
        delBtn: this.permission.wx_user_delete,
        editBtn: this.permission.wx_user_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    handleDetail (data) {
      this.$router.push({
        path: '/health/btmeterdata_detail',
        query: { wxUserId: data.id, meterDate: this.params.meterDate, jkStatus: data.jkStatus }
      })
    },

    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.params = params
      this.onLoad(this.page)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.params = data.searchForm
      this.onLoad(this.page)
    },
    onLoad (page) {
      const that = this
      that.params.type = 2
      this.params.channel = window.localStorage.getItem('channel')
      getBtDataList(page.currentPage, page.pageSize, that.params).then(res => {
        const data = res.data
        that.page.total = data.total
        that.data = data.records
      })
    }
  }
}
</script>

<style>
</style>
