<template>
  <basic-container>
    <div style="font-size: 20px; margin-top: 20px; margin-bottom: 20px;"> 所在医院 </div>
    <avue-crud
        :option="option1"
        :data="data1"
        ref="crud"
        v-model="form1"
        :permission="permissionList"
        @row-del="rowDel"
        @row-save="rowSave1"
        :before-open="beforeOpen1"
        :page.sync="page"
        @refresh-change="refreshChange"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
      </template>
    </avue-crud>

    <div style="font-size: 20px; margin-top: 50px; margin-bottom: 10px;"> 所在科室 </div>
    <avue-crud
        :option="option2"
        :data="data2"
        ref="crud"
        v-model="form2"
        :permission="permissionList"
        @row-del="rowDel"
        @row-save="rowSave2"
        :before-open="beforeOpen2"
        :page.sync="page"
        @refresh-change="refreshChange"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
      </template>
    </avue-crud>

    <div style="font-size: 20px; margin-top: 50px; margin-bottom: 10px;"> 职称 </div>
    <avue-crud
        :option="option3"
        :data="data3"
        ref="crud"
        v-model="form3"
        :permission="permissionList"
        @row-del="rowDel"
        @row-save="rowSave3"
        :before-open="beforeOpen3"
        :page.sync="page"
        @refresh-change="refreshChange"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getList, getDetail, remove, submit } from '@/api/health/departmentHospital'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form1: {},
      form2: {},
      form3: {},
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      page1: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      page2: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      page3: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      init: {
        roleTree: [],
        deptTree: []
      },
      option1: {
        tip: false,
        border: true,
        index: true,
        selection: false,
        viewBtn: true,
        dialogHeight: 450,
        column: [
          {
            label: '医院',
            prop: 'hospital',
            search: false,
            rules: [{
              required: true,
              message: '请输入渠道昵称'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            editDisabled: true,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            addDisplay: false,
            editDisabled: true,
            cell: false
          }
        ]
      },
      option2: {
        tip: false,
        border: true,
        index: true,
        selection: false,
        viewBtn: true,
        dialogHeight: 450,
        column: [
          {
            label: '科室',
            prop: 'department',
            search: false,
            rules: [{
              required: true,
              message: '请输入渠道昵称'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            editDisabled: true,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            addDisplay: false,
            editDisabled: true,
            cell: false
          }
        ]
      },
      option3: {
        tip: false,
        border: true,
        index: true,
        selection: false,
        viewBtn: true,
        dialogHeight: 450,
        column: [
          {
            label: '职称',
            prop: 'title',
            search: false,
            rules: [{
              required: true,
              message: '请输入渠道昵称'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            editDisabled: true,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            addDisplay: false,
            editDisabled: true,
            cell: false
          }
        ]
      },
      data1: [],
      data2: [],
      data3: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_user_add, false),
        viewBtn: this.vaildData(this.permission.sys_user_view, false),
        delBtn: this.vaildData(this.permission.sys_user_delete, false),
        editBtn: this.vaildData(this.permission.sys_user_edit, false)
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    rowSave1 (row, loading, done) {
      row.type = 1
      submit(row).then(() => {
        loading()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }, () => {
        done()
      })
    },
    rowSave2 (row, loading, done) {
      row.type = 2
      submit(row).then(() => {
        loading()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }, () => {
        done()
      })
    },
    rowSave3 (row, loading, done) {
      row.type = 3
      submit(row).then(() => {
        loading()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }, () => {
        done()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    rowUpdate (row, index, loading, done) {
      submit(row).then(() => {
        loading()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }, () => {
        done()
      })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params, done)
    },
    selectionChange (list) {
      this.selectionList = list
    },
    beforeOpen1 (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form1.id).then(res => {
          this.form1 = res.data
        })
      }
      done()
    },
    beforeOpen2 (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form1.id).then(res => {
          this.form1 = res.data
        })
      }
      done()
    },
    beforeOpen3 (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form1.id).then(res => {
          this.form1 = res.data
        })
      }
      done()
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    currentChange (currentPage) {
      this.page.currentPage = currentPage
    },
    sizeChange (pageSize) {
      this.page.pageSize = pageSize
    },
    onLoad (page, params = {}, done) {
      params.type = 1
      getList(1).then(res => {
        console.log('test getList1')
        console.log(res)
        const data = res.data
        this.page1.total = data.total
        this.data1 = data
        if (done) {
          done()
        }
      })

      params.type = 2
      getList(2).then(res => {
        console.log('test getList2')
        console.log(res)
        const data = res.data
        this.page2.total = data.total
        this.data2 = data
        if (done) {
          done()
        }
      })

      params.type = 3
      getList(3).then(res => {
        console.log('test getList3')
        console.log(res)
        const data = res.data
        this.page3.total = data.total
        this.data3 = data
        if (done) {
          done()
        }
      })
    }
  }
}
</script>

<style>
</style>
