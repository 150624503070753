<template>
  <basic-container>
    <avue-crud :option="option"
               :data="data"
               ref="crud"
               v-model="form"
               :permission="permissionList"
               @row-del="rowDel"
               @row-update="rowUpdate"
               @row-save="rowSave"
               :before-open="beforeOpen"
               :page.sync="page"
               @refresh-change="refreshChange"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @on-load="onLoad">
      <template slot="menuLeft">
      </template>
      <template slot-scope="scope" slot="menu">

        <el-button type="text" size="small"
                   @click.stop="handleDetail(scope.row,scope.index)">
          详情
        </el-button>

      </template>

    </avue-crud>
  </basic-container>
</template>

<script>
import { getDetail, submit, remove, enable, disable } from '@/api/health/userjk'
import { getList } from '@/api/health/compliancestatistics'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        editBtn: false,
        addBtn: false,
        delBtn: false,
        selection: false,
        align: 'center',
        column: [
          {
            label: '项目',
            prop: 'project',
            rules: [{
              required: true,
              message: '请输入渠道信息'
            }]
          },
          {
            label: '患病总人数',
            prop: 'illnessCount',
            rules: [{
              required: true,
              message: '请输入渠道信息'
            }]
          },
          {
            label: '达标人数',
            prop: 'meetStandardsCount',
            rules: [{
              required: true,
              message: '请输入渠道信息'
            }]
          },
          {
            label: '不达标人数',
            prop: 'noStandardsCount',
            rules: [{
              required: true,
              message: '请输入渠道信息'
            }]
          },
          {
            label: '数据不全人数',
            prop: 'incompleteDataCount',
            rules: [{
              required: true,
              message: '请输入渠道信息'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.health_userjk_add,
        viewBtn: this.permission.health_userjk_view,
        delBtn: this.permission.health_userjk_delete,
        editBtn: this.permission.health_userjk_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      params.channel = window.localStorage.getItem('channel')
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    handleEnable () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定对该用户启用VIP嘛?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return enable(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    handleDisable () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定对该用户停用VIP嘛?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return disable(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = { channel: window.localStorage.getItem('channel') }) {
      // console.log(params)
      // params.channel = window.localStorage.getItem('channel')
      // { channel: window.localStorage.getItem('channel') }
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.data = data
        console.log('test getList')
        console.log(data)
      })
    },
    handleDetail (data) {
      console.log('test handleDetail')
      // this.$router.push({
      //   path: '/health/bpmeterdata_value',
      //   query: { wxUserId: data.wxUserId }
      // })
    },
    // rechargeConsume (data) {
    //   this.$router.push({
    //     path: '/health/rechargeconsume',
    //     query: { wxUserId: data.wxUserId }
    //   })
    // },
    bloodSugarDetail (data) {
      this.$router.push({
        path: '/health/bsmeterdata_value',
        query: { wxUserId: data.wxUserId }
      })
    },

    jdReport (row) {
      console.log(row)
      // if (row.isVip !== 1) {
      //   this.$message.error('VIP用户才可解读报告')
      //   return
      // }
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/reportvip-jd',
        query: parameter
      })
    },
    plan (row) {
      console.log(row)
      // if (row.isVip !== 1) {
      //   this.$message.error('VIP用户才可解读报告')
      //   return
      // }
      const parameter = {
        wxUserId: row.wxUserId
      }
      this.$router.push({
        path: '/health/health_plan',
        query: parameter
      })
    },

    adminPage (row) {
      console.log(row)
      const parameter = {
        wxUserId: row.wxUserId
      }
      this.$router.push({
        path: '/health/user_cr',
        query: parameter
      })
    },

    archiveType (row) {
      console.log(row)
      const parameter = {
        wxUserId: row.wxUserId
      }
      this.$router.push({
        path: '/health/archiveType',
        query: parameter
      })
    },

    followUpPlanNew (row) {
      console.log(row)
      const parameter = {
        wxUserId: row.wxUserId
      }
      this.$router.push({
        path: '/health/followUpPlanNew',
        query: parameter
      })
    },

    historyTaking (row) {
      console.log(row)
      if (row.isVip !== 1) {
        this.$message.error('VIP用户才可进行病史采集')
        return
      }
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/historytaking',
        query: parameter
      })
    },
    drugplan (row) {
      console.log(row)
      if (row.isVip !== 1) {
        this.$message.error('VIP用户才可录入药物方案')
        return
      }
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/drugplan',
        query: parameter
      })
    },
    followUpPlan (row) {
      console.log(row)
      if (row.isVip !== 1) {
        this.$message.error('VIP用户才可进行随访计划')
        return
      }
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/followupplan',
        query: parameter
      })
    },
    healthRecords (row) {
      console.log(row)
      if (row.isVip !== 1) {
        this.$message.error('VIP用户才可录入检查档案')
        return
      }
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/healthrecords',
        query: parameter
      })
    }
  }
}
</script>
