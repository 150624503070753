import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/user/userConfigureVipNew/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/user/userConfigureVipNew/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/user/userConfigureVipNew/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/user/userConfigureVipNew/submit',
    method: 'post',
    data: row
  })
}

export const saveExcel = (data) => {
  return request({
    url: '/user/userConfigureVipNew/excel/save',
    method: 'post',
    file: data
  })
}

export const moduleExcel = () => {
  return request({
    url: '/user/userConfigureVipNew/excel/download',
    method: 'get',
    responseType: 'blob'
  })
}
