<template>
  <basic-container>
    <avue-crud :option="option"
               :data="data"
               :page.sync="page"
               ref="crud"
               @row-del="rowDel"
               v-model="form"
               :permission="permissionList"
               @row-update="rowUpdate"
               @row-save="rowSave"
               :before-open="beforeOpen"
               @search-change="searchChange"
               @search-reset="searchReset"
               @refresh-change="refreshChange"
               @selection-change="selectionChange"
               @on-load="onLoad">
      <template slot="menuLeft">

      </template>
      <template slot-scope="scope" slot="menu">
          <el-button type="text"
                    icon="el-icon-s-operation"
                    size="small"
                    @click.native="toItem(scope.row)">内容编辑
          </el-button>
          <el-button type="text"
                    size="small"
                    icon="el-icon-delete"
                    @click="rowUpdatestate(scope.row)">确认完成
          </el-button>
          <el-button type="text"
                    size="small"
                    icon="el-icon-delete"
                    @click="toItem1(scope.row)">健康随诊方案
          </el-button>
        </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getList, getDetail, submit, remove } from '@/api/health/schemeProject'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        editBtn: false,
        selection: false,
        delBtn: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '姓名',
            prop: 'name'
          },
          {
            label: '是否完成',
            prop: 'ifComplete',
            dicData: [
              { label: '未完成', value: 0 },
              { label: '已完成', value: 1 }
            ]
          },
          {
            label: '提交时间',
            prop: 'createTime'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.health_bsmeterweekreportitem_add,
        viewBtn: this.permission.health_bsmeterweekreportitem_view,
        delBtn: this.permission.health_bsmeterweekreportitem_delete,
        editBtn: this.permission.health_bsmeterweekreportitem_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    toItem (row) {
      this.$router.push({
        path: '/health/health_plan',
        query: {
          wxUserId: row.wxUserId,
          id: row.id
        }
      })
    },
    toItem1 (row) {
      this.$router.push({
        path: '/health/followUpPlanNew',
        query: {
          wxUserId: row.wxUserId,
          id: row.id
        }
      })
    },
    rowSave (row) {
      submit(row).then(() => {
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    },
    rowUpdatestate (row, done, loading) {
      row.ifComplete = 1
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },

    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = {}) {
      params.channel = window.localStorage.getItem('channel')
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        console.log(data)
      })
    }
  }
}
</script>
