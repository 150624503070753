<template>
  <basic-container>
  <div>
    <avue-crud :option="option" :data="data" :page.sync="page" ref="crud" v-model="form"
      :permission="permissionList"
      @search-change="searchChange" @search-reset="searchReset" @refresh-change="refreshChange"
      @selection-change="selectionChange" @on-load="onLoad">
      <template slot="menuLeft">
        <!-- <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.qa_qa_delete"
          @click="handleDelete">删 除
        </el-button> -->
      </template>
      <!-- <template slot-scope="scope" slot="menu">
        <el-button type="text" icon="el-icon-s-operation" size="small" @click.native="toItemShow(scope.row)">查看
        </el-button>
      </template> -->
      <template slot-scope="scope" slot="menu">
        <el-button type="text" icon="el-icon-s-operation" size="small" @click.native="toItemModify(scope.row)">查看档案
        </el-button>
      </template>
    </avue-crud>
  </div>
    <div>
      <div style="font-size: 20px; margin-top: 10px; margin-bottom: 10px;"> 药物方案 </div>
      <avue-crud
          :option="option1"
          :data="dataList"
          :page.sync="page1"
          v-model="form1"
          @row-del="rowDel1"
          :permission="permissionList"
          @row-update="rowUpdate1"
          @row-save="rowSave1"
          :before-open="beforeOpen1"
          @on-load="onLoad">
      </avue-crud>
    </div>
  </basic-container>
</template>

<script>
// import { getList, submit, remove, getDetail } from '@/api/sys/speech_template'
import { getList } from '@/api/health/archiveType'
import { getDurg, getListUDP, getDetailUDP, removeUDP, submitUDP } from '@/api/health/plan_details'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      form1: {},
      wxUserId: 0,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      page1: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        addBtn: false,
        editBtn: false,
        addRowBtn: false,
        cellBtn: true,
        delBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        selection: true,
        menuWidth: 300,
        column: [
          {
            label: 'id',
            prop: 'id',
            cell: false
          },
          {
            label: '档案类型',
            prop: 'type',
            cell: true,
            rules: [{
              required: true,
              message: '请输入档案类型'
            }]
          },
          {
            label: '详情',
            prop: 'url',
            search: true,
            dicData: [
              { label: '报告类型', value: 0 }
            ]
          }
        ]
      },
      option1: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        addBtn: true,
        menuWidth: 200,
        column: [
          {
            type: 'tree',
            label: '选择药品 ',
            prop: 'ssProvince',
            span: 24,
            hide: true,
            addDisplay: true,
            editDisabled: false,
            dicUrl: 'https://dbadmin.dbzdjk.com/admin/fy/rest/third/DmTest',
            dicMethod: 'post',
            props: {
              label: 'name',
              value: 'code'
            },
            slot: true,
            nodeClick: (data) => {
              getDurg(data.code).then(res => {
                const data1 = res.data
                this.form1.drugName = data1.drugName
                this.form1.tradeName = data1.tradeName
                this.form1.indications = data1.indications
                this.form1.dosageTaken = data1.dosageTaken
                this.form1.medicationFrequency = data1.medicationFrequency
                this.form1.medicationTime = data1.medicationTime
                this.form1.note = data1.note
              })
            }
          },
          {
            label: '药品名称',
            prop: 'drugName',
            span: 24,
            cell: true,
            rules: [{
              required: true,
              message: '请输入药品名称'
            }]
          },
          {
            label: '商品名称',
            prop: 'tradeName',
            span: 24,
            cell: true,
            rules: [{
              required: true,
              message: '请输入商品名称'
            }]
          },
          {
            label: '适用症状',
            prop: 'indications',
            span: 24,
            cell: true,
            rules: [{
              required: true,
              message: '请输入药品名称'
            }]
          },
          {
            label: '服用剂量',
            prop: 'dosageTaken',
            span: 24,
            cell: true,
            rules: [{
              required: true,
              message: '请输入服用剂量'
            }]
          },
          {
            label: '服用频次',
            prop: 'medicationFrequency',
            span: 24,
            cell: true,
            rules: [{
              required: true,
              message: '请输入服用频次'
            }]
          },
          {
            label: '服用时间',
            prop: 'medicationTime',
            span: 24,
            cell: true,
            rules: [{
              required: true,
              message: '请输入服用时间'
            }]
          },
          {
            label: '注意事项',
            prop: 'note',
            span: 24,
            cell: true,
            rules: [{
              required: false,
              message: '请输入注意事项'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            hide: true,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            hide: true,
            cell: false
          }
        ]
      },
      data: [],
      dataList: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qa_add,
        viewBtn: this.permission.qa_qa_view,
        delBtn: this.permission.qa_qa_delete,
        editBtn: this.permission.qa_qa_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  created () {
    this.wxUserId = this.$route.query.wxUserId
  },
  methods: {
    toItemModify (row) {
      this.$router.push({
        path: '/health/archiveTypeDetails',
        query: {
          wxUserId: this.wxUserId,
          type: row.type
        }
      })
    },
    // toItemShow (row) {
    //   this.$router.push({
    //     path: '/health/archiveTypeDetails',
    //     query: {
    //       wxUserId: this.wxUserId,
    //       type: row.type,
    //       boolean: true
    //     }
    //   })
    // },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    rowSave1 (row, done, loading) {
      row.isUsing = '1'
      row.wxUserId = this.wxUserId
      submitUDP(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate1 (row, index, done, loading) {
      row.wxUserId = this.wxUserId
      submitUDP(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel1 (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return removeUDP(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    beforeOpen1 (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetailUDP(this.form1.id).then(res => {
          this.form1 = res.data
        })
      }
      done()
    },
    onLoad (page, params = { id: this.wxUserId }) {
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data
        // console.log('测试')
        // console.log(res)
        // console.log(this.wxUserId)
      })

      getListUDP(this.wxUserId).then(res => {
        const data1 = res.data.records
        this.page1.total1 = data1.total
        this.dataList = data1
      })
    }
  }
}
</script>

<style></style>
