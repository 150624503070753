<template>
  <basic-container>
    <avue-crud :option="option"
               :data="data"
               ref="crud"
               v-model="form"
               :permission="permissionList"
               @row-del="rowDel"
               @row-update="rowUpdate"
               @row-save="rowSave"
               :before-open="beforeOpen"
               :page.sync="page"
               @refresh-change="refreshChange"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @on-load="onLoad">
      <template slot="menuLeft">
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button type="text" size="small" @click="archiveType(scope.row)">
          数字档案
        </el-button>
        <el-button type="text" size="small" @click="datashow(scope.row)">
          健康达标评估
        </el-button>
        <el-button type="text" size="small" @click="jdReport(scope.row)">
          重大疾病预警
        </el-button>
        <el-button type="text" size="small" @click="plan(scope.row)">
          健康方案调整
        </el-button>
        <el-button type="text" size="small" @click="followUpPlanNew(scope.row)">
          数字行为指导
        </el-button>
        <el-button type="text" size="small" @click="followUpPlanNew(scope.row)">
          上门随诊时间
        </el-button>
        <!-- <el-button type="text" size="small" @click="archiveType(scope.row)">
          档案信息
        </el-button>
        <el-button type="text" size="small" @click="datashow(scope.row)">
          动态数据展示
        </el-button>
        <el-button type="text" size="small" @click="jdReport(scope.row)">
          报告解读
        </el-button>
        <el-button type="text" size="small" @click="plan(scope.row)">
          方案管理
        </el-button>
        <el-button type="text" size="small" @click="followUpPlanNew(scope.row)">
          健康随诊方案
        </el-button> -->
      </template>

    </avue-crud>
  </basic-container>
</template>

<script>
import { getDetail, submit, remove, enable, disable, getBasedOnLevelList } from '@/api/health/userjk'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        editBtn: false,
        addBtn: false,
        delBtn: false,
        selection: true,
        column: [
          {
            label: '用户名',
            prop: 'name',
            search: true
          },
          // {
          //   label: 'VIP等级',
          //   prop: 'vipLevel',
          //   // search: true,
          //   type: 'select',
          //   dicData: [
          //     { label: '基础会员', value: 1 },
          //     { label: '体验会员', value: 2 },
          //     { label: '全年会员', value: 3 },
          //     { label: 'vip会员', value: 6 }
          //   ]
          //   // dicUrl: '/sys/dict/code/isVip'
          // },
          // {
          //   label: '会员级别',
          //   prop: 'vipLevel'
          // },
          {
            label: '性别',
            prop: 'sex',
            type: 'select',
            // dicUrl: '/sys/dict/code/sex'
            dicData: [
              { label: '未填写', value: 0 },
              { label: '男', value: 1 },
              { label: '女', value: 2 }
            ]
          },
          {
            label: '手机号码',
            prop: 'mobile',
            search: true
          },
          {
            label: '年龄',
            prop: 'age',
            dicData: [
              { label: '未填写', value: 1 }
            ],
            rules: [{
              required: true,
              message: '请输入年龄'
            }]
          },
          {
            label: '身高',
            prop: 'height',
            rules: [{
              required: true,
              message: '请输入身高'
            }]
          },
          {
            label: '体重',
            prop: 'weight',
            rules: [{
              required: true,
              message: '请输入体重'
            }]
          },
          // {
          //   label: '健康调整次数',
          //   prop: 'diseaseRiskCount',
          //   search: true
          // },
          // {
          //   label: '注册时间',
          //   prop: 'createTime',
          //   rules: [{
          //     required: true,
          //     message: '请输入创建时间'
          //   }]
          // }
          // {
          //   label: '会员开始时间',
          //   prop: 'createTime',
          //   rules: [{
          //     required: true,
          //     message: '请输入创建时间'
          //   }]
          // },
          // {
          //   label: '会员到期时间',
          //   prop: 'createTime',
          //   rules: [{
          //     required: true,
          //     message: '请输入创建时间'
          //   }]
          // }
          {
            label: '会员开始时间',
            prop: 'vipPurchase',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          },
          {
            label: '会员到期时间',
            prop: 'vipExpired',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          }
          /* {
            label: '是否打鼾',
            prop: 'snore',
            rules: [{
              required: true,
              message: '请输入是否打鼾'
            }]
          },
          {
            label: '是否抽烟',
            prop: 'smoke',
            rules: [{
              required: true,
              message: '请输入是否抽烟'
            }]
          },
          {
            label: '每天抽几根烟',
            prop: 'smokeTimes',
            rules: [{
              required: true,
              message: '请输入每天抽几根烟'
            }]
          },
          {
            label: '锻炼方式',
            prop: 'exerciseType',
            rules: [{
              required: true,
              message: '请输入锻炼方式'
            }]
          },
          {
            label: '习惯锻炼时间',
            prop: 'exerciseTime',
            rules: [{
              required: true,
              message: '请输入习惯锻炼时间'
            }]
          },
          {
            label: '锻炼次数',
            prop: 'exerciseTimes',
            rules: [{
              required: true,
              message: '请输入锻炼次数'
            }]
          },
          {
            label: '锻炼小时',
            prop: 'exerciseHours',
            rules: [{
              required: true,
              message: '请输入锻炼小时'
            }]
          },*/
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.health_userjk_add,
        viewBtn: this.permission.health_userjk_view,
        delBtn: this.permission.health_userjk_delete,
        editBtn: this.permission.health_userjk_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      params.vipLevel = 6
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    handleEnable () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定对该用户启用VIP嘛?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return enable(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    handleDisable () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定对该用户停用VIP嘛?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return disable(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = { vipLevel: 6, channel: window.localStorage.getItem('channel') }) {
      getBasedOnLevelList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        console.log('ceshiyixia')
        console.log(res.data)
      })
    },
    handleDetail (data) {
      this.$router.push({
        path: '/health/bpmeterdata_detail',
        query: { wxUserId: data.id, meterDate: this.params.meterDate, jkStatus: data.jkStatus }
      })
    },
    datashow (row) {
      console.log(row)
      const parameter = {
        wxUserId: row.wxUserId
      }
      this.$router.push({
        path: '/health/datashow',
        query: parameter
      })
    },
    jdReport (row) {
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/reportvip-jd',
        query: parameter
      })
    },
    archiveType (row) {
      const parameter = {
        wxUserId: row.wxUserId
      }
      this.$router.push({
        path: '/health/archiveType',
        query: parameter
      })
    },
    plan (row) {
      const parameter = {
        wxUserId: row.wxUserId
      }
      this.$router.push({
        path: '/health/health_plan',
        query: parameter
      })
    },
    followUpPlanNew (row) {
      console.log(row)
      const parameter = {
        wxUserId: row.wxUserId
      }
      this.$router.push({
        path: '/health/followUpPlanNew',
        query: parameter
      })
    }
  }
}
</script>
