import { request } from '@/util/http'

export const selectOne = (params) => {
  return request({
    url: '/sys/user/selectOne',
    method: 'get',
    params: {
      params
    }
  })
}

export const resetPassword = (userIds) => {
  return request({
    url: '/sys/user/reset-password',
    method: 'post',
    params: {
      userIds
    }
  })
}
export const getList = (current, size, params) => {
  return request({
    url: '/sys/user/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
export const remove = (ids) => {
  return request({
    url: '/sys/user/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/sys/user/submit',
    method: 'post',
    data: row
  })
}

export const getUser = (id) => {
  return request({
    url: '/sys/user/detail',
    method: 'get',
    params: {
      id
    }
  })
}

