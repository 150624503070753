/**
 * 通用工具类
 */
export default class func {
  /**
     * 不为空
     * @param val
     * @returns {boolean}
     */
  static notEmpty (val) {
    return !this.isEmpty(val)
  }

  /**
     * 是否为定义
     * @param val
     * @returns {boolean}
     */
  static isUndefined (val) {
    return val === null || typeof val === 'undefined'
  }

  /**
     * 为空
     * @param val
     * @returns {boolean}
     */
  static isEmpty (val) {
    if (
      val === null ||
            typeof val === 'undefined' ||
            (typeof val === 'string' && val === '' && val !== 'undefined')
    ) {
      return true
    }
    return false
  }

  /**
     * 强转int型
     * @param val
     * @param defaultValue
     * @returns {number}
     */
  static toInt (val, defaultValue) {
    if (this.isEmpty(val)) {
      return defaultValue === undefined ? -1 : defaultValue
    }
    const num = parseInt(val, 0)
    return Number.isNaN(num) ? (defaultValue === undefined ? -1 : defaultValue) : num
  }

  /**
     * Json强转为Form类型
     * @param obj
     * @returns {FormData}
     */
  static toFormData (obj) {
    const data = new FormData()
    Object.keys(obj).forEach(key => {
      data.append(key, Array.isArray(obj[key]) ? obj[key].join(',') : obj[key])
    })
    return data
  }

  static format (date, fmt) {
    let ret
    const opt = {
      'Y+': date.getFullYear().toString(), // 年
      'm+': (date.getMonth() + 1).toString(), // 月
      'd+': date.getDate().toString(), // 日
      'H+': date.getHours().toString(), // 时
      'M+': date.getMinutes().toString(), // 分
      'S+': date.getSeconds().toString() // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (const k in opt) {
      ret = new RegExp('(' + k + ')').exec(fmt)
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
      };
    };
    return fmt
  }

  /**
     * 根据逗号联合
     * @param arr
     * @returns {string}
     */
  static join (arr) {
    return arr ? arr.join(',') : ''
  }

  /**
     * 根据逗号分隔
     * @param str
     * @returns {string}
     */
  static split (str) {
    return str ? String(str).split(',') : ''
  }

  static uuid () {
    const s = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    return s.join('')
  }

  static getFileType (filePath) {
    const startIndex = filePath.lastIndexOf('.')
    if (startIndex !== -1) { return filePath.substring(startIndex + 1, filePath.length).toLowerCase() } else return ''
  }
}
