import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/sys/code/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const build = (ids, newModule) => {
  return request({
    url: '/sys/code/gen',
    method: 'post',
    params: {
      ids,
      newModule
    }
  })
}
export const remove = (ids) => {
  return request({
    url: '/sys/code/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const add = (row) => {
  return request({
    url: '/sys/code/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/sys/code/submit',
    method: 'post',
    data: row
  })
}

export const getCode = (id) => {
  return request({
    url: '/sys/code/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const getWeek = (date) => {
  return request({
    url: '/sys/dict/week',
    method: 'get',
    params: {
      date
    }
  })
}
