import { request } from '@/util/http'

// export const getList = (current, size, params) => {
//   return request({
//     url: '/health/bsmeterdataitem/getList',
//     method: 'get',
//     params: {
//       ...params,
//       current,
//       size
//     }
//   })
// }

export const getList = (current, size, params) => {
  return request({
    url: '/health/bometerdata/getList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const submitForm = (row) => {
  return request({
    url: '/health/bometerdata/submitFormBo',
    method: 'post',
    data: row
  })
}

export const boZcReport = (row) => {
  return request({
    url: '/health/bometerdata/boZcReport',
    method: 'post',
    data: row
  })
}

