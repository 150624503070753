<template>
    <basic-container>
        <avue-crud :option="option"
                   :data="data"
                   :page.sync="page"
                   ref="crud"
                   @row-del="rowDel"
                   v-model="form"
                   :permission="permissionList"
                   @row-update="rowUpdate"
                   @row-save="rowSave"
                   :before-open="beforeOpen"
                   @search-change="searchChange"
                   @search-reset="searchReset"
                   @refresh-change="refreshChange"
                   @selection-change="selectionChange"
                   @on-load="onLoad">
            <template slot="menuLeft">
                <el-button type="danger"
                           size="small"
                           icon="el-icon-delete"
                           plain
                           v-if="permission.qa_qapgpz_delete"
                           @click="handleDelete">删 除
                </el-button>
                <el-button size="small"
                           plain
                           icon="el-icon-back" @click="handleBack()">返 回
                </el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { getList, getDetail, submit, remove } from '@/api/qa/qapgpz'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '总分最小值（>=）',
            prop: 'scoreMin'
          },
          {
            label: '总分最大值(<)',
            prop: 'scoreMax'
          },
          {
            label: '阳性项最小数（>=）',
            prop: 'yxxMin'
          },
          {
            label: '阳性项最大数（<）',
            prop: 'yxxMax'
          },
          {
            label: '因子项阈值',
            prop: 'yzxScore'
          },
          {
            label: '因子类型(如2<阈值)',
            prop: 'yzxType',
            type: 'select',
            dicUrl: '/sys/dict/code/yzx_type'
          },
          {
            label: '类型',
            prop: 'type',
            type: 'select',
            dicUrl: '/sys/dict/code/pgpe_type'
          },
          {
            label: '描述',
            prop: 'des',
            type: 'textarea',
            span: 24
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qapgpz_add,
        viewBtn: this.permission.qa_qapgpz_view,
        delBtn: this.permission.qa_qapgpz_delete,
        editBtn: this.permission.qa_qapgpz_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  created () {
    console.log(this.$route.query.qaId)
  },
  methods: {
    rowSave (row, done, loading) {
      row.qaId = this.$route.query.qaId
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      row.qaId = this.$route.query.qaId
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    handleBack () {
      this.$router.back()
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = {}) {
      params.qaId = this.$route.query.qaId
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>
