<template>
  <basic-container>
    <div style="padding: 20px 0;">
      <i style="margin-right: 4px;" class="el-icon-time"></i>报告隶属时间段：
      <el-date-picker
          v-model="dateValue"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :readonly="false">
      </el-date-picker>
      {{ "\u3000\u3000\u3000" }}
      <el-button type="primary" @click="searchBtn">搜索</el-button>
      <el-button size="small"
                 plain
                 icon="el-icon-back" @click="handleBack()">返回上一级
      </el-button>
    </div>
    <div v-if="tabType=='1'">
      <avue-crud
          :option="option"
          :data="data"
          :page.sync="page"
          ref="crud"
          @row-del="rowDel"
          v-model="form"
          :permission="permissionList"
          @row-update="rowUpdate"
          @row-save="rowSave"
          :before-open="beforeOpen"
          @search-change="searchChange"
          @search-reset="searchReset"
          @refresh-change="refreshChange"
          @selection-change="selectionChange"
          @on-load="onLoad">
      </avue-crud>
    </div>
  </basic-container>
</template>

<script>
import { getDetail, submit, remove, getSerachList } from '@/api/health/bsmeterdata'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      orgOptions: {},
      form: {},
      dateValue: [],
      tabType: 1,
      picList: null,
      linList: null,
      params: {
        wxUserId: '',
        week: '',
        // type: '1'
        beginDate: '',
        endDate: ''
      },
      weekDate: '',
      page: {
        pageSize: 50,
        currentPage: 1,
        total: 0
      },
      free: '',
      freeOptions: {
        // 普通图片上传
        customConfig: {}, // wangEditor编辑的配置
        oss: 'ali',
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-shanghai',
          endpoint: 'oss-cn-shanghai.aliyuncs.com',
          accessKeyId: 'LTAI4GGYDGZjaZQyshkjPBJH',
          accessKeySecret: '5O9VCIRDa6AqHtVj2NDiMUO9Uv2GDe',
          bucket: 'health-planet'
        }
      },
      pay: '',
      payOptions: {
        // 普通图片上传
        action: 'https://avuejs.com/imgupload',
        customConfig: {}, // wangEditor编辑的配置
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-beijing',
          endpoint: 'oss-cn-beijing.aliyuncs.com',
          accessKeyId: '',
          accessKeySecret: '',
          bucket: 'avue'
        }
      },
      selectionList: [],
      option: {
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '时间段',
            prop: 'type',
            type: 'select',
            dicData: [
              { label: '随机', value: 9 },
              { label: '早餐前', value: 1 },
              { label: '早餐后', value: 2 },
              { label: '午餐前', value: 3 },
              { label: '午餐后', value: 4 },
              { label: '晚餐前', value: 5 },
              { label: '晚餐后', value: 6 },
              { label: '睡前', value: 7 },
              { label: '凌晨', value: 8 }
            ]
          },
          {
            label: '测量时间',
            prop: 'bsDateTime'
          },
          {
            label: '血糖数值',
            prop: 'bsValue'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.health_bsmeterdata_add,
        viewBtn: this.permission.health_bsmeterdata_view,
        delBtn: this.permission.health_bsmeterdata_delete,
        editBtn: this.permission.health_bsmeterdata_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  created () {
    this.params.wxUserId = this.$route.query.wxUserId || '' // eslint-disable-line no-unused-vars
    this.params.week = this.$route.query.meterDate || '' // eslint-disable-line no-unused-vars
  },
  mounted () {
  },
  methods: {
    handleBack () {
      this.$router.back()
    },
    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },

    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page) {
      if (this.dateValue == null) {
        this.params.beginDate = ''
        this.params.endDate = ''
      } else {
        this.params.beginDate = this.dateValue[0] || ''
        this.params.endDate = this.dateValue[1] || ''
      }
      getSerachList(page.currentPage, page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        console.log(this.data)
      })
    },
    searchBtn (data) {
      if (this.dateValue == null) {
        this.params.beginDate = ''
        this.params.endDate = ''
      } else {
        this.params.beginDate = this.dateValue[0] || ''
        this.params.endDate = this.dateValue[1] || ''
      }
      getSerachList(this.page.currentPage, this.page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}

</script>

<style>
.memberTit{
  color: #5a88d3;
  font-size: 16px;
  margin-bottom: 20px;
}
.memberTit::before{
  content: '';
  border-left: 6px solid #5a88d3;
  margin-right: 20px;
  background: #5a88d3;
}
</style>
