import { request } from '@/util/http'

export const gettest = () => {
  return request({
    url: 'https://dbadmin.dbzdjk.com/admin/health/bsmeterdataitem/getList?wxUserId=1626142636698456065&week=2023-05-22&current=1&size=20',
    method: 'get',
    params: {

    }
  })
}

export const getList = () => {
  return request({
    url: '/fy/rest/third/getopenlink',
    method: 'post',
    params: {

    }
  })
}
