<template>
  <basic-container>
    <el-upload
              action="#"
              ref="upload"
              list-type="picture-card"
              :http-request="fnUploadRequest">
                    <el-button size="small" type="primary">点击上传PDF报告</el-button>
          </el-upload>
          <!-- <el-upload
              action="#"
              ref="upload"
              list-type="picture-card"
              :http-request="fnUploadRequest">
                    <el-button size="small" type="primary">点击上传图片报告</el-button>
          </el-upload> -->
    <avue-crud
      :option="option"
      :data="data"
      :page.sync="page"
      ref="crud"
      v-model="form"
      :permission="permissionList"
      @row-update="rowUpdate"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @refresh-change="refreshChange"
      @selection-change="selectionChange"
      @on-load="onLoad">
      <template slot="menuLeft">
        <!-- <el-button type="danger" size="small" icon="el-icon-delete"
          @click="handleDelete">删 除
        </el-button> -->
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button v-if="boolean" type="text" icon="el-icon-s-operation" size="small" @click.native="openArchive(scope.row)">查看报告
        </el-button>
        <!-- <el-button v-if="boolean" type="text" icon="el-icon-s-operation" size="small" @click.native="openArchive(scope.row)">删除报告
        </el-button> -->
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
// import { getList, submit, remove, getDetail } from '@/api/sys/speech_template'
import { getListDeatils1, getListDeatils2, submit01, submit02, submit1, submit2, remove1, remove2 } from '@/api/health/archiveType'
import { mapGetters } from 'vuex'

const OSS = require('ali-oss')
const client = new OSS({
  region: 'oss-cn-shanghai',
  endpoint: 'oss-cn-shanghai.aliyuncs.com',
  accessKeyId: 'LTAI4GGYDGZjaZQyshkjPBJH',
  accessKeySecret: '5O9VCIRDa6AqHtVj2NDiMUO9Uv2GDe',
  bucket: 'health-planet'
})

export default {
  data () {
    return {
      form: {},
      ifshow: true,
      test: true,
      boolean: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        editBtn: this.boolean,
        selection: true,
        delBtn: true,
        addBtn: false,
        menuWidth: 300,
        column: [
          {
            label: '报告名称',
            prop: 'reportName',
            cell: true
          },
          {
            label: '报告类型',
            prop: 'type',
            addDisplay: false,
            editDisabled: true,
            cell: true,
            span: 24
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qa_add,
        viewBtn: this.permission.qa_qa_view,
        delBtn: this.permission.qa_qa_delete,
        editBtn: this.permission.qa_qa_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  created () {
    this.wxUserId = this.$route.query.wxUserId
    this.type = this.$route.query.type
  },
  methods: {
    async fnUploadRequest (options) {
      try {
        console.log('test')
        console.log(options)
        const file = options.file // 拿到 file
        console.log(file)
        const fileName = file.name.substr(0, file.name.lastIndexOf('.'))
        const fileType = file.name.substr(file.name.lastIndexOf('.') + 1)
        console.log(file.name.lastIndexOf('.'))
        console.log(file.name.substr(file.name.lastIndexOf('.') + 1))
        const date = new Date().getTime()
        const fileNames = `${date}_${fileName}` + '.' + fileType// 拼接文件名，保证唯一，这里使用时间戳+原文件名
        // 上传文件,这里是上传到OSS的文件夹下
        client.put(fileNames, file).then(res => {
          if (res.res.statusCode === 200) {
            // 把上传成功的值保存到imageUrl中
            // console.log('ceshiyixa')
            // console.log(res)
            options.onSuccess(res)
            const params = { url: res.url, wxUserId: this.wxUserId, type: this.type }
            const params1 = { wxUserId: this.wxUserId, type: this.type }
            if (this.type === '体检报告') {
              submit1(params).then(() => {
                this.onLoad(this.page)
                this.$message({
                  type: 'success',
                  message: '操作成功!'
                })
              }).catch(() => {
                this.onLoad(this.page, params1)
              })
            } else {
              submit2(params).then(() => {
                this.onLoad(this.page)
                this.$message({
                  type: 'success',
                  message: '操作成功!'
                })
              }).catch(() => {
                this.onLoad(this.page, params1)
              })
            }
          } else {
            options.onError('上传失败')
          }
        })
      } catch (e) {
        options.onError('上传失败')
      }
    },
    openArchive (row) {
      const url = row.url
      const typeDetails = url.substring(url.length - 3)
      if (typeDetails === 'pdf') {
        window.open(row.url, '_blank')
      } else if (typeDetails === 'png' || typeDetails === 'jpg') {
        const newWindow = window.open('')
        newWindow.document.body.innerHTML = '<img src="' + row.url + '" alt="">'
      }
    },
    // rowSave (row, done, loading) {
    //   row.wxUserId = this.wxUserId
    //   row.parentId = this.id
    //   submit(row).then(() => {
    //     done()
    //     this.onLoad(this.page)
    //     this.$message({
    //       type: 'success',
    //       message: '操作成功!'
    //     })
    //   }).catch(() => {
    //     loading()
    //   })
    // },
    rowUpdate (row, index, done, loading) {
      if (this.type === '体检报告') {
        submit01(row).then(() => {
          done()
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        }).catch(() => {
          loading()
        })
      } else {
        submit02(row).then(() => {
          done()
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        }).catch(() => {
          loading()
        })
      }
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.type === '体检报告') {
            return remove1(row.id)
          } else {
            return remove2(row.id)
          }
          // return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    onLoad (page, params = { type: this.type, wxUserId: this.wxUserId }) {
      if (this.type === '体检报告') {
        getListDeatils1(page.currentPage, page.pageSize, params).then(res => {
          const data = res.data
          this.page.total = data.total
          this.data = data.records
          // console.log('测试')
          // console.log(res)
        })
      } else {
        getListDeatils2(page.currentPage, page.pageSize, params).then(res => {
          const data = res.data
          this.page.total = data.total
          this.data = data.records
          // console.log('测试')
          // console.log(res)
        })
      }
    }
  }
}
</script>

<style></style>
