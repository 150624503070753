<template>
  <basic-container>
    <avue-crud :option="option"
               :data="data"
               ref="crud"
               v-model="form"
               :permission="permissionList"
               @row-del="rowDel"
               @row-update="rowUpdate"
               @row-save="rowSave"
               :before-open="beforeOpen"
               :page.sync="page"
               @refresh-change="refreshChange"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @on-load="onLoad">
      <template slot="menuLeft">
<!--        <el-button type="danger"-->
<!--                   size="small"-->
<!--                   icon="el-icon-delete"-->
<!--                   plain-->
<!--                   v-if="permission.health_userjk_delete"-->
<!--                   @click="handleDelete">删 除-->
<!--        </el-button>-->
        <el-button type="primary"
                   size="small"
                   icon="el-icon-check"
                   plain
                   @click="handleEnable">启用VIP
        </el-button>
        <el-button type="danger"
                   size="small"
                   icon="el-icon-close"
                   plain
                   @click="handleDisable">停用VIP
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button type="text" size="small" @click="jdReport(scope.row)">
          VIP报告解读
        </el-button>
        <el-button type="text" size="small" @click="historyTaking(scope.row)">
          VIP病史采集
        </el-button>
        <el-button type="text" size="small" @click="drugplan(scope.row)">
          VIP药物方案
        </el-button>
        <el-button type="text" size="small" @click="healthRecords(scope.row)">
          VIP检查档案
        </el-button>
        <el-button type="text" size="small" @click="followUpPlan(scope.row)">
          VIP随访计划
        </el-button>
        <el-button type="text" size="small"
                   @click.stop="handleDetail(scope.row,scope.index)">
          血压查询
        </el-button>
<!--        <el-button type="text" size="small"-->
<!--                   @click.stop="rechargeConsume(scope.row,scope.index)">-->
<!--          VIP充值消费-->
<!--        </el-button>-->
        <el-button type="text" size="small"
                   @click.stop="bloodSugarDetail(scope.row,scope.index)">
          血糖查询
        </el-button>
      </template>

    </avue-crud>
  </basic-container>
</template>

<script>
import { getVipList, getDetail, submit, remove, enable, disable } from '@/api/health/userjk'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        editBtn: false,
        addBtn: false,
        delBtn: false,
        selection: true,
        column: [
          {
            label: '姓名',
            prop: 'name',
            search: true
          },
          {
            label: '是否是VIP',
            prop: 'isVip',
            // search: true,
            type: 'select',
            dicUrl: '/sys/dict/code/isVip'
          },
          // {
          //   label: '会员级别',
          //   prop: 'vipLevel'
          // },
          {
            label: '性别',
            prop: 'sex',
            type: 'select',
            dicUrl: '/sys/dict/code/sex'
          },
          {
            label: '手机号码',
            prop: 'mobile',
            search: true
          },
          {
            label: '年龄',
            prop: 'age',
            rules: [{
              required: true,
              message: '请输入年龄'
            }]
          },
          {
            label: '身高',
            prop: 'height',
            rules: [{
              required: true,
              message: '请输入身高'
            }]
          },
          {
            label: '体重',
            prop: 'weight',
            rules: [{
              required: true,
              message: '请输入体重'
            }]
          }
          /* {
            label: '是否打鼾',
            prop: 'snore',
            rules: [{
              required: true,
              message: '请输入是否打鼾'
            }]
          },
          {
            label: '是否抽烟',
            prop: 'smoke',
            rules: [{
              required: true,
              message: '请输入是否抽烟'
            }]
          },
          {
            label: '每天抽几根烟',
            prop: 'smokeTimes',
            rules: [{
              required: true,
              message: '请输入每天抽几根烟'
            }]
          },
          {
            label: '锻炼方式',
            prop: 'exerciseType',
            rules: [{
              required: true,
              message: '请输入锻炼方式'
            }]
          },
          {
            label: '习惯锻炼时间',
            prop: 'exerciseTime',
            rules: [{
              required: true,
              message: '请输入习惯锻炼时间'
            }]
          },
          {
            label: '锻炼次数',
            prop: 'exerciseTimes',
            rules: [{
              required: true,
              message: '请输入锻炼次数'
            }]
          },
          {
            label: '锻炼小时',
            prop: 'exerciseHours',
            rules: [{
              required: true,
              message: '请输入锻炼小时'
            }]
          },*/
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.health_userjk_add,
        viewBtn: this.permission.health_userjk_view,
        delBtn: this.permission.health_userjk_delete,
        editBtn: this.permission.health_userjk_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    handleEnable () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定对该用户启用VIP嘛?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return enable(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    handleDisable () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定对该用户停用VIP嘛?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return disable(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = {}) {
      getVipList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        console.log('ceshiyixia')
        console.log(res.data)
      })
    },
    handleDetail (data) {
      this.$router.push({
        path: '/health/bpmeterdata_value',
        query: { wxUserId: data.wxUserId }
      })
    },
    // rechargeConsume (data) {
    //   this.$router.push({
    //     path: '/health/rechargeconsume',
    //     query: { wxUserId: data.wxUserId }
    //   })
    // },
    bloodSugarDetail (data) {
      this.$router.push({
        path: '/health/bsmeterdata_value',
        query: { wxUserId: data.wxUserId }
      })
    },
    jdReport (row) {
      console.log(row)
      if (row.isVip !== 1) {
        this.$message.error('VIP用户才可解读报告')
        return
      }
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/reportvip-jd',
        query: parameter
      })
    },
    historyTaking (row) {
      console.log(row)
      if (row.isVip !== 1) {
        this.$message.error('VIP用户才可进行病史采集')
        return
      }
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/historytaking',
        query: parameter
      })
    },
    drugplan (row) {
      console.log(row)
      if (row.isVip !== 1) {
        this.$message.error('VIP用户才可录入药物方案')
        return
      }
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/drugplan',
        query: parameter
      })
    },
    followUpPlan (row) {
      console.log(row)
      if (row.isVip !== 1) {
        this.$message.error('VIP用户才可进行随访计划')
        return
      }
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/followupplan',
        query: parameter
      })
    },
    healthRecords (row) {
      console.log(row)
      if (row.isVip !== 1) {
        this.$message.error('VIP用户才可录入检查档案')
        return
      }
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/healthrecords',
        query: parameter
      })
    }
  }
}
</script>
