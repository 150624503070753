<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        :before-open="beforeOpen"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.dept_delete"
            plain
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template
          slot-scope="{row}"
          slot="roleId">
        <el-tag>{{ row.roleName }}</el-tag>
      </template>
      <template
          slot-scope="{row}"
          slot="deptId">
        <el-tag>{{ row.deptName }}</el-tag>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import {
  getList,
  remove,
  update,
  add,
  getDept
} from '@/api/sys/dept'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        tree: true,
        border: true,
        viewBtn: true,
        column: [
          {
            label: '部门名称',
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              message: '请输入部门名称'
            }]
          },
          {
            label: '部门全称',
            prop: 'fullName',
            search: true,
            rules: [{
              required: true,
              message: '请输入部门全称'
            }]
          },
          {
            label: '上级部门',
            prop: 'parentId',
            dicUrl: '/sys/dept/tree',
            type: 'tree',
            hide: true,
            props: {
              value: 'id',
              label: 'name'
            },
            rules: [{
              required: false,
              message: '请选择上级部门',
              trigger: 'click'
            }]
          },

          {
            label: '排序',
            prop: 'sort',
            type: 'number',
            rules: [{
              required: true,
              message: '请输入排序'
            }]
          },

          {
            label: '备注',
            prop: 'remark',
            span: 24,
            hide: true,
            rules: [{
              required: false,
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_dept_add, false),
        viewBtn: this.vaildData(this.permission.sys_dept_view, false),
        delBtn: this.vaildData(this.permission.sys_dept_delete, false),
        editBtn: this.vaildData(this.permission.sys_dept_edit, false)
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    rowSave (row, done) {
      add(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }, () => {
        done()
      })
    },
    rowUpdate (row, index, done) {
      update(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }, () => {
        done()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(row.id).then(() => {
          this.onLoad(this.page)
        })
      })
    },

    handleDelete () {
      const _this = this
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return remove(this.ids).then(() => {
          _this.onLoad(this.page)
          _this.$refs.crud.toggleSelection()
        })
      })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params, done)
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDept(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = {}, callback) {
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.data = data
        if (callback) {
          callback()
        }
      })
    }
  }
}
</script>

<style>
</style>
