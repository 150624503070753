<template>
  <basic-container>
    <div style="padding: 20px 0;">
      <i style="margin-right: 4px;" class="el-icon-time"></i>报告隶属时间段：
      <el-date-picker
          v-model="dateValue"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :readonly="false">
      </el-date-picker>
      {{ "\u3000\u3000\u3000" }}
      <el-button type="primary" @click="searchBtn">搜索</el-button>
      <el-button size="small"
                 plain
                 icon="el-icon-back" @click="handleBack()">返回上一级
      </el-button>
    </div>
    <div>
      <div id="lineChart" :style="{width:'100%', height: '600px'}"></div>
      <div style="font-size: 20px; margin-top: 10px;"> 血糖表 </div>
      <avue-crud
          :option="option"
          :data="data"
          :page.sync="page"
          ref="crud"
          v-model="form"
          :permission="permissionList"
          @search-change="searchChange"
          @search-reset="searchReset"
          @refresh-change="refreshChange"
          @selection-change="selectionChange"
          @on-load="onLoad">
      </avue-crud>
    </div>
  </basic-container>
</template>

<script>

import {
  getSerachListbs
} from '@/api/health/datashow'
import { mapGetters } from 'vuex'
import { bsDataValueNew } from '../../api/health/datashow'

export default {
  components: { },
  data () {
    return {
      showdata: false,
      orgOptions: {},
      form: {},
      dateValue: [],
      tabType: 1,
      picList: null,
      linList: null,
      params: {
        wxUserId: '',
        week: '',
        type: '1',
        jkStatus: '',
        beginDate: '',
        endDate: ''
      },
      bpTrend: [],
      weekDate: '',
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      free: '',
      freeOptions: {
        // 普通图片上传
        customConfig: {}, // wangEditor编辑的配置
        oss: 'ali',
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-shanghai',
          endpoint: 'oss-cn-shanghai.aliyuncs.com',
          accessKeyId: 'LTAI4GGYDGZjaZQyshkjPBJH',
          accessKeySecret: '5O9VCIRDa6AqHtVj2NDiMUO9Uv2GDe',
          bucket: 'health-planet'
        }
      },
      pay: '',
      payOptions: {
        // 普通图片上传
        action: 'https://avuejs.com/imgupload',
        customConfig: {}, // wangEditor编辑的配置
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-beijing',
          endpoint: 'oss-cn-beijing.aliyuncs.com',
          accessKeyId: '',
          accessKeySecret: '',
          bucket: 'avue'
        }
      },
      selectionList: [],
      option: {
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '时间段',
            prop: 'type',
            type: 'select',
            dicData: [
              { label: '随机', value: 9 },
              { label: '早餐前', value: 1 },
              { label: '早餐后', value: 2 },
              { label: '午餐前', value: 3 },
              { label: '午餐后', value: 4 },
              { label: '晚餐前', value: 5 },
              { label: '晚餐后', value: 6 },
              { label: '睡前', value: 7 },
              { label: '凌晨', value: 8 }
            ]
          },
          {
            label: '测量时间',
            prop: 'bsDateTime'
          },
          {
            label: '血糖数值',
            prop: 'bsValue'
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.params.wxUserId = this.$route.query.wxUserId || '' // eslint-disable-line no-unused-vars
    this.params.week = this.$route.query.meterDate || '' // eslint-disable-line no-unused-vars
    this.params.jkStatus = this.$route.query.jkStatus || ''
    this.params.beginDate = this.$route.query.beginDate || ''
    this.params.endDate = this.$route.query.endDate || ''
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.health_bpmeterdata_add,
        viewBtn: this.permission.health_bpmeterdata_view,
        delBtn: this.permission.health_bpmeterdata_delete,
        editBtn: this.permission.health_bpmeterdata_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    drawLine (list) {
      const myChart = this.$echarts.init(document.getElementById('lineChart'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: '血糖数据折现变化图',
          subtext: '单位:mmol/L'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['血糖']
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: { readOnly: false },
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: list.map(v => v.meterDate)
        },
        yAxis: {
          type: 'value',
          min: 0,
          axisLabel: {
            formatter: '{value} mmol/L'
          }
        },
        series: [
          {
            name: '血糖',
            type: 'line',
            data: list.map(v => v.avgBsValue),
            markPoint: {
              data: [
                { type: 'max', name: '最大值' },
                { type: 'min', name: '最小值' }
              ]
            },
            markLine: {
              data: [
                { type: 'average', name: '平均值' }
              ]
            }
          }
        ]
      })
    },

    handleBack () {
      this.$router.back()
    },

    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)

      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },

    onLoad () {
      bsDataValueNew(this.params).then(res => {
        this.drawLine(res.data)
      })
      getSerachListbs(this.page.currentPage, this.page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    searchBtn (data) {
      if (this.dateValue == null) {
        this.params.beginDate = ''
        this.params.endDate = ''
      } else {
        this.params.beginDate = this.dateValue[0] || ''
        this.params.endDate = this.dateValue[1] || ''
      }
      bsDataValueNew(this.params).then(res => {
        this.drawLine(res.data)
      })
      getSerachListbs(this.page.currentPage, this.page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>

<style>
.memberTit {
  color: #5a88d3;
  font-size: 16px;
  margin-bottom: 20px;
}

.memberTit::before {
  content: '';
  border-left: 6px solid #5a88d3;
  margin-right: 20px;
  background: #5a88d3;
}
</style>
