import { request } from '@/util/http'

export const getDetail = (id) => {
  return request({
    url: '/health/bpmeterdata/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/health/bpmeterdata/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/health/bpmeterdata/submit',
    method: 'post',
    data: row
  })
}

export const getClassifyFree = (params) => {
  return request({
    url: '/bp/mic/micClassifyByDate',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getAvgByDayFree = (params) => {
  return request({
    url: '/bp/mic/micAvgByDate',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const bpDataValue = (params) => {
  return request({
    url: '/bp/mic/avgByBp',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getSerachList = (current, size, params) => {
  return request({
    url: '/health/bpmeterdata/serachList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getSerachList1 = (current, size, params) => {
  return request({
    url: '/health/bpmeterdata/serachList1',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getSerachListbs = (current, size, params) => {
  return request({
    url: '/health/bsmeterdataitem/serachList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const bsDataValueNew = (params) => {
  return request({
    url: '/health/bsmeterdataitem/avgByBsNew',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getSerachListbo = (current, size, params) => {
  return request({
    url: '/health/bometerdata/serachList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const boDataValueNew = (params) => {
  return request({
    url: '/health/bometerdata/avgByBoNew',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getSerachListbt = (current, size, params) => {
  return request({
    url: '/health/btmeterdata/serachList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const btDataValueNew = (params) => {
  return request({
    url: '/health/btmeterdata/avgByBtNew',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getSerachListmo = (current, size, params) => {
  return request({
    url: '/health/mometerdata/serachList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const moDataValueNew = (params) => {
  return request({
    url: '/health/mometerdata/avgByMoNew',
    method: 'get',
    params: {
      ...params
    }
  })
}
