<template>
  <basic-container>
    <div style="padding: 20px 0;">
      <i style="margin-right: 4px;" class="el-icon-time"></i>报告隶属时间段：
      <el-date-picker
          v-model="dateValue"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :readonly="true">
      </el-date-picker>
    </div>
    <div>
      <avue-crud
          :option="option"
          :data="data"
          :page.sync="page"
          ref="crud"
          v-model="form"
          :permission="permissionList"
          @search-change="searchChange"
          @search-reset="searchReset"
          @refresh-change="refreshChange"
          @selection-change="selectionChange"
          @on-load="onLoad">
      </avue-crud>
      <el-form ref="form" :model="reportForm">
        <el-form-item>
          <div class="free_tit_box">
            <div class="free_tit">
              <div class="free_tit_text">精准评估方案描述</div>
              <div class="free_tit_bg"></div>
            </div>
          </div>
          <div class="formContent">
            <div class="formInput" v-for="(item,index) in jzpgfa" :key="index">
              <div class="formInputInd">{{ index + 1 }}</div>
              <el-input class="formInputBox" v-model="jzpgfa[index]">{{ item }}</el-input>
              <div class="formInputBtn">
                <el-button-group>
                  <el-button v-if="jzpgfa.length != index+1" @click="delItem('jzpgfa',index)" type="primary"
                             icon="el-icon-delete"></el-button>
                  <el-button v-if="jzpgfa.length== index+1" @click="addItem('jzpgfa',jzpgfa[index],0)" type="danger"
                             icon="el-icon-plus"></el-button>
                </el-button-group>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <div class="free_tit_box">
            <div class="free_tit">
              <div class="free_tit_text">精准治疗方案非药物治疗描述</div>
              <div class="free_tit_bg"></div>
            </div>
          </div>
          <div class="formContent">
            <div class="formInput" v-for="(item,index) in fywzl" :key="index">
              <div class="formInputInd">{{ index + 1 }}</div>
              <el-input class="formInputBox" v-model="fywzl[index]">{{ item }}</el-input>
              <div class="formInputBtn">
                <el-button-group>
                  <el-button v-if="fywzl.length!= index+1" @click="delItem('fywzl',index)" type="primary"
                             icon="el-icon-delete"></el-button>
                  <el-button v-if="fywzl.length== index+1" @click="addItem('fywzl',fywzl[index])" type="danger"
                             icon="el-icon-plus"></el-button>
                </el-button-group>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <div class="free_tit_box">
            <div class="free_tit">
              <div class="free_tit_text">精准治疗方案药物治疗描述</div>
              <div class="free_tit_bg"></div>
            </div>
          </div>
          <div class="formContent">
            <div class="formInput" v-for="(item,index) in ywzl" :key="index">
              <div class="formInputInd">{{ index + 1 }}</div>
              <el-input class="formInputBox" v-model="ywzl[index]">{{ item }}</el-input>
              <div class="formInputBtn">
                <el-button-group>
                  <el-button v-if="ywzl.length!= index+1" @click="delItem('ywzl',index)" type="primary"
                             icon="el-icon-delete"></el-button>
                  <el-button v-if="ywzl.length== index+1" @click="addItem('ywzl',ywzl[index])" type="danger"
                             icon="el-icon-plus"></el-button>
                </el-button-group>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <div class="free_tit_box">
            <div class="free_tit">
              <div class="free_tit_text">精准管理方案描述</div>
              <div class="free_tit_bg"></div>
            </div>
          </div>
          <div class="formContent">
            <div class="formInput" v-for="(item,index) in jzglfa" :key="index">
              <div class="formInputInd">{{ index + 1 }}</div>
              <el-input class="formInputBox" v-model="jzglfa[index]">{{ item }}</el-input>
              <div class="formInputBtn">
                <el-button-group>
                  <el-button v-if="jzglfa.length!= index+1" @click="delItem('jzglfa',index)" type="primary"
                             icon="el-icon-delete"></el-button>
                  <el-button v-if="jzglfa.length== index+1" @click="addItem('jzglfa',jzpgfa[index])" type="danger"
                             icon="el-icon-plus"></el-button>
                </el-button-group>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item align="center">
          <el-button type="primary" @click="onSubmit">提交解读</el-button>
          <el-button @click="zcBtn">暂存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </basic-container>
</template>

<script>
import { getList, submitForm, moZcReport } from '@/api/health/mometerdata'
import { mapGetters } from 'vuex'
import { getWeek } from '@/api/sys/code'
// import { bpAnalysis, bpDataReport, cnt } from '@/api/health/bpmeterdata'

export default {
  data () {
    return {
      jzpgfa: this.reportForm ? this.reportForm.jzpgfa.split(';') : [''],
      jzglfa: this.reportForm ? this.reportForm.jzglfa.split(';') : [''],
      fywzl: this.reportForm ? this.reportForm.fywzl.split(';') : [''],
      ywzl: this.reportForm ? this.reportForm.ywzl.split(';') : [''],
      orgOptions: {},
      form: {},
      reportForm: {},
      dateValue: [],
      tabType: 1,
      picList: null,
      linList: null,
      params: {
        wxUserId: '',
        week: '',
        jkStatus: ''
        // type: '1'
      },
      weekDate: '',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      free: '',
      freeOptions: {
        // 普通图片上传
        customConfig: {}, // wangEditor编辑的配置
        oss: 'ali',
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-shanghai',
          endpoint: 'oss-cn-shanghai.aliyuncs.com',
          accessKeyId: 'LTAI4GGYDGZjaZQyshkjPBJH',
          accessKeySecret: '5O9VCIRDa6AqHtVj2NDiMUO9Uv2GDe',
          bucket: 'health-planet'
        }
      },
      pay: '',
      payOptions: {
        // 普通图片上传
        action: 'https://avuejs.com/imgupload',
        customConfig: {}, // wangEditor编辑的配置
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-beijing',
          endpoint: 'oss-cn-beijing.aliyuncs.com',
          accessKeyId: '',
          accessKeySecret: '',
          bucket: 'avue'
        }
      },
      selectionList: [],
      option: {
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '测量时间',
            prop: 'moDate'
          },
          {
            label: '跑步步数',
            prop: 'moSn'
          },
          {
            label: '消耗卡路里',
            prop: 'moCa'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.health_bsmeterdata_add,
        viewBtn: this.permission.health_bsmeterdata_view,
        delBtn: this.permission.health_bsmeterdata_delete,
        editBtn: this.permission.health_bsmeterdata_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    },
    reportOption () {
      return {
        size: this.sizeValue,
        submitText: '评估',
        emptyBtn: false,
        column: [
          {
            label: '精准评估方案描述',
            prop: 'jzpgfa',
            formslot: true,
            minRows: 10,
            span: 24
          },
          {
            label: '精准治疗方案非药物治疗描述',
            prop: 'fywzl',
            formslot: true,
            minRows: 10,
            span: 24
          },
          {
            label: '精准治疗方案药物治疗描述',
            prop: 'ywzl',
            formslot: true,
            minRows: 10,
            span: 24
          },
          {
            label: '精准管理方案描述',
            prop: 'jzglfa',
            formslot: true,
            minRows: 10,
            span: 24
          }
        ]
      }
    }
  },
  created () {
    this.params.wxUserId = this.$route.query.wxUserId || '' // eslint-disable-line no-unused-vars
    this.params.week = this.$route.query.meterDate || '' // eslint-disable-line no-unused-vars
    this.params.jkStatus = this.$route.query.jkStatus || ''
    getWeek(this.$route.query.meterDate).then(res => {
      this.dateValue = [res.data.monday, res.data.sunday]
    })

    this.reportForm.wxUserId = this.$route.query.wxUserId
    this.reportForm.closingData = this.$route.query.meterDate
  },
  mounted () {

  },
  methods: {
    // 删除元素
    delItem (type, index) {
      this[type].splice(index, 1)
    },

    // 添加元素
    addItem (type, item, index) {
      this[type].push('')
      console.log(this[type], '++++++++')
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    onLoad (page) {
      getList(page.currentPage, page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        console.log(data)
        if (data.records[0].jzpgfaDetail.length !== 0) {
          this.jzpgfa = data.records[0].jzpgfaDetail
        }
        if (data.records[0].jzglfaDetail.length !== 0) {
          this.jzglfa = data.records[0].jzglfaDetail
        }
        if (data.records[0].fywzlDetail.length !== 0) {
          this.fywzl = data.records[0].fywzlDetail
        }
        if (data.records[0].ywzlDetail.length !== 0) {
          this.ywzl = data.records[0].ywzlDetail
        }
      })
    },
    onSubmit (data, done) {
      // if (this.params.jkStatus === '0' || this.params.jkStatus === '') {
      //   this.$message.error('只有已付费待解读状态或者已付费已解读状态才能评估')
      //   return
      // }
      this.reportForm.jzpgfa = this.jzpgfa.join(';')
      this.reportForm.jzglfa = this.jzglfa.join(';')
      this.reportForm.fywzl = this.fywzl.join(';')
      this.reportForm.ywzl = this.ywzl.join(';')
      submitForm(this.reportForm).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
      })
    },

    zcBtn (data, done) {
      // if (this.params.jkStatus !== '1') {
      //   this.$messgea.error('只有已付费待解读状态才能暂存')
      //   return
      // }
      this.reportForm.status = 3
      this.reportForm.jzpgfa = this.jzpgfa.join(';')
      this.reportForm.jzglfa = this.jzglfa.join(';')
      this.reportForm.fywzl = this.fywzl.join(';')
      this.reportForm.ywzl = this.ywzl.join(';')
      moZcReport(this.reportForm).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
      })
    }
  }
}

</script>

<style>
.memberTit{
  color: #5a88d3;
  font-size: 16px;
  margin-bottom: 20px;
}
.memberTit::before{
  content: '';
  border-left: 6px solid #5a88d3;
  margin-right: 20px;
  background: #5a88d3;
}
.free_tit_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

.free_tit {
  display: inline-block;
  height: 50px;
  padding: 10px 0px;
}

.free_tit_text {
  padding: 0px 20px;
  z-index: 1;
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #0F75EB;
}

.free_tit_bg {
  height: 27px;
  margin-top: -20px;
  margin-left: 39px;
  z-index: 0;
  background: rgba(61, 184, 255, 0.3);
  border-radius: 10px;
}

.formContent {
  padding: 20px;
}

.formInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.formInputBox {
  flex: 1;
}

.formInputInd {
  font-size: 18px;
  color: #fff;
  background: #0F75EB;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 30px;
  height: 30px;
  box-shadow: 0 3px 0 0 rgba(61, 184, 255, 0.3);;
}

.formInputBtn {
  width: 120px;
  margin-left: 20px;
}
</style>
