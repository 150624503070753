<template>
  <basic-container>
    <el-form ref="form">
      <el-form-item>
        <div class="free_tit_box">
          <div class="free_tit">
            <div class="free_tit_text">健康分析档案</div>
            <div class="free_tit_bg"></div>
          </div>
        </div>
      </el-form-item>
    </el-form>
<!--    <el-upload-->
<!--        class="upload"-->
<!--        action=""-->
<!--        list-type="picture-card"-->
<!--        :show-file-list="false"-->
<!--        :http-request="fnUploadRequest"-->
<!--        :on-success="handleAvatarSuccess"-->
<!--        :before-upload="beforeAvatarUpload">-->
<!--            <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="">-->
<!--            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--    </el-upload>-->

    <el-upload
        action="#"
        ref="upload"
        list-type="picture-card"
        :http-request="fnUploadRequest"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload">
      <el-button size="small" type="primary">点击上传报告</el-button>
<!--      <i slot="default" class="el-icon-plus"></i>-->
      <div slot="file" slot-scope="{file}">
        <img
            class="el-upload-list__item-thumbnail"
            :src="file.url" alt=""
        >
        <span class="el-upload-list__item-actions">
        <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleDownload(file)"-->
<!--        >-->
<!--          <i class="el-icon-download"></i>-->
<!--        </span>-->
        <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

    <el-container style="height: 100vh; border: 1px solid #eee">
      <el-container style="background: #fff;padding: 20px; overflow-y: auto">
        <avue-form ref="form" v-model="recordForm" :option="recordOption" @submit="submit">
          <template slot="checkUrl">
            <div class="demo-image__preview">
              <el-image
                  v-for="url in recordForm.checkUrl"
                  style="padding: 5px; width: 100px; height: 100px"
                  :key="url" :src="url" id="img1" :preview-src-list="recordForm.checkUrl">
              </el-image>
            </div>
          </template>
        </avue-form>
      </el-container>
    </el-container>
  </basic-container>
</template>

<script>
import { getDetail, getSubmit } from '@/api/health/healthrecords'
import { mapGetters } from 'vuex'

const OSS = require('ali-oss')
const client = new OSS({
  region: 'oss-cn-shanghai',
  endpoint: 'oss-cn-shanghai.aliyuncs.com',
  accessKeyId: 'LTAI4GGYDGZjaZQyshkjPBJH',
  accessKeySecret: '5O9VCIRDa6AqHtVj2NDiMUO9Uv2GDe',
  bucket: 'health-planet'
})
export default {
  data () {
    return {
      form: {},
      recordForm: {},
      selectionList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: []
      },
      data: [],
      imageUrl: '',
      imageUrlList: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    recordOption () {
      return {
        size: this.sizeValue,
        submitText: '保存',
        emptyBtn: false,
        column: [
          {
            label: '检查报告',
            prop: 'checkUrl',
            hide: true,
            editDisabled: true,
            dataType: 'array',
            type: 'upload',
            formslot: true,
            propsHttp: {
              res: 'data.0'
            },
            span: 24,
            listType: 'picture-card',
            detail: true
          }
        ],
        group: [
          {
            icon: 'el-icon-info',
            label: '档案分析',
            collapse: true,
            prop: 'recordList',
            column: [
              {
                label: '档案分析',
                prop: 'recordList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    done()
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    {
                      width: 200,
                      label: '日期',
                      prop: 'date'
                      // type: 'date'
                    },
                    {
                      width: 400,
                      label: '生化',
                      prop: 'biochemical',
                      type: 'textarea'
                    },
                    {
                      width: 400,
                      label: '心电',
                      prop: 'ecg',
                      type: 'textarea'
                    },
                    {
                      width: 400,
                      label: '超声',
                      prop: 'ultrasonic',
                      type: 'textarea'
                    },
                    {
                      width: 400,
                      label: '影像',
                      prop: 'image',
                      type: 'textarea'
                    },
                    {
                      width: 400,
                      label: '病理',
                      prop: 'pathology',
                      type: 'textarea'
                    },
                    {
                      width: 400,
                      label: '其他',
                      prop: 'other',
                      type: 'textarea'
                    },
                    {
                      width: 400,
                      label: '诊断',
                      prop: 'diagnosis',
                      type: 'textarea'
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    }
  },
  created () {
    this.recordForm.name = this.$route.query.jkName
    this.recordForm.age = this.$route.query.jkAge
    this.recordForm.sex = this.$route.query.jkSex
    this.recordForm.wxUserId = this.$route.query.wxUserId
    this.onLoad()
  },
  methods: {
    handleRemove (file) {
      console.log(file)
      // 在el-upload的元素加上,ref="upload"
      const fileList = this.$refs.upload.uploadFiles
      const index = fileList.findIndex(fileItem => { return fileItem.uid === file.uid })
      fileList.splice(index, 1)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    onLoad () {
      getDetail(this.recordForm.wxUserId).then(res => {
        this.recordForm = res.data
      })
    },
    submit (form, done) {
      this.recordForm.imageUrlList = this.imageUrlList
      getSubmit(this.recordForm).then(() => {
        done()
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        done()
      })
    },
    // 图片上传成功回调
    handleAvatarSuccess (res) {
      if (res) this.imageUrl = res.url
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      // const isLt2M = file.size / 1024 / 1024 < 2

      if (!(isJPG || isPNG)) {
        this.$message.error('上传头像图片只能是 JPG或者PNG 格式!')
      }
      if (isJPG) {
        return isJPG
      } else {
        return isPNG
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!')
      // }

      // return isJPG && isLt2M
    },

    async fnUploadRequest (options) {
      try {
        const file = options.file // 拿到 file
        const fileName = file.name.substr(0, file.name.lastIndexOf('.'))
        const date = new Date().getTime()
        const fileNames = `${date}_${fileName}` // 拼接文件名，保证唯一，这里使用时间戳+原文件名
        // 上传文件,这里是上传到OSS的文件夹下
        client.put(fileNames, file).then(res => {
          if (res.res.statusCode === 200) {
            // 把上传成功的值保存到imageUrl中
            this.imageUrlList.push(res.url)
            options.onSuccess(res)
          } else {
            options.onError('上传失败')
          }
        })
      } catch (e) {
        options.onError('上传失败')
      }
    }

  }
}
</script>

<style>
.free_tit_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

.free_tit {
  display: inline-block;
  height: 50px;
  padding: 10px 0px;
}

.free_tit_text {
  padding: 0px 20px;
  z-index: 1;
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #0F75EB;
}

.free_tit_bg {
  height: 27px;
  margin-top: -20px;
  margin-left: 39px;
  z-index: 0;
  background: rgba(61, 184, 255, 0.3);
  border-radius: 10px;
}

.formContent {
  padding: 20px;
}

.formInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.formInputBox {
  flex: 1;
}

.formInputInd {
  font-size: 18px;
  color: #fff;
  background: #0F75EB;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 30px;
  height: 30px;
  box-shadow: 0 3px 0 0 rgba(61, 184, 255, 0.3);;
}

.formInputBtn {
  width: 120px;
  margin-left: 20px;
}
</style>
