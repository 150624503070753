<template>
  <basic-container>
    <el-container style="border: 1px solid #eee">
      <el-container style="background: #fff;padding: 20px; overflow-y: auto">
        <el-collapse v-model="activeName" accordion style="width: 100%">
          <el-collapse-item :name="index" v-for="(item,index) in formData" :key="index">
            <template slot="title">
              {{ '随访计划' + (index + 1) }} <i style="color: #e4393c;margin-left: 20px;" v-if="formData.length>1"
                @click.prevent="delItem(index)" class="header-icon el-icon-delete"></i>
            </template>
            <el-form :model="item" label-width="100px" class="demo-dynamic">
              <el-form-item label="选择日期时间">
                <el-row :gutter="20">
                  <el-col :span="16">
                    <el-date-picker v-model="item.recordTimeS" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item v-for="(domain, childIndex) in item.followUpPlanList" :label="(childIndex+1)"
                :key="childIndex">
                <el-row :gutter="20">
                  <el-col :span="20">
                    <el-input style="width: 86%" placeholder="请填写详细内容" v-model="domain.detail">
                    </el-input>
                  </el-col>
                  <el-col :span="3">
                    <el-button-group style="margin-bottom:20px">
                      <el-button type="danger" @click.prevent="removeDomain(index,domain)">删除</el-button>
                      <el-button type="warning" @click="addDomain(index)">新增</el-button>
                    </el-button-group>
                  </el-col>
                </el-row>
              </el-form-item>

            </el-form>
          </el-collapse-item>
          <div style="padding:20px 0;width: 100%">
            <span class="demonstration">提醒日期</span>
            <br><br>
            <el-date-picker v-model="dateRemindS" type="date" placeholder="选择提醒日期">
            </el-date-picker>
          </div>
        </el-collapse>
      </el-container>
    </el-container>
    <el-button-group style="margin: 30px auto;display: flex;align-items: center;justify-content: center;">
      <el-button type="primary" @click="addItem">新增</el-button>
      <el-button type="primary" @click="submitForm">提交</el-button>
      <el-button size="small" plain icon="el-icon-back" @click="handleBack()">返回上一级
      </el-button>
    </el-button-group>
  </basic-container>
</template>

<script>
  import {getSubmit, getDetail} from '@/api/health/followupplan'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        form: {},
        planForm: {},
        data: [],
        params: {
          wxUserId: ''
        },
        activeName: '',
        dateRemindS: '',
        formData: [
          {
            label: '随访计划',
            followUpPlanList: [{
              detail: ''
            }]
          }
        ]
        // dynamicValidateForm:
      }
    },
    computed: {
      ...mapGetters(['permission']),
      planOption() {
        return {
          size: this.sizeValue,
          submitText: '保存',
          emptyBtn: false
        }
      }
    },
    created() {
      this.planForm.name = this.$route.query.jkName
      this.planForm.age = this.$route.query.jkAge
      this.planForm.sex = this.$route.query.jkSex
      this.planForm.wxUserId = this.$route.query.wxUserId
      this.params.wxUserId = this.$route.query.wxUserId
      console.log(this.planForm)
      this.onLoad()
    },
    methods: {
      // 删除元素
      delItem(index) {
        this.formData.splice(index, 1)
      },

      // 添加元素
      addItem(type) {
        this.formData.push({
          label: '随访计划',
          followUpPlanList: [{
            detail: ''
          }]
        })
      },
      handleBack() {
        this.$router.back()
      },
      submitForm(formName) {
        this.planForm.dateRemindS = this.dateRemindS
        this.planForm.sfjh = this.formData
        console.log(this.planForm)
        getSubmit(this.planForm).then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        }).catch(() => {
        })
      },

      removeDomain(index, item) {
        const childIndex = this.formData[index].followUpPlanList.indexOf(item)
        if (childIndex !== -1) {
          this.formData[index].followUpPlanList.splice(childIndex, 1)
        }
      },
      addDomain(index) {
        this.formData[index].followUpPlanList.push({
          detail: ''
        })
      },

      searchReset() {
        this.onLoad(this.page)
      },
      searchChange(params, done) {
        this.onLoad(this.page, params)
        done()
      },
      selectionChange(list) {
        this.selectionList = list
      },
      refreshChange(data) {
        this.onLoad(this.page, data.searchForm)
      },
      onLoad() {
        getDetail(this.params.wxUserId).then(res => {
          if (res.data.sfjh.length) {
            this.formData = []
            this.formData = res.data.sfjh
            this.dateRemindS = res.data.dateRemindS
          }
        })
      }
    }
  }

</script>