import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/health/historytaking/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

// export const getDetail = (id) => {
//   return request({
//     url: '/health/historytaking/detail',
//     method: 'get',
//     params: {
//       id
//     }
//   })
// }

export const remove = (ids) => {
  return request({
    url: '/health/historytaking/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const getSubmit = (row) => {
  return request({
    url: '/health/historytaking/submit',
    method: 'post',
    data: row
  })
}

export const getDetail = (wxUserId) => {
  return request({
    url: '/health/historytaking/detail',
    method: 'get',
    params: {
      wxUserId
    }
  })
}

