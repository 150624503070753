import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/qa/qaanswer/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/qa/qaanswer/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const getDiabetesDetail = (id) => {
  return request({
    url: '/qa/qaanswer/diabetesDetail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/qa/qaanswer/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/qa/qaanswer/submit',
    method: 'post',
    data: row
  })
}

export const getCommonuserjkDetail = (wxUserId) => {
  return request({
    url: '/qa/qaanswer/commonuserjkDetail',
    method: 'get',
    params: {
      wxUserId
    }
  })
}

export const submitCommonuserjk = (row) => {
  return request({
    url: '/qa/qaanswer/submitCommonuserjk',
    method: 'post',
    data: row
  })
}

