<template>
  <basic-container>
    <div style="padding: 20px 0;">
      <i style="margin-right: 4px;" class="el-icon-time"></i>报告隶属时间段：
      <el-date-picker v-model="dateValue" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
        :readonly="true">
      </el-date-picker>
      <!--      <el-radio-group @change="chooseTab" style="float: right" v-model="tabType">-->
      <!--        <el-radio-button label="1">数据展示解读</el-radio-button>-->
      <!--      </el-radio-group>-->
    </div>
    <div>
      <div style="font-size: 20px; margin-top: 10px;"> 手表方式-收缩压、舒张压表 </div>
      <avue-crud :option="option" :data="data" :page.sync="page" ref="crud" @row-del="rowDel" v-model="form"
        :permission="permissionList" @row-update="rowUpdate" @row-save="rowSave" :before-open="beforeOpen"
        @search-change="searchChange" @search-reset="searchReset" @refresh-change="refreshChange"
        @selection-change="selectionChange" @on-load="onLoad">
      </avue-crud>
      <div style="font-size: 20px; margin-top: 10px;"> 血压计方式-收缩压、舒张压表 </div>
      <avue-crud :option="option01" :data="data01" :page.sync="page01" @search-reset="searchReset01" @on-load="onLoad">
      </avue-crud>
      <div style="font-size: 20px; margin-top: 10px;"> 心率表 </div>
      <avue-crud :option="option1" :data="dataList" :page.sync="page1" @on-load="onLoad" @search-reset="searchReset1">
      </avue-crud>
      <!-- <div style="font-size: 20px; margin-top: 10px; margin-bottom: 10px;"> 血压状况可视图 </div> -->
      <div id="myChart" :style="{ width: '100%', height: '600px' }"></div>
      <div id="lineChart" :style="{ width: '100%', height: '600px' }"></div>
      <el-table :data="bpDataReport" stripe style="width: 100%">
        <el-table-column prop="name" label="家庭血压监测数据评估报告" width="300">
        </el-table-column>
        <el-table-column prop="standard" label="" width="180">
        </el-table-column>
      </el-table>
      <br />
      <el-table :data="bpDataClassification" stripe style="width: 100%">
        <el-table-column prop="name" label="家庭血压监测数据分类" width="300">
        </el-table-column>
        <el-table-column prop="time" label="" width="180">
        </el-table-column>
      </el-table>
      <br />
      <el-table :data="bpDataAnalysis" stripe style="width: 100%">
        <el-table-column prop="name" label="家庭血压监测数据水平分析" width="300">
        </el-table-column>
        <el-table-column prop="index" label="" width="180">
        </el-table-column>
        <el-table-column prop="name1" label="" width="180">
        </el-table-column>
        <el-table-column prop="index1" label="" width="180">
        </el-table-column>
      </el-table>
      <br />

      <!-- <avue-form ref="form" v-model="reportForm" :option="reportOption" @submit="onSubmit">
             <template slot="jzpgfa">
               <tags-input @input="changeInputVal" v-model="reportForm.jzpgfa" size="small"></tags-input>
             </template>
             <template slot="jzglfa">
               <tags-input v-model="reportForm.jzglfa" size="small"></tags-input>
             </template>
             <template slot="fywzl">
               <tags-input v-model="reportForm.fywzl" size="small"></tags-input>
             </template>
             <template slot="ywzl">
               <tags-input v-model="reportForm.ywzl" size="small"></tags-input>
             </template>
           </avue-form> -->

      <!--            <avue-form ref="form" v-model="reportForm" :option="reportOption" @submit="onSubmit">-->
      <!--              <template slot="jzpgfa">-->
      <!--                <tags-input @input="changeInputVal" v-model="reportForm.jzpgfa" size="small"></tags-input>-->
      <!--              </template>-->
      <!--              <template slot="jzglfa">-->
      <!--                <tags-input v-model="reportForm.jzglfa" size="small"></tags-input>-->
      <!--              </template>-->
      <!--              <template slot="fywzl">-->
      <!--                <tags-input v-model="reportForm.fywzl" size="small"></tags-input>-->
      <!--              </template>-->
      <!--              <template slot="ywzl">-->
      <!--                <tags-input v-model="reportForm.ywzl" size="small"></tags-input>-->
      <!--              </template>-->
      <!--            </avue-form>-->

      <!-- <el-form ref="form" :model="reportForm">
        <el-form-item>
          <div class="free_tit_box">
            <div class="free_tit">
              <div class="free_tit_text">精准评估方案描述</div>
              <div class="free_tit_bg"></div>
            </div>
          </div>
          <div class="formContent">
            <div class="formInput" v-for="(item, index) in jzpgfa" :key="index">
              <div class="formInputInd">{{ index + 1 }}</div>
              <el-input class="formInputBox" v-model="jzpgfa[index]">{{ item }}</el-input>
              <div class="formInputBtn">
                <el-button-group>
                  <el-button v-if="jzpgfa.length != index + 1" @click="delItem('jzpgfa', index)" type="primary"
                    icon="el-icon-delete"></el-button>
                  <el-button v-if="jzpgfa.length == index + 1" @click="addItem('jzpgfa', jzpgfa[index], 0)" type="danger"
                    icon="el-icon-plus"></el-button>
                </el-button-group>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <div class="free_tit_box">
            <div class="free_tit">
              <div class="free_tit_text">精准治疗方案非药物治疗描述</div>
              <div class="free_tit_bg"></div>
            </div>
          </div>
          <div class="formContent">
            <div class="formInput" v-for="(item, index) in fywzl" :key="index">
              <div class="formInputInd">{{ index + 1 }}</div>
              <el-input class="formInputBox" v-model="fywzl[index]">{{ item }}</el-input>
              <div class="formInputBtn">
                <el-button-group>
                  <el-button v-if="fywzl.length != index + 1" @click="delItem('fywzl', index)" type="primary"
                    icon="el-icon-delete"></el-button>
                  <el-button v-if="fywzl.length == index + 1" @click="addItem('fywzl', fywzl[index])" type="danger"
                    icon="el-icon-plus"></el-button>
                </el-button-group>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <div class="free_tit_box">
            <div class="free_tit">
              <div class="free_tit_text">精准治疗方案药物治疗描述</div>
              <div class="free_tit_bg"></div>
            </div>
          </div>
          <div class="formContent">
            <div class="formInput" v-for="(item, index) in ywzl" :key="index">
              <div class="formInputInd">{{ index + 1 }}</div>
              <el-input class="formInputBox" v-model="ywzl[index]">{{ item }}</el-input>
              <div class="formInputBtn">
                <el-button-group>
                  <el-button v-if="ywzl.length != index + 1" @click="delItem('ywzl', index)" type="primary"
                    icon="el-icon-delete"></el-button>
                  <el-button v-if="ywzl.length == index + 1" @click="addItem('ywzl', ywzl[index])" type="danger"
                    icon="el-icon-plus"></el-button>
                </el-button-group>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <div class="free_tit_box">
            <div class="free_tit">
              <div class="free_tit_text">精准管理方案描述</div>
              <div class="free_tit_bg"></div>
            </div>
          </div>
          <div class="formContent">
            <div class="formInput" v-for="(item, index) in jzglfa" :key="index">
              <div class="formInputInd">{{ index + 1 }}</div>
              <el-input class="formInputBox" v-model="jzglfa[index]">{{ item }}</el-input>
              <div class="formInputBtn">
                <el-button-group>
                  <el-button v-if="jzglfa.length != index + 1" @click="delItem('jzglfa', index)" type="primary"
                    icon="el-icon-delete"></el-button>
                  <el-button v-if="jzglfa.length == index + 1" @click="addItem('jzglfa', jzpgfa[index])" type="danger"
                    icon="el-icon-plus"></el-button>
                </el-button-group>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item align="center">
          <el-button type="primary" @click="onSubmit">提交解读</el-button>
          <el-button @click="zcBtn">暂存</el-button>
        </el-form-item>
      </el-form> -->
    </div>
  </basic-container>
</template>

<script>

import {
  // getList,
  getList0,
  getList01,
  getList1,
  getDetail,
  submit,
  remove,
  getClassifyFree,
  getAvgByDayFree,
  submitForm,
  cnt,
  bpAnalysis,
  bpDataReport,
  bpZcReport
} from '@/api/health/bpmeterdata'
import { getWeek } from '@/api/sys/code'
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      jzpgfa: this.reportForm ? this.reportForm.jzpgfa.split(';') : [''],
      jzglfa: this.reportForm ? this.reportForm.jzglfa.split(';') : [''],
      fywzl: this.reportForm ? this.reportForm.fywzl.split(';') : [''],
      ywzl: this.reportForm ? this.reportForm.ywzl.split(';') : [''],
      orgOptions: {},
      form: {},
      reportForm: {},
      dateValue: [],
      tabType: 2,
      picList: null,
      linList: null,
      params: {
        wxUserId: '',
        week: '',
        type: '1',
        jkStatus: ''
      },
      weekDate: '',
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      page01: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      page1: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      free: '',
      freeOptions: {
        // 普通图片上传
        customConfig: {}, // wangEditor编辑的配置
        oss: 'ali',
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-shanghai',
          endpoint: 'oss-cn-shanghai.aliyuncs.com',
          accessKeyId: 'LTAI4GGYDGZjaZQyshkjPBJH',
          accessKeySecret: '5O9VCIRDa6AqHtVj2NDiMUO9Uv2GDe',
          bucket: 'health-planet'
        }
      },
      pay: '',
      payOptions: {
        // 普通图片上传
        action: 'https://avuejs.com/imgupload',
        customConfig: {}, // wangEditor编辑的配置
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-beijing',
          endpoint: 'oss-cn-beijing.aliyuncs.com',
          accessKeyId: '',
          accessKeySecret: '',
          bucket: 'avue'
        }
      },
      selectionList: [],
      option: {
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '测量时间',
            prop: 'meterDate'
          },
          {
            label: '收缩压',
            prop: 'systolic'
          },
          {
            label: '舒张压',
            prop: 'diastolic'
          },
          // {
          //   label: '心率',
          //   prop: 'heartRate'
          // },
          {
            label: '数据类型',
            prop: 'type',
            type: 'select',
            dicData: [
              // { label: '手动录入', value: 1 },
              // { label: '蓝牙', value: 2 }
              { label: '手动录入', value: 1, color: '#red' },
              { label: '手表录入', value: 2, color: '#red' },
              { label: '一体机', value: 3 },
              { label: '脉搏波血压计', value: 4 },
              { label: '大型蓝牙血压站', value: 5, color: '#blue' },
              { label: '小型蓝牙血压计', value: 6, color: '#blue' },
              { label: '数字人', value: 7 },
              { label: '小的血压计', value: 8 },
              { label: '心电图血压计', value: 9 },
              { label: '蓝牙手表', value: 10 },
              { label: '4G手表', value: 11 },
              { label: '三诺血压计', value: 12 },
              { label: '蓝牙手表', value: 13 }
            ]
          },
          {
            label: '状态',
            prop: 'pressureLevel'
          }
          // ,
          // {
          //   label: '',
          //   prop: 'time',
          //   formslot: true,
          //   span: 24
          // }
        ]
      },

      option01: {
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '测量时间',
            prop: 'meterDate'
          },
          {
            label: '收缩压',
            prop: 'systolic'
          },
          {
            label: '舒张压',
            prop: 'diastolic'
          },
          // {
          //   label: '心率',
          //   prop: 'heartRate'
          // },
          {
            label: '数据类型',
            prop: 'type',
            type: 'select',
            dicData: [
              { label: '手动录入', value: 1 },
              { label: '手表录入', value: 2 },
              { label: '一体机', value: 3 },
              { label: '脉搏波血压计', value: 4 },
              { label: '大型蓝牙血压站', value: 5 },
              { label: '小型蓝牙血压计', value: 6 },
              { label: '数字人', value: 7 },
              { label: '小的血压计', value: 8 },
              { label: '心电图血压计', value: 9 },
              { label: '蓝牙手表', value: 10 },
              { label: '4G手表', value: 11 },
              { label: '三诺血压计', value: 12 },
              { label: '蓝牙手表', value: 13 }
            ]
          },
          {
            label: '状态',
            prop: 'pressureLevel'
          }
        ]
      },

      option1: {
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '测量时间',
            prop: 'meterDate'
          },
          {
            label: '心率',
            prop: 'heartRate'
          },
          {
            label: '数据类型',
            prop: 'type',
            type: 'select',
            dicData: [
              // { label: '手动录入', value: 1 },
              // { label: '蓝牙', value: 2 }
              { label: '手动录入', value: 1 },
              { label: '手表录入', value: 2 },
              { label: '一体机', value: 3 },
              { label: '脉搏波血压计', value: 4 },
              { label: '大型蓝牙血压站', value: 5 },
              { label: '小型蓝牙血压计', value: 6 },
              { label: '数字人', value: 7 },
              { label: '小的血压计', value: 8 },
              { label: '心电图血压计', value: 9 },
              { label: '蓝牙手表', value: 10 },
              { label: '4G手表', value: 11 },
              { label: '三诺血压计', value: 12 },
              { label: '蓝牙手表', value: 13 }
            ]
          },
          {
            label: '状态',
            prop: 'pressureLevel1'
          }
        ]
      },
      data: [],
      data01: [],
      dataList: [],
      bpDataClassification: [{
        name: '测定总有效次数：',
        time: ''
      },
      {
        name: '清晨测定次数 (06:00-10:00)：',
        time: ''
      }, {
        name: '白天测定次数 (10:00-18:00)：',
        time: ''
      }, {
        name: '晚间测定次数 (18:00-22:00)：',
        time: ''
      }, {
        name: '夜间测定次数 (22:00-06:00)：',
        time: ''
      }],
      bpDataAnalysis: [{
        name: '整体（06：00-23：00）血压平均值'
      }, {
        name: '收缩压平均值:',
        index: '',
        name1: '收缩压负荷值:',
        index1: ''
      }, {
        name: '舒张压平均值:',
        index: '',
        name1: '舒张压负荷值:',
        index1: ''
      }, {
        name: '白天（06：00-18：00）血压平均值'
      }, {
        name: '收缩压平均值:',
        index: '',
        name1: '收缩压负荷值:',
        index1: ''
      }, {
        name: '舒张压平均值:',
        index: '',
        name1: '舒张压负荷值:',
        index1: ''
      }, {
        name: '晚间（18：00-23：00）血压平均值'
      }, {
        name: '收缩压平均值:',
        index: '',
        name1: '收缩压负荷值:',
        index1: ''
      }, {
        name: '舒张压平均值:',
        index: '',
        name1: '舒张压负荷值:',
        index1: ''
      }],
      bpDataReport: [
        {
          name: '你的近期血压控制水平为：',
          standard: ''
        }]
    }
  },
  created () {
    this.params.wxUserId = this.$route.query.wxUserId || '' // eslint-disable-line no-unused-vars
    this.params.week = this.$route.query.meterDate || '' // eslint-disable-line no-unused-vars
    this.params.jkStatus = this.$route.query.jkStatus || ''
    getWeek(this.$route.query.meterDate).then(res => {
      this.dateValue = [res.data.monday, res.data.sunday]
    })
    this.reportForm.wxUserId = this.$route.query.wxUserId
    this.reportForm.closingData = this.$route.query.meterDate

    cnt({ date: this.reportForm.closingData, wxUserId: this.reportForm.wxUserId }).then(res => {
      this.bpDataClassification = [{
        name: '测定总有效次数：',
        time: res.data.zs + '次'
      }, {
        name: '清晨测定次数 (06:00-10:00)：',
        time: res.data.qc + '次'
      }, {
        name: '白天测定次数 (10:00-18:00)：',
        time: res.data.bt + '次'
      }, {
        name: '晚间测定次数 (18:00-22:00)：',
        time: res.data.yw + '次'
      }, {
        name: '夜间测定次数 (22:00-06:00)：',
        time: res.data.yj + '次'
      }]
    })
    bpAnalysis({ date: this.reportForm.closingData, wxUserId: this.reportForm.wxUserId }).then(res => {
      this.bpDataAnalysis = [{
        name: '整体（06：00-23：00）血压平均值'
      }, {
        name: '收缩压平均值:',
        index: res.data[0].avgSystolic + 'mmHg',
        name1: '收缩压负荷值:',
        index1: parseFloat(res.data[0].vlrSystolic * 100).toFixed(0) + '%'
      }, {
        name: '舒张压平均值:',
        index: res.data[0].avgDiastolic + 'mmHg',
        name1: '舒张压负荷值:',
        index1: parseFloat(res.data[0].vlrDiastolic * 100).toFixed(0) + '%'
      }, {
        name: '白天（06：00-18：00）血压平均值'
      }, {
        name: '收缩压平均值:',
        index: res.data[1].avgSystolic + 'mmHg',
        name1: '收缩压负荷值:',
        index1: parseFloat(res.data[0].vlrDaySystolic * 100).toFixed(0) + '%'
      }, {
        name: '舒张压平均值:',
        index: res.data[1].avgDiastolic + 'mmHg',
        name1: '舒张压负荷值:',
        index1: parseFloat(res.data[0].vlrDayDiastolic * 100).toFixed(0) + '%'
      }, {
        name: '晚间（18：00-23：00）血压平均值'
      }, {
        name: '收缩压平均值:',
        index: res.data[2].avgSystolic + 'mmHg',
        name1: '收缩压负荷值:',
        index1: parseFloat(res.data[0].vlrNightSystolic * 100).toFixed(0) + '%'
      }, {
        name: '舒张压平均值:',
        index: res.data[2].avgDiastolic + 'mmHg',
        name1: '舒张压负荷值:',
        index1: parseFloat(res.data[0].vlrNightDiastolic * 100).toFixed(0) + '%'
      }]
    })
    bpDataReport({ date: this.reportForm.closingData, wxUserId: this.reportForm.wxUserId }).then(res => {
      this.bpDataReport = [
        {
          name: '你的近期血压控制水平为：',
          standard: res.data.upToStandard
        }]
    })
  },
  mounted () {
    this.classifyfree(this.params.week)
    this.avgByDayFree(this.params.week)
  },

  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.health_bpmeterdata_add,
        viewBtn: this.permission.health_bpmeterdata_view,
        delBtn: this.permission.health_bpmeterdata_delete,
        editBtn: this.permission.health_bpmeterdata_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    },
    reportOption () {
      return {
        size: this.sizeValue,
        submitText: '评估',
        emptyBtn: false,
        column: [
          {
            label: '精准评估方案描述',
            prop: 'jzpgfa',
            formslot: true,
            minRows: 10,
            span: 24
          },
          {
            label: '精准治疗方案非药物治疗描述',
            prop: 'fywzl',
            formslot: true,
            minRows: 10,
            span: 24
          },
          {
            label: '精准治疗方案药物治疗描述',
            prop: 'ywzl',
            formslot: true,
            minRows: 10,
            span: 24
          },
          {
            label: '精准管理方案描述',
            prop: 'jzglfa',
            formslot: true,
            minRows: 10,
            span: 24
          }
        ]
      }
    }
  },
  methods: {
    // 删除元素
    delItem (type, index) {
      this[type].splice(index, 1)
    },

    // 添加元素
    addItem (type, item, index) {
      this[type].push('')
      console.log(this[type], '++++++++')
    },

    changeInputVal (e) {
      console.log(e)
    },
    drawpie () {
      // 初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myChart'))
      const nameData = ['正常', '一级高血压', '二级高血压', '三级高血压']
      myChart.setOption({
        // title: {
        //   text: '血压状况',
        //   left: 'center',
        //   top: '50%',
        //   textStyle: {
        //     fontSize: 28
        //   }
        // },
        // tooltip: {},
        // series: [{
        //   name: '血压状况',
        //   type: 'pie',
        //   radius: ['50%', '70%'],
        //   label: {
        //     formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
        //     backgroundColor: '#eee',
        //     borderColor: '#aaa',
        //     borderWidth: 1,
        //     borderRadius: 4,
        //     rich: {
        //       a: {
        //         color: '#999',
        //         lineHeight: 22,
        //         align: 'center'
        //       },
        //       hr: {
        //         borderColor: '#aaa',
        //         width: '100%',
        //         borderWidth: 0.5,
        //         height: 0
        //       },
        //       b: {
        //         fontSize: 16,
        //         lineHeight: 33
        //       },
        //       per: {
        //         color: '#eee',
        //         backgroundColor: '#334455',
        //         padding: [2, 4],
        //         borderRadius: 2
        //       }
        //     }
        //   },
        //   color: ['#86bdf6', '#fdca35', '#fd8242', '#fc182c'],
        //   data: [
        //     { name: '正常', value: Number(this.picList.vsumNormal) },
        //     { name: '一级高血压', value: Number(this.picList.vsumI) },
        //     { name: '二级高血压', value: Number(this.picList.vsumIi) },
        //     { name: '三级高血压', value: Number(this.picList.vsumIii) }
        //   ]
        // }]
        animation: true,
        title: {
          x: 'center',
          y: 'center',
          textStyle: {
            color: 'red',
            fontSize: 60,
            fontWeight: 'normal',
            align: 'center',
            width: '200px'
          },
          subtextStyle: {
            color: 'red',
            fontSize: 20,
            fontWeight: 'normal',
            align: 'center'
          }
        },
        legend: {
          width: '50%',
          left: 'center',
          textStyle: {
            color: '#9B9B9B',
            fontSize: 16
          },
          icon: 'circle',
          right: '0',
          bottom: '0',
          top: '75%',
          itemGap: 20,
          data: nameData
        },
        series: [
          {
            type: 'pie',
            center: ['50%', '35%'],
            radius: ['30%', '40%'],
            color: ['#3A86D9', '#DD0C75', '#fd8242', '#fc182c'],
            startAngle: 135,
            labelLine: {
              normal: {
                length: 25
              }
            },
            label: {
              normal: {
                // formatter: "{c}\n{d}%",
                formatter: '\n{d}%',
                backgroundColor: 'rgba(255, 147, 38, 0)',
                borderColor: 'transparent',
                borderRadius: 4,
                textStyle: {
                  color: '#4a4a4a',
                  fontSize: 16
                }
              }
            },
            // data: data
            data: [
              { name: '正常', value: Number(this.picList.vsumNormal) },
              { name: '一级高血压', value: Number(this.picList.vsumI) },
              { name: '二级高血压', value: Number(this.picList.vsumIi) },
              { name: '三级高血压', value: Number(this.picList.vsumIii) }
            ]
          }
        ]
      })
    },

    drawLine () {
      // 初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('lineChart'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: '每周血压数据折现变化图',
          subtext: '单位:mmHg'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['收缩压', '舒张压']
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: { readOnly: false },
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.linList.map(v => v.meterDate)
        },
        yAxis: {
          type: 'value',
          min: 40,
          axisLabel: {
            formatter: '{value} mmHg'
          }
        },
        series: [
          {
            name: '收缩压',
            type: 'line',
            data: this.linList.map(v => v.avgSystolic),
            markPoint: {
              data: [
                { type: 'max', name: '最大值' },
                { type: 'min', name: '最小值' }
              ]
            },
            markLine: {
              data: [
                { type: 'average', name: '平均值' }
              ]
            }
          },
          {
            name: '舒张压',
            type: 'line',
            data: this.linList.map(v => v.avgDiastolic),
            markPoint: {
              data: [
                { type: 'max', name: '最大值' },
                { type: 'min', name: '最小值' }
              ]
            },
            markLine: {
              data: [
                { type: 'average', name: '平均值' },
                [{
                  symbol: 'none',
                  x: '90%',
                  yAxis: 'max'
                }, {
                  symbol: 'circle',
                  label: {
                    position: 'start',
                    formatter: '最大值'
                  },
                  type: 'max',
                  name: '最高点'
                }]
              ]
            }
          }
        ]
      })
    },

    // chooseTab (e) {
    //   this.tabType = e
    //   if (e === '1') {
    //     this.drawpie()
    //     this.drawLine()
    //   }
    // },
    classifyfree (date) {
      getClassifyFree({ date: date, wxUserId: this.params.wxUserId }).then(res => {
        // console.log("输出当前的数据信息")
        // console.log(res)
        const data = res.data
        this.picList = data
        this.drawpie()
      })
    },
    avgByDayFree (day) {
      getAvgByDayFree({ date: day, wxUserId: this.params.wxUserId }).then(res => {
        const data = res.data
        this.linList = data
        console.log(this.linList)
        this.drawLine()
      })
    },

    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad()
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchReset01 () {
      this.onLoad(this.page01)
    },
    searchReset1 () {
      this.onLoad(this.page1)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },

    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },

    onLoad () {
      // getList(page.currentPage, page.pageSize, this.params).then(res => {
      //   const data = res.data
      //   // // console.log("输出整个的data")
      //   // console.log(data)
      //   // // console.log("输出整个的parmas")
      //   // console.log(this.params)
      //   // // console.log("输出page.currentPage")
      //   // console.log(this.page.currentPage)
      //   // // console.log("输出page.pageSize")
      //   // console.log(this.page.pageSize)
      //   this.page.total = data.total
      //   this.data = data.records
      //   if (data.records[0].jzpgfaDetail && data.records[0].jzpgfaDetail.length !== 0) {
      //     this.jzpgfa = data.records[0].jzpgfaDetail
      //   }
      //   if (data.records[0].jzglfaDetail && data.records[0].jzglfaDetail.length !== 0) {
      //     this.jzglfa = data.records[0].jzglfaDetail
      //   }
      //   if (data.records[0].fywzlDetail && data.records[0].fywzlDetail.length !== 0) {
      //     this.fywzl = data.records[0].fywzlDetail
      //   }
      //   if (data.records[0].ywzlDetail && data.records[0].ywzlDetail.length !== 0) {
      //     this.ywzl = data.records[0].ywzlDetail
      //   }
      // })
      getList0(this.page.currentPage, this.page.pageSize, this.params).then(res => {
        const data = res.data
        console.log(data)
        console.log(this.params)
        this.page.total = data.total
        this.data = data.records
        if (data.records[0].jzpgfaDetail && data.records[0].jzpgfaDetail.length !== 0) {
          this.jzpgfa = data.records[0].jzpgfaDetail
        }
        if (data.records[0].jzglfaDetail && data.records[0].jzglfaDetail.length !== 0) {
          this.jzglfa = data.records[0].jzglfaDetail
        }
        if (data.records[0].fywzlDetail && data.records[0].fywzlDetail.length !== 0) {
          this.fywzl = data.records[0].fywzlDetail
        }
        if (data.records[0].ywzlDetail && data.records[0].ywzlDetail.length !== 0) {
          this.ywzl = data.records[0].ywzlDetail
        }
      })
      getList01(this.page1.currentPage, this.page1.pageSize, this.params).then(res => {
        const data1 = res.data
        console.log(data1)
        this.page01.total = data1.total
        this.data01 = data1.records
      })
      getList1(this.page1.currentPage, this.page1.pageSize, this.params).then(res => {
        const data1 = res.data
        console.log(data1)
        this.page1.total = data1.total
        this.dataList = data1.records
      })
    },
    onSubmit (data, done) {
      if (this.params.jkStatus === '0' || this.params.jkStatus === '') {
        this.$message.error('只有已付费待解读状态或者已付费已解读状态才能评估')
        return
      }
      this.reportForm.jzpgfa = this.jzpgfa.join(';')
      this.reportForm.jzglfa = this.jzglfa.join(';')
      this.reportForm.fywzl = this.fywzl.join(';')
      this.reportForm.ywzl = this.ywzl.join(';')
      submitForm(this.reportForm).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
      })
    },
    zcBtn (data, done) {
      if (this.params.jkStatus === '2' || this.params.jkStatus === '0' || this.params.jkStatus === '') {
        this.$message.error('只有已付费待解读状态才能暂存')
        return
      }
      this.reportForm.status = 3
      this.reportForm.jzpgfa = this.jzpgfa.join(';')
      this.reportForm.jzglfa = this.jzglfa.join(';')
      this.reportForm.fywzl = this.fywzl.join(';')
      this.reportForm.ywzl = this.ywzl.join(';')
      bpZcReport(this.reportForm).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
      })
    }
  }
}
</script>

<style>
.memberTit {
  color: #5a88d3;
  font-size: 16px;
  margin-bottom: 20px;
}

.memberTit::before {
  content: '';
  border-left: 6px solid #5a88d3;
  margin-right: 20px;
  background: #5a88d3;
}

.free_tit_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

.free_tit {
  display: inline-block;
  height: 50px;
  padding: 10px 0px;
}

.free_tit_text {
  padding: 0px 20px;
  z-index: 1;
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #0F75EB;
}

.free_tit_bg {
  height: 27px;
  margin-top: -20px;
  margin-left: 39px;
  z-index: 0;
  background: rgba(61, 184, 255, 0.3);
  border-radius: 10px;
}

.formContent {
  padding: 20px;
}

.formInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.formInputBox {
  flex: 1;
}

.formInputInd {
  font-size: 18px;
  color: #fff;
  background: #0F75EB;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 30px;
  height: 30px;
  box-shadow: 0 3px 0 0 rgba(61, 184, 255, 0.3);
  ;
}

.formInputBtn {
  width: 120px;
  margin-left: 20px;
}
</style>
