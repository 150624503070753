<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<template>
  <el-form
      class="login-form"
      status-icon
      ref="loginForm"
      :model="loginForm"
      label-width="0">
      <el-select v-model="form.columeType" placeholder="字段类型" style="width: 100%; line-height: 50px; font-size: 12px;">
           <el-option v-for="(item,index) in columeTypeArr" :key="index" :label="item.label" :value="item.value">
           </el-option>
      </el-select>
    <el-form-item prop="password">
      <el-button
          type="primary"
          size="small"
          @click.native.prevent="handleLogin1"
          class="login-submit">进入渠道大屏
      </el-button>
    </el-form-item>
    <el-form-item>
      <el-button
          type="primary"
          size="small"
          @click.native.prevent="handleLogin2"
          class="login-submit">进入渠道后台
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>

import {
  getList
} from '@/api/health/test'

export default {
  name: 'UserLogin',
  data () {
    return {
      form: {
        columeType: window.localStorage.getItem('channel'),
      },
      columeTypeArr:[{
        value: window.localStorage.getItem('channel'),
        label: window.localStorage.getItem('channel')
      },{
        value:'尽请期待',
        label:'尽请期待',
      }],
      loginForm: {
        username: '',
        password: '',
        requestHeadUrl: 'https://dbadmin.dbzdjk.com',
        type: false,
        appLink: ''
      },
      passwordType: 'password'
    }
  },
  created () {
    console.log('test channel')
    console.log(window.localStorage.getItem('channel'))
  },
  methods: {
    showPassword () {
      this.passwordType === ''
        ? (this.passwordType = 'password')
        : (this.passwordType = '')
    },
    handleLogin1 () {
      this.$refs.loginForm.validate(valid => {
        // if (this.form.columeType === '尽请期待') {
        //   this.$message.success("提示一下")
        // } else {
        //   this.$router.push({ path: '/jumpPage' })
        // }
        if (this.form.columeType === '尽请期待') {
          this.$message.success("提示一下")
        } else {
          this.$router.push({ path: '/areaPage' })
        }
      })
    },
    handleLogin2 () {
      this.$refs.loginForm.validate(valid => {
        // console.log(' test 跳转 ')
        // console.log(this.$myUser.loginuser)
        // console.log(this.$myUser.loginuser == 'admin')
        // console.log(this.$myUser.loginuser != 'admin')
        // console.log(' test 缓存姓名 ')
        // console.log(window.localStorage.getItem('username'))
        // console.log(window.localStorage.getItem('username') == 'admin')
        // console.log(window.localStorage.getItem('username') != 'admin')
        if (this.form.columeType === '尽请期待') {
          this.$message.success("提示一下")
        } else {
          // if (this.$myUser.loginuser == 'admin') {
          //   this.$router.push({ path: '/index' })
          // } else {
          //   this.$message.success("用户权限不足")
          // }
          // this.$router.push({ path: '/index' })

          // if (window.localStorage.getItem('username') == 'admin') {
          // if (window.localStorage.getItem('channel') == '大白主动健康' || window.localStorage.getItem('channel') == '大白乐康') {
          //   this.$router.push({ path: '/index' })
          // } else {
          //   this.$message.success("用户权限不足")
          // }
          this.$router.push({ path: '/index' })
        }
      })
    },
    onLoad () {
      // getList().then(res => {
      //   console.log(res)
      //   this.appLink = res.data
      //   location.href=res.data
      // })
      Vue.set()
    }
  }
}
</script>

<style>
</style>
