<template>
  <basic-container>
    <div style="padding: 20px 0;">
      <i style="margin-right: 4px;" class="el-icon-time"></i>报告隶属时间段：
      <el-date-picker
          v-model="dateValue"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :readonly="false">
      </el-date-picker>
      {{ "\u3000\u3000\u3000" }}
      <el-button type="primary" @click="searchBtn">搜索</el-button>
      <el-button size="small"
                 plain
                 icon="el-icon-back" @click="handleBack()">返回上一级
      </el-button>
    </div>
    <div>
      <div style="font-size: 20px; margin-top: 10px;"> 收缩压、舒张压表 <el-link type="primary" style="margin-bottom: 5px; margin-left: 10px;" @click="tobpTrends()"> 查看趋势图>> </el-link> </div>
      <avue-crud
          :option="option"
          :data="data"
          :page.sync="page"
          ref="crud"
          @row-del="rowDel"
          v-model="form"
          :permission="permissionList"
          @row-update="rowUpdate"
          @row-save="rowSave"
          :before-open="beforeOpen"
          @search-change="searchChange"
          @search-reset="searchReset"
          @refresh-change="refreshChange"
          @selection-change="selectionChange"
          @on-load="onLoad">
      </avue-crud>
      <div style="font-size: 20px; margin-top: 10px;"> 心率表 <el-link type="primary" style="margin-bottom: 5px; margin-left: 10px;" @click="toheTrends()"> 查看趋势图>> </el-link> </div>
      <avue-crud
          :option="option1"
          :data="dataList"
          :page.sync="page1"
          @on-load="onLoad">
      </avue-crud>

      <div style="font-size: 20px; margin-top: 10px;"> 血糖表 <el-link type="primary" style="margin-bottom: 5px; margin-left: 10px;" @click="tobsTrends()"> 查看趋势图>> </el-link> </div>
      <avue-crud
          :option="optionbs"
          :data="databs"
          :page.sync="pagebs"
          @on-load="onLoad">
      </avue-crud>

      <div style="font-size: 20px; margin-top: 10px;"> 血氧表 <el-link type="primary" style="margin-bottom: 5px; margin-left: 10px;" @click="toboTrends()"> 查看趋势图>> </el-link>  </div>
      <avue-crud
          :option="optionbo"
          :data="databo"
          :page.sync="pagebo"
          @on-load="onLoad">
      </avue-crud>

      <div style="font-size: 20px; margin-top: 10px;"> 体温表 <el-link type="primary" style="margin-bottom: 5px; margin-left: 10px;" @click="tobtTrends()"> 查看趋势图>> </el-link>  </div>
      <avue-crud
          :option="optionbt"
          :data="databt"
          :page.sync="pagebt"
          @on-load="onLoad">
      </avue-crud>

      <div style="font-size: 20px; margin-top: 10px;"> 运动表 <el-link type="primary" style="margin-bottom: 5px; margin-left: 10px;" @click="tomoTrends()"> 查看趋势图>> </el-link>  </div>
      <avue-crud
          :option="optionmo"
          :data="datamo"
          :page.sync="pagemo"
          @on-load="onLoad">
      </avue-crud>
    </div>
  </basic-container>
</template>

<script>

import {
  getDetail,
  submit,
  remove,
  getClassifyFree,
  getAvgByDayFree,
  getSerachList,
  getSerachList1,
  getSerachListbs,
  getSerachListbo,
  getSerachListbt,
  getSerachListmo
} from '@/api/health/datashow'
// import {getWeek} from '@/api/sys/code'
import { mapGetters } from 'vuex'

export default {
  components: { },
  data () {
    return {
      orgOptions: {},
      form: {},
      dateValue: [],
      tabType: 1,
      picList: null,
      linList: null,
      params: {
        wxUserId: '',
        week: '',
        type: '1',
        jkStatus: '',
        beginDate: '',
        endDate: ''
      },
      weekDate: '',
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      page1: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      pagebs: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      pagebo: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      pagebt: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      pagemo: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      free: '',
      freeOptions: {
        // 普通图片上传
        customConfig: {}, // wangEditor编辑的配置
        oss: 'ali',
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-shanghai',
          endpoint: 'oss-cn-shanghai.aliyuncs.com',
          accessKeyId: 'LTAI4GGYDGZjaZQyshkjPBJH',
          accessKeySecret: '5O9VCIRDa6AqHtVj2NDiMUO9Uv2GDe',
          bucket: 'health-planet'
        }
      },
      pay: '',
      payOptions: {
        // 普通图片上传
        action: 'https://avuejs.com/imgupload',
        customConfig: {}, // wangEditor编辑的配置
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-beijing',
          endpoint: 'oss-cn-beijing.aliyuncs.com',
          accessKeyId: '',
          accessKeySecret: '',
          bucket: 'avue'
        }
      },
      selectionList: [],
      option: {
        searchMenuSpan: 12,
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        align: 'center',
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '测量时间',
            prop: 'meterDate'
          },
          {
            label: '收缩压',
            prop: 'systolic'
          },
          {
            label: '舒张压',
            prop: 'diastolic'
          },
          {
            label: '数据类型',
            prop: 'type',
            type: 'select',
            dicData: [
              { label: '手动录入', value: 1 },
              { label: '手表录入', value: 2 },
              { label: '一体机', value: 3 },
              { label: '脉搏波血压计', value: 4 },
              { label: '大型蓝牙血压站', value: 5 },
              { label: '小型蓝牙血压计', value: 6 }
            ]
          },
          {
            label: '状态',
            prop: 'pressureLevel'
          }
        ]
      },
      option1: {
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '测量时间',
            prop: 'meterDate'
          },
          {
            label: '心率',
            prop: 'heartRate'
          },
          {
            label: '数据类型',
            prop: 'type',
            type: 'select',
            dicData: [
              { label: '手动录入', value: 1 },
              { label: '手表录入', value: 2 },
              { label: '一体机', value: 3 },
              { label: '脉搏波血压计', value: 4 },
              { label: '大型蓝牙血压站', value: 5 },
              { label: '小型蓝牙血压计', value: 6 }
            ]
          },
          {
            label: '状态',
            prop: 'pressureLevel'
          }
        ]
      },
      optionbs: {
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '时间段',
            prop: 'type',
            type: 'select',
            dicData: [
              { label: '随机', value: 9 },
              { label: '早餐前', value: 1 },
              { label: '早餐后', value: 2 },
              { label: '午餐前', value: 3 },
              { label: '午餐后', value: 4 },
              { label: '晚餐前', value: 5 },
              { label: '晚餐后', value: 6 },
              { label: '睡前', value: 7 },
              { label: '凌晨', value: 8 }
            ]
          },
          {
            label: '测量时间',
            prop: 'bsDateTime'
          },
          {
            label: '血糖数值',
            prop: 'bsValue'
          }
        ]
      },
      optionbo: {
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '测量时间',
            prop: 'boDate'
          },
          {
            label: '血氧数值',
            prop: 'boValue'
          }
        ]
      },
      optionbt: {
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '测量时间',
            prop: 'btDate'
          },
          {
            label: '体温数值',
            prop: 'btValue'
          }
        ]
      },
      optionmo: {
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '测量时间',
            prop: 'moDate'
          },
          {
            label: '跑步步数',
            prop: 'moSn'
          },
          {
            label: '消耗卡路里',
            prop: 'moCa'
          }
        ]
      },
      data: [],
      dataList: [],
      databs: [],
      databo: [],
      databt: [],
      datamo: []

    }
  },
  created () {
    this.params.wxUserId = this.$route.query.wxUserId || '' // eslint-disable-line no-unused-vars
    this.params.week = this.$route.query.meterDate || '' // eslint-disable-line no-unused-vars
    this.params.jkStatus = this.$route.query.jkStatus || ''
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.health_bpmeterdata_add,
        viewBtn: this.permission.health_bpmeterdata_view,
        delBtn: this.permission.health_bpmeterdata_delete,
        editBtn: this.permission.health_bpmeterdata_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {

    handleBack () {
      this.$router.back()
    },
    classifyfree (date) {
      getClassifyFree({ date: date, wxUserId: this.params.wxUserId }).then(res => {
        const data = res.data
        this.picList = data
      })
    },
    avgByDayFree (day) {
      getAvgByDayFree({ date: day, wxUserId: this.params.wxUserId }).then(res => {
        const data = res.data
        this.linList = data
      })
    },

    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)

      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },

    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    tobpTrends () {
      const parameter = {
        wxUserId: this.params.wxUserId,
        beginDate: this.params.beginDate,
        endDate: this.params.endDate
      }
      this.$router.push({
        path: '/health/bpTrends',
        query: parameter
      })
    },
    toheTrends () {
      const parameter = {
        wxUserId: this.params.wxUserId,
        beginDate: this.params.beginDate,
        endDate: this.params.endDate
      }
      this.$router.push({
        path: '/health/heTrends',
        query: parameter
      })
    },
    tobsTrends () {
      const parameter = {
        wxUserId: this.params.wxUserId,
        beginDate: this.params.beginDate,
        endDate: this.params.endDate
      }
      this.$router.push({
        path: '/health/bsTrends',
        query: parameter
      })
    },
    toboTrends () {
      const parameter = {
        wxUserId: this.params.wxUserId,
        beginDate: this.params.beginDate,
        endDate: this.params.endDate
      }
      this.$router.push({
        path: '/health/boTrends',
        query: parameter
      })
    },
    tobtTrends () {
      const parameter = {
        wxUserId: this.params.wxUserId,
        beginDate: this.params.beginDate,
        endDate: this.params.endDate
      }
      this.$router.push({
        path: '/health/btTrends',
        query: parameter
      })
    },
    tomoTrends () {
      const parameter = {
        wxUserId: this.params.wxUserId,
        beginDate: this.params.beginDate,
        endDate: this.params.endDate
      }
      this.$router.push({
        path: '/health/moTrends',
        query: parameter
      })
    },
    onLoad () {
      if (this.dateValue == null) {
        this.params.beginDate = ''
        this.params.endDate = ''
      } else {
        this.params.beginDate = this.dateValue[0] || ''
        this.params.endDate = this.dateValue[1] || ''
      }
      getSerachList(this.page.currentPage, this.page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
      getSerachList1(this.page1.currentPage, this.page1.pageSize, this.params).then(res => {
        const data = res.data
        this.page1.total = data.total
        this.dataList = data.records
      })
      getSerachListbs(this.pagebs.currentPage, this.pagebs.pageSize, this.params).then(res => {
        const data = res.data
        this.pagebs.total = data.total
        this.databs = data.records
      })
      getSerachListbo(this.pagebo.currentPage, this.pagebo.pageSize, this.params).then(res => {
        const data = res.data
        this.pagebo.total = data.total
        this.databo = data.records
      })
      getSerachListbt(this.pagebt.currentPage, this.pagebt.pageSize, this.params).then(res => {
        const data = res.data
        this.pagebt.total = data.total
        this.databt = data.records
      })
      getSerachListmo(this.pagebt.currentPage, this.pagebt.pageSize, this.params).then(res => {
        const data = res.data
        this.pagemo.total = data.total
        this.datamo = data.records
      })
    },
    searchBtn (data) {
      if (this.dateValue == null) {
        this.params.beginDate = ''
        this.params.endDate = ''
      } else {
        this.params.beginDate = this.dateValue[0] || ''
        this.params.endDate = this.dateValue[1] || ''
      }
      getSerachList(this.page.currentPage, this.page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
      getSerachList1(this.page1.currentPage, this.page1.pageSize, this.params).then(res => {
        const data = res.data
        this.page1.total = data.total
        this.dataList = data.records
      })
      getSerachListbs(this.pagebs.currentPage, this.pagebs.pageSize, this.params).then(res => {
        const data = res.data
        this.pagebs.total = data.total
        this.databs = data.records
      })
      getSerachListbo(this.pagebo.currentPage, this.pagebo.pageSize, this.params).then(res => {
        const data = res.data
        this.pagebo.total = data.total
        this.databo = data.records
      })
      getSerachListbt(this.pagebt.currentPage, this.pagebt.pageSize, this.params).then(res => {
        const data = res.data
        this.pagebt.total = data.total
        this.databt = data.records
      })
      getSerachListmo(this.pagebt.currentPage, this.pagebt.pageSize, this.params).then(res => {
        const data = res.data
        this.pagemo.total = data.total
        this.datamo = data.records
      })
    }
  }
}
</script>

<style>
.memberTit {
  color: #5a88d3;
  font-size: 16px;
  margin-bottom: 20px;
}

.memberTit::before {
  content: '';
  border-left: 6px solid #5a88d3;
  margin-right: 20px;
  background: #5a88d3;
}
</style>
