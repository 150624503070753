<template>
  <basic-container>
    <avue-crud :option="option"
               :data="data"
               :page.sync="page"
               ref="crud"
               @row-del="rowDel"
               v-model="form"
               :permission="permissionList"
               @row-update="rowUpdate"
               @row-save="rowSave"
               :before-open="beforeOpen"
               @search-change="searchChange"
               @search-reset="searchReset"
               @refresh-change="refreshChange"
               @selection-change="selectionChange"
               :upload-before="uploadBefore"
               :upload-exceed="uploadExceed"
               @on-load="onLoad">
      <template slot="menuForm">
        <el-button type="primary" icon="el-icon-check" size="small" plain @click="$refs.crud.rowUpdate()">提交解读
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button size="small" type="text" @click="jdReport(scope.row)">
          报告解读
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>

import { getList, getDetail, submit, remove, pdf } from '@/api/health/report'
import { mapGetters } from 'vuex'
import func from '@/util/func'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      params: {
        status: 1
      },
      selectionList: [],
      option: {
        searchMenuSpan: 12,
        tip: false,
        border: true,
        index: true,
        columnBtn: false,
        refreshBtn: false,
        saveBtn: false,
        updateBtn: false,
        cancelBtn: true,
        addBtn: false,
        editBtn: false,
        editBtnText: '解读',
        delBtn: false,
        selection: true,
        column: [
          {
            label: '姓名',
            prop: 'jkName',
            detail: true,
            search: true
          },
          {
            label: '年龄',
            prop: 'jkAge',
            detail: true
          },
          {
            label: '性别',
            prop: 'jkSex',
            type: 'select',
            dicUrl: '/sys/dict/code/sex',
            detail: true
          },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            search: true,
            searchValue: 1,
            detail: true,
            dicUrl: '/sys/dict/code/report_status',
            rules: [{
              required: true,
              message: '请输入状态'
            }],
            change: ({ value }) => {
              this.params.status = value
              this.onLoad(this.page)
            }
          },
          {
            label: '体检报告',
            prop: 'checkUrl',
            hide: true,
            editDisabled: true,
            dataType: 'array',
            type: 'upload',
            propsHttp: {
              res: 'data.0'
            },
            span: 24,
            listType: 'picture-card'
          },
          {
            label: '报告解读',
            prop: 'readUrls',
            type: 'upload',
            accept: 'application/pdf',
            oss: 'ali',
            dataType: 'string',
            loadText: '附件上传中，请稍等',
            limit: 1,
            span: 24,
            hide: true,
            tip: '只能上传pdf文件',
            uploadPreview: (file, column) => {
              pdf('reader/' + file.name).then(res => {
                window.open(res.data, '_blank')
              })
              return false
            }
          },
          {
            label: '更新时间',
            prop: 'updateTime',
            readOnly: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.health_report_add,
        viewBtn: this.permission.health_report_view,
        delBtn: this.permission.health_report_delete,
        editBtn: this.permission.health_report_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    jdReport (row) {
      console.log(row)
      const parameter = {
        id: row.id,
        jkName: row.jkName,
        jkAge: row.jkAge,
        jkSex: row.jkSex
      }
      this.$router.push({
        path: '/health/report-jd',
        query: parameter
      })
    },
    uploadExceed (limit) {
      this.$message.error('最多只能上传' + limit + '个文件')
    },
    uploadBefore (file, done, loading, column) {
      // 如果你想修改file文件,由于上传的file是只读文件，必须复制新的file才可以修改名字，完后赋值到done函数里,如果不修改的话直接写done()即可
      const newFile = new File([file], 'reader/' + func.uuid() + '.' + func.getFileType(file.name), { type: file.type })
      done(newFile)
    },
    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.params = params
      this.onLoad(this.page)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.params = data.searchForm
      this.onLoad(this.page)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },

    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
          console.log(this.form)
        })
      }
      done()
    },
    onLoad (page) {
      this.params.channel = window.localStorage.getItem('channel')
      getList(page.currentPage, page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>
