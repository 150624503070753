<template>
  <basic-container>
    <avue-crud :option="option"
               :data="data"
               :page.sync="page"
               ref="crud"
               @row-del="rowDel"
               v-model="form"
               :permission="permissionList"
               @row-update="rowUpdate"
               @row-save="rowSave"
               :before-open="beforeOpen"
               @search-change="searchChange"
               @search-reset="searchReset"
               @refresh-change="refreshChange"
               @selection-change="selectionChange"
               @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.common_order_delete"
                   @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getList, getDetail, submit, remove } from '@/api/common/order'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      params1: {
        status: 3
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '姓名',
            prop: 'name',
            search: true
          },
          {
            label: '性别',
            prop: 'sex',
            type: 'select',
            dicUrl: '/sys/dict/code/sex'
          },
          {
            label: '手机号码',
            prop: 'mobile',
            search: true
          },
          {
            label: '年龄',
            prop: 'age',
            rules: [{
              required: true,
              message: '请输入年龄'
            }]
          },
          // {
          //   label: '订单id',
          //   prop: 'id',
          //   rules: [{
          //     required: true,
          //     message: '请输入订单id'
          //   }]
          // },
          // {
          //   label: '用户id',
          //   prop: 'wxUserId',
          //   rules: [{
          //     required: true,
          //     message: '请输入用户id'
          //   }]
          // },
          // {
          //   label: '订单号',
          //   prop: 'orderNo',
          //   rules: [{
          //     required: true,
          //     message: '请输入订单号'
          //   }]
          // },
          // {
          //   label: 'sn',
          //   prop: 'sn',
          //   rules: [{
          //     required: true,
          //     message: '请输入sn'
          //   }]
          // },
          // {
          //   label: '订单类型',
          //   prop: 'type',
          //   rules: [{
          //     required: true,
          //     message: '请输入订单类型'
          //   }]
          // },
          {
            label: '订单类型',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入订单类型'
            }],
            dicData: [
              { label: 'VIP', value: 1 },
              { label: '血压付费', value: 2 },
              { label: '体检付费', value: 3 },
              { label: '会员充值首月特惠', value: 4 },
              { label: '会员月充值', value: 5 },
              { label: '会员季充值', value: 6 },
              { label: '会员年充值', value: 7 }
            ]
          },
          {
            label: '金额',
            prop: 'amount',
            rules: [{
              required: true,
              message: '请输入金额'
            }]
          },
          {
            label: '状态 0正常  1取消  2冻结  3已支付  4已交付  9其他',
            prop: 'status',
            rules: [{
              required: true,
              message: '请输入状态 0正常  1取消  2冻结  3已支付  4已交付  9其他'
            }]
          },
          // {
          //   label: '数量',
          //   prop: 'quantity',
          //   rules: [{
          //     required: true,
          //     message: '请输入数量'
          //   }]
          // },
          // {
          //   label: '运费',
          //   prop: 'freight',
          //   rules: [{
          //     required: true,
          //     message: '请输入运费'
          //   }]
          // },
          // {
          //   label: '物流方式',
          //   prop: 'logisticsId',
          //   rules: [{
          //     required: true,
          //     message: '请输入物流方式'
          //   }]
          // },
          // {
          //   label: '物流单号',
          //   prop: 'logisticsNo',
          //   rules: [{
          //     required: true,
          //     message: '请输入物流单号'
          //   }]
          // },
          // {
          //   label: '地址',
          //   prop: 'address',
          //   rules: [{
          //     required: true,
          //     message: '请输入地址'
          //   }]
          // },
          {
            label: '创建时间',
            prop: 'createTime',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            rules: [{
              required: true,
              message: '请输入修改时间'
            }]
          }
          // {
          //   label: '乐观锁',
          //   prop: 'revision',
          //   rules: [{
          //     required: true,
          //     message: '请输入乐观锁'
          //   }]
          // }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.shop_order_add,
        viewBtn: this.permission.shop_order_view,
        delBtn: this.permission.shop_order_delete,
        editBtn: this.permission.shop_order_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      console.log('test searchChange')
      console.log(params)
      console.log(this.params1)
      this.params1 = params
      this.params1.status = 3
      console.log(this.params1)
      this.onLoad(this.page)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      console.log('test')
      console.log(data)
      console.log(this.params1)
      this.params1 = data.searchForm
      this.params1.status = 3
      console.log(this.params1)
      this.onLoad(this.page)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },

    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page) {
      getList(page.currentPage, page.pageSize, this.params1).then(res => {
        console.log('test')
        console.log(res)
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>
