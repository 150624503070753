import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/health/healthrecords/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/health/healthrecords/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const getSubmit = (row) => {
  return request({
    url: '/health/healthrecords/submit',
    method: 'post',
    data: row
  })
}

export const getDetail = (wxUserId) => {
  return request({
    url: '/health/healthrecords/detail',
    method: 'get',
    params: {
      wxUserId
    }
  })
}

