<template>
  <basic-container>
    <div>
      <avue-crud :option="option" :data="data" :page.sync="page" ref="crud" v-model="form"
        :permission="permissionList"
        @search-change="searchChange" @search-reset="searchReset" @refresh-change="refreshChange"
        @selection-change="selectionChange" @on-load="onLoad">
        <template slot="menuLeft">
        </template>
        <template slot-scope="scope" slot="menu">
          <el-button type="text" icon="el-icon-s-operation" size="small" @click.native="toItem(scope.row)">内容编辑
          </el-button>
        </template>
      </avue-crud>
    </div>
    <div v-if="boolean">
      <avue-crud
          :option="option1"
          :data="dataList"
          :page.sync="page1"
          v-model="form1"
          @row-del="rowDel1"
          :permission="permissionList"
          @row-update="rowUpdate1"
          @row-save="rowSave1"
          :before-open="beforeOpen1"
          @on-load="onLoad">
      </avue-crud>
    </div>
  </basic-container>
</template>

<script>
// import { getList, submit, remove, getDetail } from '@/api/sys/speech_template'
import { getList1 } from '@/api/health/plan_details'
import { getList, getDetail, remove, submit, getBpDurg } from '@/api/health/bpDrug'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      form1: {},
      wxUserId: 0,
      schemeId: 0,
      boolean: false,
      id: 0,
      show: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      page1: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        addBtn: false,
        editBtn: false,
        addRowBtn: false,
        cellBtn: true,
        delBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        menuWidth: 300,
        column: [
          {
            label: '方案名称',
            prop: 'title',
            cell: true,
            rules: [{
              required: true,
              message: '请输入模板名称'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            cell: false
          }
        ]
      },
      option1: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        addBtn: true,
        menuWidth: 200,
        column: [
          {
            type: 'select',
            label: '选择类型',
            prop: 'ssProvince',
            span: 24,
            hide: true,
            addDisplay: true,
            editDisabled: false,
            cascader: ['test'],
            dicUrl: 'https://dbadmin.dbzdjk.com/admin/fy/rest/third/getBpDrugType',
            // dicUrl: 'http://localhost:6126/admin/fy/rest/third/getBpDrugType',
            dicMethod: 'post',
            props: {
              label: 'name',
              value: 'code'
            }
            // search: true
          },
          {
            type: 'tree',
            label: '选择药品',
            prop: 'test',
            span: 24,
            hide: true,
            addDisplay: true,
            editDisabled: false,
            cascader: ['test1'],
            dicMethod: 'post',
            props: {
              label: 'name',
              value: 'code'
            },
            dicUrl: 'https://dbadmin.dbzdjk.com/admin/fy/rest/third/getBpDrugDetails?drugType={{key}}',
            // dicUrl: 'http://localhost:6126/admin/fy/rest/third/getBpDrugDetails?drugType={{key}}',
            slot: true,
            nodeClick: (data) => {
              getBpDurg(data.code).then(res => {
                const data1 = res.data
                this.form1.drugName = data1.drugName
                this.form1.drugType = data1.drugType
                this.form1.component = data1.component
                this.form1.indications = data1.indications
                this.form1.disable = data1.disable
                this.form1.frequency = data1.frequency
              })
            }
          },
          // {
          //   type: 'tree',
          //   label: '选择药品 ',
          //   prop: 'ssProvince',
          //   span: 24,
          //   hide: true,
          //   addDisplay: true,
          //   editDisabled: false,
          //   dicUrl: 'https://dbadmin.dbzdjk.com/admin/fy/rest/third/DmTest',
          //   dicMethod: 'post',
          //   props: {
          //     label: 'name',
          //     value: 'code'
          //   },
          //   slot: true,
          //   nodeClick: (data) => {
          //     getDurg(data.code).then(res => {
          //       const data1 = res.data
          //       this.form1.drugName = data1.drugName
          //       this.form1.tradeName = data1.tradeName
          //       this.form1.indications = data1.indications
          //       this.form1.dosageTaken = data1.dosageTaken
          //       this.form1.medicationFrequency = data1.medicationFrequency
          //       this.form1.medicationTime = data1.medicationTime
          //       this.form1.note = data1.note
          //     })
          //   }
          // },
          {
            label: '药品名称',
            prop: 'drugName',
            span: 24,
            cell: true,
            rules: [{
              required: true,
              message: '请输入药品名称'
            }]
          },
          {
            label: '药品类型',
            prop: 'drugType',
            span: 24,
            cell: true,
            rules: [{
              required: true,
              message: '请输入药品类型'
            }]
          },
          {
            label: '药物成分',
            prop: 'component',
            span: 24,
            cell: true,
            rules: [{
              required: true,
              message: '请输入药物成分'
            }]
          },
          {
            label: '适用症状',
            prop: 'indications',
            span: 24,
            cell: true,
            rules: [{
              required: true,
              message: '请输入药品适用症状'
            }]
          },
          {
            label: '禁用症状',
            prop: 'disable',
            span: 24,
            cell: true,
            rules: [{
              required: true,
              message: '请输入禁用症状'
            }]
          },
          {
            label: '使用频次',
            prop: 'frequency',
            span: 24,
            cell: true,
            rules: [{
              required: true,
              message: '请输入使用频次'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            hide: true,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            hide: true,
            cell: false
          }
        ]
      },
      data: [],
      dataList: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qa_add,
        viewBtn: this.permission.qa_qa_view,
        delBtn: this.permission.qa_qa_delete,
        editBtn: this.permission.qa_qa_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  created () {
    this.wxUserId = this.$route.query.wxUserId
    this.schemeId = this.$route.query.schemeId
    this.id = this.$route.query.id
    if (this.$route.query.id === '2') {
      this.boolean = true
    }
  },
  methods: {
    toItem (row) {
      this.$router.push({
        path: '/health/health_plan_details',
        query: {
          wxUserId: this.wxUserId,
          id: row.id,
          schemeId: this.schemeId
        }
      })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    rowSave1 (row, done, loading) {
      row.wxUserId = this.wxUserId
      row.schemeId = this.schemeId
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate1 (row, index, done, loading) {
      row.wxUserId = this.wxUserId
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel1 (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    beforeOpen1 (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form1.id).then(res => {
          this.form1 = res.data
        })
      }
      done()
    },
    onLoad (page, params = { wxUserId: this.wxUserId, schemeId: this.schemeId }) {
      getList1(this.id).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data
      })
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data1 = res.data.records
        this.page1.total1 = data1.total
        this.dataList = data1
        // console.log('ceshiyixia')
        // console.log(res.data)
      })
    }
  }
}
</script>

<style></style>
