<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.qa_qaitem_delete"
            @click="handleDelete">删 除
        </el-button>
        <el-button size="small"
                   plain
                   icon="el-icon-back" @click="handleBack()">返 回
        </el-button>
      </template>

      <template slot-scope="{row}" slot="inputForm">
        <el-tag>{{row}}</el-tag>
      </template>
      <template  slot="buttonForm">
        <el-button @click="addAll" size="small" type="primary">添加10条子表单数据</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getList, getDetail, submit, remove } from '@/api/qa/qaitem'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      params: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        labelWidth: 120,
        selection: true,
        align: 'center',
        column: [
          {
            prop: 'qaId',
            type: 'text',
            display: false
          },
          {
            label: '题目',
            prop: 'itemContent',
            type: 'textarea',
            tip: '填空题有几个空，下面选项增加几项',
            span: 24,
            align: 'left',
            rules: [{
              required: true,
              message: '请输入题目'
            }]
          },
          {
            label: '引导语句',
            prop: 'guide',
            type: 'textarea',
            span: 24,
            align: 'left'
          },
          {
            label: '试题类型',
            prop: 'itemType',
            type: 'radio',
            dicUrl: '/sys/dict/code/qa_item_type',
            value: 1,
            width: 80,

            span: 24,
            rules: [{
              required: true,
              message: '请选择试题类型'
            }]
          },
          {
            label: '是否多选',
            prop: 'isMulti',
            type: 'radio',
            display: false,
            value: 0,
            dicUrl: '/sys/dict/code/yes_no',
            hide: true,
            rules: [{
              required: true,
              message: '请输入是否多选'
            }]
          },
          {
            label: '最大选择项',
            prop: 'maxOptions',
            type: 'number',
            display: false,
            hide: true
          },
          {
            label: '是否必填',
            prop: 'isRequire',
            hide: true,
            type: 'radio',
            value: 1,
            dicUrl: '/sys/dict/code/yes_no',
            rules: [{
              required: true,
              message: '请输入是否必填'
            }]
          },
          {
            label: '是否有补充项',
            prop: 'hasOther',
            dicUrl: '/sys/dict/code/yes_no',
            type: 'radio',
            hide: true,
            value: 0
          },
          {
            label: '排序',
            prop: 'orderNum',
            width: 80,
            rules: [{
              required: true,
              message: '请输入排序'
            }]
          },
          {
            label: '答案',
            prop: 'solution',
            hide: true
          },
          {
            label: '启用状态',
            prop: 'status',
            type: 'radio',
            width: 80,
            dicUrl: '/sys/dict/code/enable_status',
            value: 1,
            rules: [{
              required: true,
              message: '请选择启用状态'
            }]
          },
          {
            label: '选项',
            prop: 'itemOptions',
            type: 'dynamic',
            span: 24,
            children: {
              align: 'center',
              headerAlign: 'center',
              column: [
                {
                  label: '试题选项',
                  prop: 'optionKey',
                  align: 'center',
                  width: 100,
                  rules: [{
                    required: true,
                    message: '请输入试题选项：ABCD等等'
                  }]
                },
                {
                  label: '试题内容',
                  prop: 'optionContent'
                },
                {
                  label: '选项分值',
                  prop: 'optionVal',
                  value: 0
                }
              ]
            }
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qaitem_add,
        viewBtn: this.permission.qa_qaitem_view,
        delBtn: this.permission.qa_qaitem_delete,
        editBtn: this.permission.qa_qaitem_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  created () {
    this.form.qaId = this.$route.query.qaId
    this.params.qaId = this.$route.query.qaId
    console.log(this.form)
  },
  watch: {
    'form.itemType': {
      handler (val) {
        const isMulti = this.findObject(this.option.column, 'isMulti')
        const itemOptions = this.findObject(this.option.column, 'itemOptions')
        const hasOther = this.findObject(this.option.column, 'hasOther')
        if (val === 1) {
          isMulti.display = true
          itemOptions.display = true
          hasOther.display = true
          isMulti.rules = [{
            required: true,
            message: '请选择是否多选'
          }]
        } else if (val === 3) {
          isMulti.display = false
          itemOptions.display = true
          hasOther.display = false
          isMulti.rules = []
        } else {
          itemOptions.display = false
          isMulti.display = false
          hasOther.display = false
          isMulti.rules = []
        }
      },
      immediate: true
    },
    'form.isMulti': {
      handler (val) {
        const maxOptions = this.findObject(this.option.column, 'maxOptions')
        maxOptions.display = val === 1
      },
      immediate: true
    },
    'form.itemOptions': {
      handler (newVal, oldVal) {
        if (newVal) {
          newVal.forEach((d, index) => {
            d.optionKey = String.fromCharCode('A'.charCodeAt(0) + index)
          })
        }
      }
    }
  },
  methods: {
    addAll () {
      for (let i = 0; i < 10; i++) {
        this.form.dynamic.push({
          input: 1,
          select: 1,
          radio: 1
        })
      }
    },
    rowSave (row, done, loading) {
      row.qaId = this.$route.query.qaId
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.params = params
      this.onLoad(this.page)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.params = data.searchForm
      this.onLoad(this.page)
    },

    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    handleBack () {
      this.$router.back()
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page) {
      getList(page.currentPage, page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>

<style>
</style>
