<template>
  <basic-container>
    <avue-crud :option="option"
               :data="data"
               :page.sync="page"
               ref="crud"
               @row-del="rowDel"
               v-model="form"
               :permission="permissionList"
               @row-update="rowUpdate"
               @row-save="rowSave"
               :before-open="beforeOpen"
               @search-change="searchChange"
               @search-reset="searchReset"
               @refresh-change="refreshChange"
               @selection-change="selectionChange"
               @on-load="onLoad">
      <template slot="menuLeft">
        <el-button size="small"
                   plain
                   icon="el-icon-back" @click="handleBack()">返 回
        </el-button>
      </template>
    </avue-crud>
    <br>
    <el-row>
      <el-col :span="24">
        <div>
          <div id="pg" style="width: 100%;height: 400px;"></div>
        </div>
      </el-col>
    </el-row>
  </basic-container>
</template>

<script>
import { getList, getDetail, submit, remove } from '@/api/qa/qarecord'
import { mapGetters } from 'vuex'
import echarts from 'echarts'
export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        column: [
          {
            label: '题目',
            prop: 'qaId',
            type: 'select',
            dicUrl: 'qa/qa/all',
            props: {
              value: 'id',
              label: 'name'
            }
          },
          {
            label: '用户',
            prop: 'userId',
            type: 'select',
            dicUrl: '/health/userjk/jk?uid=' + this.$route.query.uid,
            props: {
              value: 'wxUserId',
              label: 'name'
            }
          },
          {
            label: '分值',
            prop: 'score'
          },
          {
            label: '提交时间',
            prop: 'createTime',
            type: 'date',
            format: 'yyyy-MM-dd hh:mm:ss',
            valueFormat: 'yyyy-MM-dd hh:mm:ss'
          }
        ]
      },
      data: [],
      charts: '',
      opinionData: [],
      yData: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qarecord_add,
        viewBtn: this.permission.qa_qarecord_view,
        delBtn: this.permission.qa_qarecord_delete,
        editBtn: this.permission.qa_qarecord_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    drawC () {
      this.charts = echarts.init(document.getElementById('pg'))
      const option = {
        title: {},
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['评分']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.opinionData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '评分',
            type: 'line',
            stack: '分值',
            data: this.yData
          }
        ]
      }
      this.charts.setOption(option)
    },
    rowSave (row, done, loading) {
      row.qaId = this.$route.query.qaId
      row.userId = this.$route.query.uid
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      row.qaId = this.$route.query.qaId
      row.userId = this.$route.query.uid
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    handleBack () {
      this.$router.back()
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = {}) {
      params.qaId = this.$route.query.qaId
      params.userId = this.$route.query.uid
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        console.log(this.data)
        this.opinionData = []
        this.yData = []
        for (let i = 0; i < this.data.length; i++) {
          this.opinionData.push(this.data[i].createTime)
          this.yData.push(this.data[i].score)
        }
        this.drawC()
      })
    }
  }
}
</script>
