<template>
  <div class="login-container">
    <div class="login-weaper animated bounceInDown">
      <div class="login-left">
        <div class="login-time">
          {{ time }}
        </div>
        <img
            class="img"
            src="~@/assets/img/logo.png"
            alt="">
        <p class="title">大白健康星球</p>
      </div>
      <div class="login-border">
        <div class="login-main">
          <h4 class="login-title">
            登录{{ title }}
          </h4>
          <userLogin></userLogin>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import userLogin from './userlogin'
import { mapGetters } from 'vuex'
import { dateFormat } from '@/util/date'
import { title } from '@/config'

export default {
  name: 'Login',
  components: {
    userLogin
  },
  data () {
    return {
      orgOptions: {},
      time: '',
      title: title
    }
  },
  created () {
    this.getTime()
    setInterval(() => {
      this.getTime()
    }, 1000)
  },
  mounted () {

  },
  computed: {
    ...mapGetters([])
  },
  props: [],
  methods: {
    getTime () {
      this.time = dateFormat(new Date())
    },
    onLoad () {

    }
  }

}
</script>

<!-- <style>
*{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style> -->

<style lang="scss">
@import "../../styles/login.scss";
</style>
