<template>
  <basic-container>
    <div style="font-size: 20px; margin-top: 10px; margin-bottom: 20px;"> 警戒值配置 </div>
    <avue-crud
        :option="option"
        :data="data"
        v-model="form"
        :permission="permissionList"
        :before-open="beforeOpen"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.menu_delete"
            plain
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template
          slot-scope="{row}"
          slot="icon">
        <div style="text-align:center">
          <i :class="row.icon"></i>
        </div>
      </template>

      <template
          slot-scope="{row}"
          slot="deptId">
        <el-tag>{{ row.deptName }}</el-tag>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { add, getList, getMenu, remove, update } from '@/api/sys/warning_value'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        dialogWidth: '60%',
        tree: true,
        border: true,
        viewBtn: true,
        index: true,
        column: [
          {
            label: '使用环境',
            prop: 'type',
            type: 'radio',
            dicData: [
              { label: '白天', value: 1 },
              { label: '晚上', value: 2 }
            ]
          },
          {
            label: '是否使用',
            prop: 'isUsing',
            type: 'radio',
            dicData: [
              { label: '使用中', value: 1 },
              { label: '没有使用', value: 0 }
            ]
          },
          {
            label: '舒张压上限',
            prop: 'diastolicUpper',
            dicData: [
              { label: '无', value: -1 }
            ]
          },
          {
            label: '舒张压下限',
            prop: 'diastolicBelow',
            dicData: [
              { label: '无', value: -1 }
            ]
          },
          {
            label: '收缩压上限',
            prop: 'systolicUpper',
            dicData: [
              { label: '无', value: -1 }
            ]
          },
          {
            label: '收缩压下限',
            prop: 'systolicBelow',
            dicData: [
              { label: '无', value: -1 }
            ]
          },
          {
            label: '心率上限',
            prop: 'heartRateUpper',
            dicData: [
              { label: '无', value: -1 }
            ]
          },
          {
            label: '心率下限',
            prop: 'heartRateBelow',
            dicData: [
              { label: '无', value: -1 }
            ]
          },
          {
            label: '血糖上限',
            prop: 'bsUpper',
            type: 'radio',
            dicData: [
              { label: '无', value: -1 }
            ]
          },
          {
            label: '血糖下限',
            prop: 'bsBelow',
            dicData: [
              { label: '无', value: -1 }
            ]
          },
          {
            label: '血氧上限',
            prop: 'boUpper',
            type: 'radio',
            dicData: [
              { label: '无', value: -1 }
            ]
          },
          {
            label: '血氧下限',
            prop: 'boBelow',
            dicData: [
              { label: '无', value: -1 }
            ]
          },
          {
            label: '体温上限',
            prop: 'btUpper',
            dicData: [
              { label: '无', value: -1 }
            ]
          },
          {
            label: '体温下限',
            prop: 'btBelow',
            dicData: [
              { label: '无', value: -1 }
            ]
          }
        ]
      },
      data: []
    }
  },

  // watch:{
  //   'form.text1'(val){
  //     if(val==0){
  //       this.defaults.text2.display=true
  //       this.defaults.text3.label='内容3'
  //     }else{
  //       this.defaults.text2.display=false
  //       this.defaults.text3.label='有没有发现我变了'
  //     }
  //   }
  // },

  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_menu_add, false),
        viewBtn: this.vaildData(this.permission.sys_menu_view, false),
        delBtn: this.vaildData(this.permission.sys_menu_delete, false),
        editBtn: this.vaildData(this.permission.sys_menu_edit, false)
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    rowSave (row, done) {
      add(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }, () => {
        done()
      })
    },
    rowUpdate (row, index, done) {
      update(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }, () => {
        done()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(row.id).then(() => {
          this.onLoad(this.page)
        })
      })
    },
    handleDelete () {
      const _this = this
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return remove(this.ids).then(() => {
          _this.onLoad(this.page)
          _this.$refs.crud.toggleSelection()
        })
      })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getMenu(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = {}, callback) {
      getList().then(res => {
        console.log(res)
        console.log(res.data)
        const data = res
        this.data = data
        if (callback) {
          callback()
        }
      })
    }
  }
}
</script>

<style>
</style>
