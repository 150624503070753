<template>
  <el-container style="height: 100vh; border: 1px solid #eee">
    <el-container style="background: #fff;padding: 20px; overflow-y: auto">
      <avue-form ref="form" v-model="reportForm" :option="reportOption" >
        <el-form-item slot="weight">
          <el-input v-model="reportForm.weight"></el-input>
        </el-form-item>

        <el-form-item slot="height">
          <el-input v-model="reportForm.height"></el-input>
        </el-form-item>

        <el-form-item slot="bmi" disabled="true">
          <el-input v-model="bmi"></el-input>
        </el-form-item>
      </avue-form>
    </el-container>
  </el-container>
</template>

<script>

import { getInformationDetail } from '@/api/health/userjk'
import {
  mapGetters
} from 'vuex'

export default {
  components: {},
  data () {
    return {
      reportId: 0,
      sizeValue: 'small',
      reportForm: {}
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    bmi () {
      const realVal = parseFloat(this.reportForm.weight / ((this.reportForm.height / 100) * (this.reportForm.height / 100))).toFixed(2)
      return realVal
    },
    reportOption () {
      return {
        size: this.sizeValue,
        // submitText: '录入',
        emptyBtn: false,
        submitBtn: false,
        column: [
          {
            label: '姓名',
            prop: 'name',
            detail: true
          },
          {
            label: '年龄',
            prop: 'age',
            detail: true
          },
          // {
          //   label: '腰围(cm)',
          //   prop: 'waistline'
          // },
          {
            label: '身高(cm)',
            prop: 'height',
            detail: true
          },
          {
            label: '体重(kg)',
            prop: 'weight',
            detail: true
          },
          {
            // label: this.weight / (this.height * this.height),
            label: 'BMI(kg/m²)',
            disabled: true,
            prop: 'bmi'
          },
        ],
      }
    }
  },
  created () {
    this.reportForm.name = this.$route.query.jkName
    this.reportForm.age = this.$route.query.jkAge
    this.reportForm.sex = this.$route.query.jkSex
    this.reportForm.wxUserId = this.$route.query.wxUserId
    this.reportForm.weight = this.$route.query.weight
    this.reportForm.height = this.$route.query.height
    this.onLoad()
  },
  methods: {
    // submit (form, done) {
    //   this.reportForm.cdFamilyHistory = this.radio
    //   if (this.bmi === 'NaN') {
    //     this.reportForm.bmi = 0
    //   } else {
    //     this.reportForm.bmi = this.bmi
    //   }
    //   if (this.reportForm.height === undefined) {
    //     this.reportForm.height = 0
    //   } else {
    //     this.reportForm.height = this.height
    //   }
    //   if (this.reportForm.weight === undefined) {
    //     this.reportForm.weight = 0
    //   } else {
    //     this.reportForm.weight = this.weight
    //   }
    //   getSubmit(this.reportForm).then(() => {
    //     done()
    //     this.$message({
    //       type: 'success',
    //       message: '操作成功!'
    //     })
    //   }).catch(() => {
    //     done()
    //   })
    // },
    // onLoad () {
    //   getInformationDetail(this.reportForm.wxUserId).then(res => {
    //     this.reportForm = res.data
    //   })
    // }
    onLoad () {

    }
  }

}
</script>
