<template>
  <basic-container>
    <div style="padding: 20px 0;">
      <i style="margin-right: 4px;" class="el-icon-time"></i>报告隶属时间段：
      <el-date-picker
          v-model="dateValue"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :readonly="false">
      </el-date-picker>
      {{ "\u3000\u3000\u3000" }}
      <el-button type="primary" @click="searchBtn">搜索</el-button>
      <el-button size="small"
                 plain
                 icon="el-icon-back" @click="handleBack()">返回上一级
      </el-button>
    </div>
    <div>
      <div id="lineChart" :style="{width:'100%', height: '600px'}"></div>
      <div style="font-size: 20px; margin-top: 10px;"> 收缩压、舒张压表 </div>
      <avue-crud
          :option="option"
          :data="data"
          :page.sync="page"
          ref="crud"
          @row-del="rowDel"
          v-model="form"
          :permission="permissionList"
          @row-update="rowUpdate"
          @row-save="rowSave"
          :before-open="beforeOpen"
          @search-change="searchChange"
          @search-reset="searchReset"
          @refresh-change="refreshChange"
          @selection-change="selectionChange"
          @on-load="onLoad">
      </avue-crud>
    </div>
  </basic-container>
</template>

<script>

import {
  getDetail,
  submit,
  remove,
  getClassifyFree,
  getAvgByDayFree,
  getSerachList
} from '@/api/health/bpmeterdata'
// import {getWeek} from '@/api/sys/code'
import { mapGetters } from 'vuex'
import { bpDataValueNew } from '../../api/health/bpmeterdata'

export default {
  components: { },
  data () {
    return {
      orgOptions: {},
      form: {},
      dateValue: [],
      tabType: 1,
      picList: null,
      linList: null,
      params: {
        wxUserId: '',
        week: '',
        type: '1',
        jkStatus: '',
        beginDate: '',
        endDate: ''
      },
      bpTrend: [],
      weekDate: '',
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      free: '',
      freeOptions: {
        // 普通图片上传
        customConfig: {}, // wangEditor编辑的配置
        oss: 'ali',
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-shanghai',
          endpoint: 'oss-cn-shanghai.aliyuncs.com',
          accessKeyId: 'LTAI4GGYDGZjaZQyshkjPBJH',
          accessKeySecret: '5O9VCIRDa6AqHtVj2NDiMUO9Uv2GDe',
          bucket: 'health-planet'
        }
      },
      pay: '',
      payOptions: {
        // 普通图片上传
        action: 'https://avuejs.com/imgupload',
        customConfig: {}, // wangEditor编辑的配置
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-beijing',
          endpoint: 'oss-cn-beijing.aliyuncs.com',
          accessKeyId: '',
          accessKeySecret: '',
          bucket: 'avue'
        }
      },
      selectionList: [],
      option: {
        searchMenuSpan: 12,
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        align: 'center',
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '测量时间',
            prop: 'meterDate'
          },
          {
            label: '收缩压',
            prop: 'systolic'
          },
          {
            label: '舒张压',
            prop: 'diastolic'
          },
          {
            label: '数据类型',
            prop: 'type',
            type: 'select',
            dicData: [
              { label: '手动录入', value: 1 },
              { label: '手表录入', value: 2 },
              { label: '一体机', value: 3 },
              { label: '脉搏波血压计', value: 4 },
              { label: '大型蓝牙血压站', value: 5 },
              { label: '小型蓝牙血压计', value: 6 }
            ]
          },
          {
            label: '状态',
            prop: 'pressureLevel'
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.params.wxUserId = this.$route.query.wxUserId || '' // eslint-disable-line no-unused-vars
    this.params.week = this.$route.query.meterDate || '' // eslint-disable-line no-unused-vars
    this.params.jkStatus = this.$route.query.jkStatus || ''
    this.params.beginDate = this.$route.query.beginDate || ''
    this.params.endDate = this.$route.query.endDate || ''
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.health_bpmeterdata_add,
        viewBtn: this.permission.health_bpmeterdata_view,
        delBtn: this.permission.health_bpmeterdata_delete,
        editBtn: this.permission.health_bpmeterdata_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    drawLine (list) {
      const myChart = this.$echarts.init(document.getElementById('lineChart'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: '血压数据折现变化图',
          subtext: '单位:mmHg'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['收缩压', '舒张压']
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: { readOnly: false },
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: list.map(v => v.meterDate)
        },
        yAxis: {
          type: 'value',
          min: 40,
          axisLabel: {
            formatter: '{value} mmHg'
          }
        },
        series: [
          {
            name: '收缩压',
            type: 'line',
            data: list.map(v => v.avgSystolic),
            markPoint: {
              data: [
                { type: 'max', name: '最大值' },
                { type: 'min', name: '最小值' }
              ]
            },
            markLine: {
              data: [
                { type: 'average', name: '平均值' }
              ]
            }
          },
          {
            name: '舒张压',
            type: 'line',
            data: list.map(v => v.avgDiastolic),
            markPoint: {
              data: [
                { type: 'max', name: '最大值' },
                { type: 'min', name: '最小值' }
              ]
            },
            markLine: {
              data: [
                { type: 'average', name: '平均值' },
                [{
                  symbol: 'none',
                  x: '90%',
                  yAxis: 'max'
                }, {
                  symbol: 'circle',
                  label: {
                    position: 'start',
                    formatter: '最大值'
                  },
                  type: 'max',
                  name: '最高点'
                }]
              ]
            }
          }
        ]
      })
    },

    handleBack () {
      this.$router.back()
    },
    classifyfree (date) {
      getClassifyFree({ date: date, wxUserId: this.params.wxUserId }).then(res => {
        const data = res.data
        this.picList = data
        console.log(this.picList.vsumNormal)
      })
    },
    avgByDayFree (day) {
      getAvgByDayFree({ date: day, wxUserId: this.params.wxUserId }).then(res => {
        const data = res.data
        this.linList = data
        console.log(this.linList)
      })
    },

    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },

    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },

    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)

      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },

    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad () {
      bpDataValueNew(this.params).then(res => {
        this.drawLine(res.data)
      })
      getSerachList(this.page.currentPage, this.page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    searchBtn (data) {
      if (this.dateValue == null) {
        this.params.beginDate = ''
        this.params.endDate = ''
      } else {
        this.params.beginDate = this.dateValue[0] || ''
        this.params.endDate = this.dateValue[1] || ''
      }
      bpDataValueNew(this.params).then(res => {
        this.drawLine(res.data)
      })
      getSerachList(this.page.currentPage, this.page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>

<style>
.memberTit {
  color: #5a88d3;
  font-size: 16px;
  margin-bottom: 20px;
}

.memberTit::before {
  content: '';
  border-left: 6px solid #5a88d3;
  margin-right: 20px;
  background: #5a88d3;
}
</style>
