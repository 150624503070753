<template>
  <basic-container>
    <avue-crud :option="option"
               :data="data"
               :page.sync="page"
               ref="crud"
               @row-del="rowDel"
               v-model="form"
               :permission="permissionList"
               @row-update="rowUpdate"
               @row-save="rowSave"
               :before-open="beforeOpen"
               @search-change="searchChange"
               @search-reset="searchReset"
               @refresh-change="refreshChange"
               @selection-change="selectionChange"
               @on-load="onLoad">
      <template slot="menuLeft">
        <el-upload style="float: left" class="filter-item" :show-file-list="false" :action="handleImport()"
                   :headers="uploadHeaders()" :on-success="uploadSuc">
          <el-button type="primary" icon="upload">导入</el-button>
        </el-upload>
        <div style="float: left">
          <el-button id="files" size="small" type="primary" @click="downModule()">下载模版</el-button>
          <el-button type="danger"
                     size="small"
                     icon="el-icon-delete"
                     plain
                     v-if="permission.user_userconfigurevip_delete"
                     @click="handleDelete">删 除
          </el-button>
        </div>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getList, getDetail, submit, remove, moduleExcel } from '@/api/user/userconfigurevipNew'
import { mapGetters } from 'vuex'
import FileSaver from 'file-saver'
import { getToken } from '@/util/auth'
import { baseUrl } from '@/config'
export default {
  data () {
    return {
      fileList: [],
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        addBtn: false,
        editBtn: false,
        column: [
          /* {
            label: '身份证',
            prop: 'idCardNo',
          },*/
          {
            label: '用户名',
            prop: 'userName',
            search: true
          },
          {
            label: '手机号码',
            prop: 'mobile',
            search: true
          },
          {
            label: '会员充值类型',
            prop: 'vipType'
          },
          {
            label: '是否激活',
            prop: 'vipIsActivation'
          },
          {
            label: '年（增加的）',
            prop: 'vipYear'
          },
          {
            label: '月（增加的',
            prop: 'vipMonth'
          },
          {
            label: '日（增加的）',
            prop: 'vipDay'
          },
          {
            label: '方案动态调整次数',
            prop: 'vipAdjustCount'
          },
          {
            label: '会员开始时间',
            prop: 'startTime'
          },
          {
            label: '会员结束时间',
            prop: 'endTime'
          },
          {
            label: '导入时间',
            prop: 'createTime'
          },
          {
            label: '导入状态',
            prop: 'status',
            search: true,
            dicData: [
              { label: '未完成', value: 1 },
              { label: '已完成', value: 2 }
            ]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.user_userconfigurevip_add,
        viewBtn: this.permission.user_userconfigurevip_view,
        delBtn: this.permission.user_userconfigurevip_delete,
        editBtn: this.permission.user_userconfigurevip_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    // excle导入
    uploadHeaders () {
      return { token: getToken() }
    },
    handleImport () {
      return baseUrl + '/user/userConfigureVipNew/excel/save'
    },
    uploadSuc (response) {
      if (response.success) {
        this.$message({ message: '操作成功', type: 'success' })
        this.onLoad(this.page)
      } else {
        this.$message({ message: '操作失败', type: 'error' })
      }
    },
    // 模版
    downModule () {
      moduleExcel().then((response) => {
        console.log('csssss')
        console.log(response)
        FileSaver.saveAs(response, '导入模版' + '.xls')
      })
    },

    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = {}) {
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        console.log(data)
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>
