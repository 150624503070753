<template>
  <basic-container>
    <avue-crud
      :option="option"
      :data="data"
      :page.sync="page"
      ref="crud"
      @row-del="rowDel"
      v-model="form"
      :permission="permissionList"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @search-change="searchChange"
      @search-reset="searchReset"
      @refresh-change="refreshChange"
      @selection-change="selectionChange"
      @on-load="onLoad">
      <template slot="menuLeft">
        <!-- <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.qa_qa_delete"
          @click="handleDelete">删 除
        </el-button> -->
      </template>
      <!-- <template slot-scope="scope" slot="menu">
        <el-button type="text" icon="el-icon-s-operation" size="small" @click.native="toItem(scope.row)">内容编辑
        </el-button>
      </template> -->
    </avue-crud>
  </basic-container>
</template>

<script>
// import { getList, submit, remove, getDetail } from '@/api/sys/speech_template'
import { getList2, submit, remove } from '@/api/health/plan_details'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      ifshow: true,
      wxUserId: 0,
      schemeId: 0,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        addBtn: true,
        menuWidth: 300,
        column: [
          {
            label: '方案名称',
            prop: 'title',
            cell: true,
            width: 200
          },
          {
            type: 'select',
            label: '模板类型',
            prop: 'ssProvince',
            span: 24,
            hide: true,
            addDisplay: true,
            editDisabled: false,
            cascader: ['test'],
            dicUrl: 'https://dbadmin.dbzdjk.com/admin/fy/rest/third/mbTest',
            dicMethod: 'post',
            props: {
              label: 'name',
              value: 'code'
            }
            // search: true
          },
          {
            type: 'tree',
            label: '选择模板',
            prop: 'test',
            span: 24,
            hide: true,
            addDisplay: true,
            editDisabled: false,
            cascader: ['test1'],
            dicMethod: 'post',
            props: {
              label: 'name',
              value: 'code'
            },
            dicUrl: 'https://dbadmin.dbzdjk.com/admin/fy/rest/third/mbTest1?templateType={{key}}',
            slot: true,
            nodeClick: (data) => {
              this.form.details = data.code
            }
          },
          {
            label: '方案详情',
            prop: 'details',
            type: 'textarea',
            cell: true,
            width: 400,
            span: 24,
            rules: [{
              required: true,
              message: '请输入方案内容详情'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qa_add,
        viewBtn: this.permission.qa_qa_view,
        delBtn: this.permission.qa_qa_delete,
        editBtn: this.permission.qa_qa_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  created () {
    this.wxUserId = this.$route.query.wxUserId
    this.schemeId = this.$route.query.schemeId
    this.id = this.$route.query.id
  },
  methods: {
    toItem (row) {
      this.$router.push({
        path: '/sys/speech_template_details',
        query: {
          templateType: row.templateType,
          id: row.id,
          schemeId: this.schemeId
        }
      })
    },
    rowSave (row, done, loading) {
      row.wxUserId = this.wxUserId
      row.parentId = this.id
      row.schemeId = this.schemeId
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    // beforeOpen (done, type) {
    //   if (['edit', 'view'].includes(type)) {
    //     getDetail(this.form.id).then(res => {
    //       this.form = res.data
    //     })
    //   }
    //   done()
    // },
    onLoad (page, params = { parentId: this.id, wxUserId: this.wxUserId, schemeId: this.schemeId }) {
      getList2(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        console.log('测试')
        console.log(res)
      })
    }
  }
}
</script>

<style></style>
