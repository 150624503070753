<template>
  <basic-container>
    <avue-crud :option="option" :data="data" :page.sync="page" ref="crud" v-model="form"
      :permission="permissionList"
      @search-change="searchChange" @search-reset="searchReset" @refresh-change="refreshChange"
      @selection-change="selectionChange" @on-load="onLoad">
      <template slot="menuLeft">
        <!-- <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.qa_qa_delete"
          @click="handleDelete">删 除
        </el-button> -->
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button type="text" icon="el-icon-s-operation" size="small" @click.native="toItem(scope.row)">内容编辑
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
// import { getList, submit, remove, getDetail } from '@/api/sys/speech_template'
import { getList } from '@/api/health/plan_details'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      wxUserId: 0,
      schemeId: 0,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        addBtn: false,
        editBtn: false,
        addRowBtn: false,
        cellBtn: true,
        delBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        menuWidth: 300,
        column: [
          {
            label: '方案类名称',
            prop: 'title',
            cell: true,
            rules: [{
              required: true,
              message: '请输入模板名称'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            cell: false
          }
          // {
          //   label: '启用状态',
          //   prop: 'isUsing',
          //   cell: false,
          //   rules: [{
          //     required: true,
          //     message: '请选择启用状态'
          //   }],
          //   dicData: [
          //     { label: '使用中', value: 1 },
          //     { label: '没有使用', value: 0 }
          //   ]
          // },
          // {
          //   label: '模板类型',
          //   prop: 'templateType',
          //   cell: true,
          //   rules: [{
          //     required: true,
          //     message: '请选择模板类型'
          //   }]
          // }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qa_add,
        viewBtn: this.permission.qa_qa_view,
        delBtn: this.permission.qa_qa_delete,
        editBtn: this.permission.qa_qa_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  created () {
    this.wxUserId = this.$route.query.wxUserId
    this.schemeId = this.$route.query.id
  },
  methods: {
    toItem (row) {
      this.$router.push({
        path: '/health/health_plan_next',
        query: {
          wxUserId: this.wxUserId,
          id: row.id,
          schemeId: this.schemeId
        }
      })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    onLoad (page, params = { id: this.wxUserId }) {
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data
        console.log('测试')
        console.log(res)
        console.log(this.wxUserId)
      })
    }
  }
}
</script>

<style></style>
