<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        :before-open="beforeOpen"
        v-model="form"
        :permission="permissionList"
        :page.sync="page"
        @search-change="searchChange"
        @search-reset="searchReset"
        @on-load="onLoad">
      <template
          slot-scope="{row}"
          slot="roleId">
        <el-tag>{{ row.roleName }}</el-tag>
      </template>
      <template
          slot-scope="{row}"
          slot="deptId">
        <el-tag>{{ row.deptName }}</el-tag>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getAbnormalLogList, getAbnormalLogs } from '@/api/log/dataAbnormal_log'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      selectionList: [],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      params: {
        name: ''
      },
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        editBtn: false,
        addBtn: false,
        delBtn: false,
        menuWidth: 100,
        column: [
          {
            label: '姓名',
            prop: 'name',
            search: true
          },
          {
            label: '性别',
            prop: 'sex',
            dicData: [
              { label: '男', value: 1 },
              { label: '女', value: 2 }
            ]
          },
          {
            label: '年龄',
            prop: 'age'
          },
          {
            label: '电话',
            prop: 'mobile'
          },
          {
            label: '类型',
            prop: 'dataType'
          },
          {
            label: '数值',
            prop: 'dataValue'
          },
          {
            label: '原因',
            prop: 'reason'
          },
          {
            label: '描述',
            prop: 'detail'
          },
          {
            label: '时间',
            prop: 'updateTime'
          },
          {
            label: '短信提醒',
            prop: 'ifSend'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        viewBtn: this.vaildData(this.permission.data_abnormal_log_view, false)
      }
    }
  },
  methods: {
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params) {
      this.params.name = params.name
      this.onLoad(this.page, this.params)
      // console.log(this.params)
      // getAbnormalLogLikeList(this.page.currentPage, this.page.pageSize, this.params).then(res => {
      //   console.log(this.params)
      //   const data = res.data
      //   this.page.total = data.total
      //   this.data = data.records
      // })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getAbnormalLogs(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = {}) {
      getAbnormalLogList(page.currentPage, page.pageSize, params).then(res => {
        console.log(params)
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>

<style>
</style>
