import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/health/bsmeterdataitem/getList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/health/bsmeterdata/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/health/bsmeterdata/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/health/bsmeterdata/submit',
    method: 'post',
    data: row
  })
}

export const getSerachList = (current, size, params) => {
  return request({
    url: '/health/bsmeterdataitem/serachList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const submitForm = (row) => {
  return request({
    url: '/health/bsmeterdataitem/submitFormS',
    method: 'post',
    data: row
  })
}

export const bsZcReport = (row) => {
  return request({
    url: '/health/bsmeterdataitem/bsZcReport',
    method: 'post',
    data: row
  })
}

