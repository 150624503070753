import { request } from '@/util/http'

export const getAbnormalLogList = (current, size) => {
  return request({
    url: '/log/dataabnormallog/list',
    method: 'get',
    params: {
      current,
      size
    }
  })
}

export const getAbnormalLogLikeList = (current, size) => {
  return request({
    url: '/log/dataabnormallog/listLike',
    method: 'get',
    params: {
      current,
      size
    }
  })
}

export const getAbnormalLogs = (id) => {
  return request({
    url: '/log/dataabnormallog/detail',
    method: 'get',
    params: {
      id
    }
  })
}

