import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/health/archive/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getListDeatils1 = (current, size, params) => {
  return request({
    url: '/health/archive/getList1',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getListDeatils2 = (current, size, params) => {
  return request({
    url: '/health/archive/getList2',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const remove1 = (ids) => {
  return request({
    url: '/health/archive/remove1',
    method: 'post',
    params: {
      ids
    }
  })
}

export const remove2 = (ids) => {
  return request({
    url: '/health/archive/remove2',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit01 = (row) => {
  return request({
    url: '/health/archive/submit01',
    method: 'post',
    data: row
  })
}

export const submit02 = (row) => {
  return request({
    url: '/health/archive/submit02',
    method: 'post',
    data: row
  })
}

export const submit1 = (params) => {
  return request({
    url: '/health/archive/submit1',
    method: 'post',
    params: {
      ...params
    }
  })
}

export const submit2 = (params) => {
  return request({
    url: '/health/archive/submit2',
    method: 'post',
    params: {
      ...params
    }
  })
}

