<template>
  <div>
    <basic-container>
      <avue-data-tabs :option="option"></avue-data-tabs>

      <avue-data-icons :option="option2"></avue-data-icons>
    </basic-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Index',
  data () {
    return {
      option: {
        data: [
          {
            click: function (item) {
              alert('开发中')
            },
            title: '分类统计',
            subtitle: '实时',
            count: 7993,
            allcount: 10222,
            text: '当前分类总记录数',
            color: 'rgb(27, 201, 142)',
            key: '类'
          },
          {
            click: function (item) {
              alert('开发中')
            },
            title: '附件统计',
            subtitle: '实时',
            count: 3112,
            allcount: 10222,
            text: '当前上传的附件数',
            color: 'rgb(230, 71, 88)',
            key: '附'
          },
          {
            click: function (item) {
              alert('开发中')
            },
            title: '文章统计',
            subtitle: '实时',
            count: 908,
            allcount: 10222,
            text: '评论次数',
            color: 'rgb(178, 159, 255)',
            key: '评'
          }
        ]
      },
      option2: {
        span: 4,
        data: [
          {
            click: function (item) {
              alert('开发中')
            },
            title: '今日注册',
            count: 12678,
            decimals: 2,
            icon: 'el-icon-tickets'
          },
          {
            click: function (item) {
              alert('开发中')
            },
            title: '今日登录',
            count: 22139,
            icon: 'el-icon-success'
          },
          {
            click: function (item) {
              alert('开发中')
            },
            title: '今日订阅',
            count: 35623,
            icon: 'el-icon-info'
          },
          {
            click: function (item) {
              alert('开发中')
            },
            title: '今日评论',
            count: 16826,
            icon: 'el-icon-message'
          },
          {
            click: function (item) {
              alert('开发中')
            },
            title: '今日评论',
            count: 16826,
            icon: 'el-icon-message'
          },
          {
            click: function (item) {
              alert('开发中')
            },
            title: '今日评论',
            count: 16826,
            icon: 'el-icon-message'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created () {
  },
  methods: {}
}
</script>

<style scoped="scoped" lang="scss">
</style>
