import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/health/ComplianceStatistics/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

// export const getDetail = (id) => {
//   return request({
//     url: '/health/bpreport/detail',
//     method: 'get',
//     params: {
//       id
//     }
//   })
// }

// export const remove = (ids) => {
//   return request({
//     url: '/health/bpreport/remove',
//     method: 'post',
//     params: {
//       ids
//     }
//   })
// }

// export const submit = (row) => {
//   return request({
//     url: '/health/bpreport/submit',
//     method: 'post',
//     data: row
//   })
// }

