<template>
  <div class="login-container">
    <div class="login-weaper animated bounceInDown">
      <div class="login-left">
        <div class="login-time">
          {{ time }}
        </div>
        <img
            class="img"
            src="~@/assets/img/logo.png"
            alt="">
        <p class="title">欢迎登录</p>
      </div>
      <div class="login-border">
        <div class="login-main">
          <h4 class="login-title">
            {{title}}
            <span><i class="icon-tuichu" @click="logout"></i></span>
          </h4>

          <selectInstitution1></selectInstitution1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import selectInstitution1 from './selectInstitution1'
import { mapGetters } from 'vuex'
import { dateFormat } from '@/util/date'

export default {
  name: 'Login',
  components: {
    selectInstitution1
  },
  data () {
    return {
      orgOptions: {},
      time: '',
      title: '请先选择渠道'
    }
  },
  created () {
    this.getTime()
    setInterval(() => {
      this.getTime()
    }, 1000)
  },
  mounted () {

  },
  computed: {
    ...mapGetters([])
  },
  props: [],
  methods: {
    getTime () {
      this.time = dateFormat(new Date())
    },
    logout () {
      this.$confirm('退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          window.localStorage.clear()
          this.$router.push({ path: '/login' })
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/login.scss";
</style>
