<template>
  <basic-container>
    <avue-crud :option="option" :data="data" :page.sync="page" ref="crud" @row-del="rowDel" v-model="form"
      :permission="permissionList" @row-update="rowUpdate" @row-save="rowSave" :before-open="beforeOpen"
      @search-change="searchChange" @search-reset="searchReset" @refresh-change="refreshChange"
      @selection-change="selectionChange" @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.qa_qa_delete"
          @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button type="text" icon="el-icon-s-operation" size="small" @click.native="toItem(scope.row)">内容编辑
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getList, submit, remove, getDetail } from '@/api/sys/speech_template'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        addBtn: false,
        editBtn: false,
        addRowBtn: true,
        cellBtn: true,
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        menuWidth: 300,
        column: [
          {
            label: '模板类型名称',
            prop: 'templateName',
            cell: true,
            rules: [{
              required: true,
              message: '请输入模板名称'
            }]
          },
          {
            label: '启用状态',
            prop: 'isUsing',
            cell: false,
            rules: [{
              required: true,
              message: '请选择启用状态'
            }],
            dicData: [
              { label: '使用中', value: 1 },
              { label: '没有使用', value: 0 }
            ]
          },
          {
            label: '模板类型',
            prop: 'templateType',
            cell: true,
            rules: [{
              required: true,
              message: '请选择模板类型'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            cell: false
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qa_add,
        viewBtn: this.permission.qa_qa_view,
        delBtn: this.permission.qa_qa_delete,
        editBtn: this.permission.qa_qa_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    toItem (row) {
      this.$router.push({
        path: '/sys/speech_template_details',
        query: {
          templateType: row.templateType,
          id: row.id
        }
      })
    },
    rowSave (row, done, loading) {
      row.isUsing = '1'
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = {}) {
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        console.log('测试')
        console.log(res)
      })
    }
  }
}
</script>

<style></style>
