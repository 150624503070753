<template>
  <!--  <basic-container>-->
  <!--    <el-container style="height: 100vh; border: 1px solid #eee">-->
  <!--      <el-container style="background: #fff;padding: 20px; overflow-y: auto">-->
  <!--        <avue-form ref="form" v-model="drugForm" :option="drugOption" @submit="submit">-->
  <!--          <template slot="recordTimeS">-->
  <!--            <el-date-picker-->
  <!--                v-model="drugForm.recordTimeS"-->
  <!--                type="datetime"-->
  <!--                placeholder="选择日期时间">-->
  <!--            </el-date-picker>-->
  <!--          </template>-->
  <!--          <template slot-scope="scope" slot="menuForm">-->
  <!--            <el-button @click="addGroup">添加用药方案</el-button>-->
  <!--          </template>-->
  <!--        </avue-form>-->
  <!--      </el-container>-->
  <!--    </el-container>-->
  <!--  </basic-container>-->

  <basic-container>
    <el-container style="border: 1px solid #eee">
      <el-container style="background: #fff;padding: 20px; overflow-y: auto">
        <el-collapse v-model="activeName" accordion style="width: 100%">
          <el-collapse-item :name="index" v-for="(item,index) in formData" :key="index">
            <template slot="title">
              {{ '用药方案调整' +(index+1)}}  <i style="color: #e4393c;margin-left: 20px;" v-if="formData.length>1" @click.prevent="delItem(index)" class="header-icon el-icon-delete"></i>
            </template>
            <el-form :model="item"  label-width="100px" class="demo-dynamic">
              <el-form-item  label="选择日期时间">
                <el-row :gutter="20">
                  <el-col :span="16">
                    <el-date-picker
                        v-model="item.recordTimeS"
                        type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item v-for="(domain, childIndex) in item.initialPlanList"
                            :key="childIndex"
                            :label="'用药方案'+ (childIndex+1)"
              >
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-input
                        placeholder="请填写化学名"
                        v-model="domain.chemical">
                    </el-input>
                  </el-col>

                  <el-col :span="3">
                    <el-input
                        placeholder="请填写商品名"
                        v-model="domain.product">
                    </el-input>
                  </el-col>
                  <el-col :span="3">
                    <el-input
                        placeholder="请填写规格"
                        v-model="domain.specifications">
                    </el-input>
                  </el-col>
                  <el-col :span="3">
                    <el-input
                        placeholder="请填写单次剂量"
                        v-model="domain.singleDose">
                    </el-input>
                  </el-col>
                  <el-col :span="3">
                    <el-input
                        placeholder="请填写服用频次"
                        v-model="domain.takingFrequency">
                    </el-input>
                  </el-col>
                  <el-col :span="3">
                    <el-button-group style="margin-bottom:20px">
                    <el-button  type="danger"  @click.prevent="removeDomain(index,domain)">删除</el-button>
                    <el-button type="warning"  @click="addDomain(index)">新增</el-button>
                    </el-button-group>
                  </el-col>
                </el-row>
              </el-form-item>

            </el-form>
          </el-collapse-item>
        </el-collapse>
      </el-container>
    </el-container>
    <el-button-group style="margin: 30px auto;display: flex;align-items: center;justify-content: center;">
      <el-button type="primary" @click="addItem">新增</el-button>
      <el-button type="primary" @click="submitForm">提交</el-button>
    </el-button-group>
  </basic-container>
</template>

<script>
import { getCommonDetail, getCommonSubmit } from '@/api/health/drugplan'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      data: [],
      drugForm: {},
      activeName: '',
      formData: [
        {
          label: '用药方案调整',
          recordTimeS: '',
          initialPlanList: [
            {
              chemical: '',
              product: '',
              specifications: '',
              singleDose: '',
              takingFrequency: ''
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['permission']),
    drugOption () {
      return {
        size: this.sizeValue,
        submitText: '录入',
        emptyBtn: false
      }
    }
  },
  created () {
    this.drugForm.name = this.$route.query.jkName
    this.drugForm.age = this.$route.query.jkAge
    this.drugForm.sex = this.$route.query.jkSex
    this.drugForm.wxUserId = this.$route.query.wxUserId
    this.onLoad()
  },
  methods: {
    // 删除元素
    delItem (index) {
      this.formData.splice(index, 1)
    },

    // 添加元素
    addItem (type) {
      this.formData.push({
        label: '用药方案调整',
        recordTimeS: '',
        initialPlanList: [
          {
            chemical: '',
            product: '',
            specifications: '',
            singleDose: '',
            takingFrequency: ''
          }
        ]
      })
    },

    removeDomain (index, item) {
      const childIndex = this.formData[index].initialPlanList.indexOf(item)
      if (childIndex !== -1) {
        this.formData[index].initialPlanList.splice(childIndex, 1)
      }
    },

    addDomain (index) {
      this.formData[index].initialPlanList.push({

        chemical: '',
        product: '',
        specifications: '',
        singleDose: '',
        takingFrequency: ''

      })
    },

    submitForm (formName) {
      this.drugForm.sfjh = this.formData
      this.drugForm.wxUserId = this.$route.query.wxUserId
      console.log(this.drugForm)
      getCommonSubmit(this.drugForm).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
      })
    },

    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    onLoad () {
      getCommonDetail(this.drugForm.wxUserId).then(res => {
        if (res.data.sfjh.length) {
          this.formData = []
          this.formData = res.data.sfjh
        }
      })
    }
  }
}
</script>
