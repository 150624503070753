<template>
  <basic-container>
    <avue-crud :option="option"
               :data="data"
               ref="crud"
               v-model="form"
               :permission="permissionList"
               @row-update="rowUpdate"
               @row-save="rowSave"
               :before-open="beforeOpen"
               :page.sync="page"
               @refresh-change="refreshChange"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @on-load="onLoad">
      <template slot="scoreForm">
        <div>
          <el-card shadow="hover" class="box-card" v-for="(item,index) in form.scoreItems" :key="index">
            <div class="clearfix">
              <span>{{ index + 1 + '. ' + item.name }}</span>
              <el-button style="float: right; padding: 3px 0" type="text">分数：{{ item.score }}
              </el-button>
            </div>
          </el-card>
        </div>
      </template>
      <template slot="cardForm">
        <div>
          <el-card shadow="hover" class="box-card" v-for="(item,index) in form.itemList" :key="index">
            <div slot="header" class="clearfix">
              <span>{{ index + 1 + '. ' + item.itemContent }}<span
                  style="padding-left: 10px;color: #e4393c;">{{
                  '(' + (item.isRequire ? '必填' : '非必填') + ')'
                }}</span></span>
              <el-button style="float: right; padding: 3px 0" type="text">正确答案：{{ item.solution }}
              </el-button>
            </div>
            <div v-if="item.itemType == 1">
              <div v-for="(child,i) in item.itemOptions" :key="i" :class="{ itemActive: child.checked}">
                {{ child.optionKey + '. ' + child.optionContent }}（分值：{{ child.optionVal }}）
              </div>
            </div>
            <div v-if="item.itemType == 2">
              <div>
                {{ item.answer }}
              </div>
            </div>
            <div v-if="item.itemType == 3">
              <div>
                {{ item.answer }}
              </div>
            </div>
          </el-card>
        </div>
      </template>
      <template slot="menuForm">
        <el-button type="primary" icon="el-icon-check" size="small" plain @click="$refs.crud.rowUpdate()">提交评估
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button type="text" icon="el-icon-s-operation" size="small" @click.native="toItem(scope.row)">评估报表
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button type="text" size="small" @click="information(scope.row)">
          基本信息
        </el-button>
        <!--        <el-button type="text" size="small" @click="bpQaAnswer(scope.row)">-->
        <!--          病史采集表-->
        <!--        </el-button>-->
        <!--        <el-button type="text" size="small" @click="historyTaking(scope.row)">-->
        <!--          病史采集-->
        <!--        </el-button>-->
        <el-button type="text" size="small" @click="commondrugplan(scope.row)">
          用药方案
        </el-button>
        <el-button type="text" size="small"
                   @click.stop="handleDetail(scope.row,scope.index)">
          血压查询
        </el-button>
        <!--        <el-button type="text" size="small"-->
        <!--                   @click.stop="rechargeConsume(scope.row,scope.index)">-->
        <!--          VIP充值消费-->
        <!--        </el-button>-->
        <el-button type="text" size="small"
                   @click.stop="bloodSugarDetail(scope.row,scope.index)">
          血糖查询
        </el-button>
      </template>

    </avue-crud>
  </basic-container>
</template>

<script>
import {getCommonList, submit, remove, enable, disable} from '@/api/health/userjk'
import {getCommonuserjkDetail, submitCommonuserjk} from '@/api/qa/qaanswer'
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        columnBtn: false,
        refreshBtn: false,
        saveBtn: false,
        updateBtn: false,
        cancelBtn: true,
        addBtn: false,
        editBtn: true,
        editBtnText: '高血压病史采集表',
        delBtn: false,
        selection: true,
        column: [
          {
            label: '试题',
            prop: 'qaId',
            detail: true,
            hide: true,
            type: 'select',
            dicUrl: 'qa/qa/all',
            props: {
              value: 'id',
              label: 'name'
            }
          },
          {
            label: '姓名',
            prop: 'name',
            detail: true,
            search: true
          },
          {
            label: '性别',
            prop: 'sex',
            type: 'select',
            dicUrl: '/sys/dict/code/sex',
            detail: true
          },
          {
            label: '联系方式',
            prop: 'mobile',
            search: true,
            detail: true
          },
          {
            label: '会员到期时间',
            prop: 'vipExpired',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '报告解读次数',
            prop: 'vipReportValue'
          },
          {
            label: '血压解读次数',
            prop: 'vipBpValue'
          },
          {
            label: '血糖解读次数',
            prop: 'vipBsValue'
          },
          {
            label: '阳性项',
            prop: 'yxxCount',
            detail: true,
            hide: true
          },
          {
            label: '阳性项和',
            prop: 'yxxVal',
            detail: true,
            hide: true
          },
          {
            label: '状态',
            prop: 'status',
            detail: true,
            hide: true
          },
          {
            label: '评估',
            prop: 'assess',
            type: 'textarea',
            hide: true,
            span: 24
          },
          {
            label: '计分项',
            prop: 'score',
            formslot: true,
            hide: true,
            span: 24
          },
          {
            label: '评估项',
            prop: 'card',
            formslot: true,
            hide: true,
            span: 24
          },
          {
            label: '提交日期',
            prop: 'updateTime',
            type: 'date',
            hide: true,
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            addDisplay: false,
            editDisplay: false
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList() {
      return {}
    },
    ids() {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    rowUpdate(row, index, done, loading) {
      submitCommonuserjk(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowSave(row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    // rowUpdate(row, index, done, loading) {
    //   submit(row).then(() => {
    //     done()
    //     this.onLoad(this.page)
    //     this.$message({
    //       type: 'success',
    //       message: '操作成功!'
    //     })
    //   }).catch(() => {
    //     loading()
    //   })
    // },
    // rowDel (row) {
    //   this.$confirm('确定将选择数据删除?', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //       .then(() => {
    //         return remove(row.id)
    //       })
    //       .then(() => {
    //         this.onLoad(this.page)
    //         this.$message({
    //           type: 'success',
    //           message: '操作成功!'
    //         })
    //       })
    // },
    searchReset() {
      this.onLoad(this.page)
    },
    searchChange(params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange(list) {
      this.selectionList = list
    },
    refreshChange(data) {
      this.onLoad(this.page, data.searchForm)
    },
    // handleDelete () {
    //   if (this.selectionList.length === 0) {
    //     this.$message.warning('请选择至少一条数据')
    //     return
    //   }
    //   this.$confirm('确定将选择数据删除?', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //       .then(() => {
    //         return remove(this.ids)
    //       })
    //       .then(() => {
    //         this.onLoad(this.page)
    //         this.$message({
    //           type: 'success',
    //           message: '操作成功!'
    //         })
    //         this.$refs.crud.toggleSelection()
    //       })
    // },
    handleEnable() {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定对该用户启用VIP嘛?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            return enable(this.ids)
          })
          .then(() => {
            this.onLoad(this.page)
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.$refs.crud.toggleSelection()
          })
    },
    handleDisable() {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定对该用户停用VIP嘛?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            return disable(this.ids)
          })
          .then(() => {
            this.onLoad(this.page)
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.$refs.crud.toggleSelection()
          })
    },
    beforeOpen(done, type) {
      if (['edit', 'view'].includes(type)) {
        getCommonuserjkDetail(this.form.wxUserId).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad(page, params = {}) {
      getCommonList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    handleDetail(data) {
      this.$router.push({
        path: '/health/bpmeterdata_value',
        query: {wxUserId: data.wxUserId}
      })
    },
    // rechargeConsume (data) {
    //   this.$router.push({
    //     path: '/health/rechargeconsume',
    //     query: { wxUserId: data.wxUserId }
    //   })
    // },
    bloodSugarDetail(data) {
      this.$router.push({
        path: '/health/bsmeterdata_value',
        query: {wxUserId: data.wxUserId}
      })
    },
    information(row) {
      console.log(row)
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex,
        weight: row.weight,
        height: row.height
      }
      this.$router.push({
        path: '/health/cuinformation',
        query: parameter
      })
    },
    bpQaAnswer(row) {
      console.log(row)
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/historytaking',
        query: parameter
      })
    },
    historyTaking(row) {
      console.log(row)
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/historytaking',
        query: parameter
      })
    },
    commondrugplan(row) {
      console.log(row)
      const parameter = {
        wxUserId: row.wxUserId,
        jkName: row.name,
        jkAge: row.age,
        jkSex: row.sex
      }
      this.$router.push({
        path: '/health/commondrugplan',
        query: parameter
      })
    },
  }
}
</script>
