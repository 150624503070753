<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<template>
  <div>
    <img src="https://health-planet.oss-cn-shanghai.aliyuncs.com/report/2024-08-20/f9a3275a53abc34fb5283436d25f79a068b1.png" class="wximg" />
  </div>
</template>
<script>

import {
  getList
} from '@/api/health/test'
import { request } from '@/util/http'

export default {
  name: 'kindex',
  data () {
    return {
      requestHeadUrl: 'https://dbadmin.dbzdjk.com',
      appLink: ''
    }
  },
  created () {
    const thisObj = this
    thisObj.onLoad()
  },
  methods: {
    onLoad: function () {
      const thisObj = this
    },
  }
}
</script>

<style>
.btn {
    padding: 12px;
    width: 200px;
    height: 50px;
}
.wximg {
  position: fixed;
  width: 20%;
  left: 40%;
  top: 25%;
}
.gotext {
  position: fixed;
  bottom: 20%;
  left: 30%;
  width: 40%;
  height: 80px;
  background: rgb(40, 196, 69);
  color: #ffffff;
  text-align: center;
  font-size: 35px;
  padding: 15px;
  border-radius: 15px;
}
</style>
