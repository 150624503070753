import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/health/report/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/health/report/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const pdf = (path) => {
  return request({
    url: '/health/report/pdf',
    method: 'get',
    params: {
      path
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/health/report/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/health/report/submit',
    method: 'post',
    data: row
  })
}

export const report = (row) => {
  return request({
    url: '/health/report/report',
    method: 'post',
    data: row
  })
}

export const reportVip = (row) => {
  return request({
    url: '/health/report/reportVip',
    method: 'post',
    data: row
  })
}

