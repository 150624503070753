import { request } from '@/util/http'

export const getList = (type) => {
  return request({
    url: '/health/doctorHospital/list',
    method: 'get',
    params: {
      type
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/health/doctorHospital/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/health/doctorHospital/submit',
    method: 'post',
    data: row
  })
}

export const remove = (ids) => {
  return request({
    url: '/health/doctorHospital/remove',
    method: 'post',
    params: {
      ids
    }
  })
}
