<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @refresh-change="refreshChange"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.role_delete"
            plain
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template
          slot-scope="{row}"
          slot="roleId">
        <el-tag>{{ row.roleName }}</el-tag>
      </template>
      <template
          slot-scope="{row}"
          slot="deptId">
        <el-tag>{{ row.deptName }}</el-tag>
      </template>

      <template slot-scope="{row}" slot="menu">
        <el-button
            size="small"
            type="text"
            @click="handleRole(row)">权限
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
        title="提示"
        :visible.sync="box"
        width="40%">
      <el-tree
          :data="list"
          show-checkbox
          node-key="id"
          ref="tree"
          :default-checked-keys="defaultObj"
          :props="props">
      </el-tree>
      <span
          slot="footer"
          class="dialog-footer">
        <el-button @click="box = false">取 消</el-button>
        <el-button
            type="primary"
            @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import { add, getByRole, getList, grant, remove, update } from '@/api/sys/role'
import { tree } from '@/api/sys/menu'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      box: false,
      props: {
        label: 'name',
        value: 'id'
      },
      list: [],
      defaultObj: [],
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        border: true,
        index: true,
        selection: true,
        viewBtn: true,
        column: [
          {
            label: '角色名称',
            prop: 'name',
            search: true,
            span: 24,
            rules: [
              {
                required: true,
                message: '请输入角色名称'
              }
            ]
          },
          {
            label: '角色编码',
            prop: 'code',
            search: true,
            span: 24,
            rules: [
              {
                required: true,
                message: '请输入角色编码'
              }
            ]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_role_add, false),
        viewBtn: this.vaildData(this.permission.sys_role_view, false),
        delBtn: this.vaildData(this.permission.sys_role_delete, false),
        editBtn: this.vaildData(this.permission.sys_role_edit, false)
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    submit () {
      const menuList = this.$refs.tree.getCheckedKeys().join(',')
      grant(this.form.id, menuList).then(() => {
        this.box = false
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.onLoad(this.page)
      })
    },
    rowSave (row, done) {
      add(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }, () => {
        done()
      })
    },
    rowUpdate (row, index, done) {
      update(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }, () => {
        done()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(row.id).then(() => {
          this.onLoad(this.page)
        })
      })
    },
    handleDelete () {
      const _this = this
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return remove(this.ids).then(() => {
          _this.onLoad(this.page)
          _this.$refs.crud.toggleSelection()
        })
      })
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      done()
      this.onLoad(this.page, params)
    },
    selectionChange (list) {
      this.selectionList = list
    },
    handleRole (row) {
      // 修复点击修改权限，已选中项的bug
      this.defaultObj = []
      this.form.id = row.id
      tree()
        .then(res => {
          this.list = res.data
          return getByRole(row.id)
        })
        .then(res => {
          this.defaultObj = res.data
          this.box = true
        })
    },
    onLoad (page, params = {}) {
      getList(page.currentPage, page.pageSize, params).then(res => {
        this.data = res.data.records
      })
    }
  }
}
</script>

<style>
</style>
