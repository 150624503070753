<template>
  <basic-container>
    <avue-crud
        v-loading="loading"
        element-loading-text="数据加载中--首次加载时间会很长"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :option="option"
        :data="data"
        :page.sync="page"
        @on-load="onLoad"
        :cell-class-name="addClass">
        <template slot="menuLeft">
          <el-button type="primary"
                     size="small"
                     icon="el-icon-check"
                     plain
                     @click="resetData">重置数据
          </el-button>
        </template>
      <template slot-scope="scope" slot="menu">
        <el-button type="text" size="small"
                   @click.stop="handleDetail(scope.row,scope.index)">评估报告
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getBpWeekList, removeBpWeekList } from '@/api/wx/wxuser'
import { mapGetters } from 'vuex'
import func from '@/util/func'

export default {
  data () {
    return {
      loading: false,
      form: {},
      count: 0,
      page: {
        pageSize: 1000000,
        currentPage: 1,
        total: 0
      },
      params: {
        meterDate: func.format(new Date(), 'YYYY-mm-dd'),
        bpReportStatus: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        selection: false,
        align: 'center',
        column: [
          {
            label: '测量时间',
            prop: 'meterDate',
            type: 'week',
            format: 'yyyy 第 WW 周',
            valueFormat: 'yyyy-MM-dd',
            hide: true,
            search: true,
            searchValue: func.format(new Date(), 'YYYY-mm-dd'),
            change: ({ value }) => {
              this.params.meterDate = value
              if (this.params.meterDate === undefined) {
                return
              }
              console.log(this.count)
              if (this.count !== 0) {
                this.onLoad(this.page)
              }
              if (this.count === 0) {
                this.count = this.count + 1
              }
              console.log(this.count)
            }
          },
          {
            label: '姓名',
            prop: 'username',
            detail: true,
            search: true
          },
          {
            label: '年龄',
            prop: 'age',
            detail: true
          },
          {
            label: '性别',
            prop: 'sex',
            type: 'select',
            dicUrl: '/sys/dict/code/sex',
            detail: true
          },
          {
            label: '状态',
            prop: 'reportStatus',
            type: 'select'
          },
          {
            label: 'VIP等级',
            prop: 'vipLevel',
            type: 'select',
            dicData: [
              { label: '普通用户', value: 0 },
              { label: '基础会员', value: 1 },
              { label: '体验会员', value: 2 },
              { label: '全年会员', value: 3 },
              { label: 'vip会员', value: 6 }
            ]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.wx_user_add,
        viewBtn: this.permission.wx_user_view,
        delBtn: this.permission.wx_user_delete,
        editBtn: this.permission.wx_user_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  // created () {
  //   // 实时检测刷新token
  //   setStore('token', getStore('token') + 'v')
  // },
  methods: {
    addClass ({ row, column, rowIndex, columnIndex }) {
      // console.log(row)
      // console.log(column)
      // console.log(rowIndex)
      // console.log(columnIndex)
      // if(columnIndex === 4){
      //   return 'cell-blue'
      // }
    },
    getRowKey (row) {
      console.log(row.id, '====================')
      return row.id
    },
    handleDetail (data) {
      this.$router.push({
        path: '/health/bpmeterdata_detail',
        query: { wxUserId: data.wxUserId, meterDate: this.params.meterDate }
      })
    },
    resetData () {
      // if (this.selectionList.length === 0) {
      //   this.$message.warning('请选择至少一条数据')
      //   return
      // }
      this.$confirm('确定将这周数据进行重置吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          console.log('test  重置111')
          console.log(this.params)
          return removeBpWeekList(this.params)
        })
        .then(() => {
          console.log('test  重置222')
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    selectionChange (list) {
      this.selectionList = list
    },
    onLoad (page) {
      this.params.type = 1
      this.params.channel = window.localStorage.getItem('channel')
      this.loading = true
      getBpWeekList(page.currentPage, page.pageSize, this.params).then(res => {
        this.loading = false
        console.log('测试实施')
        console.log(res)
        console.log(res.data)
        if (res.msg === '暂无承载数据') {
          this.page.total = null
          this.data = null
        } else {
          const data = res.data
          this.page.total = data.total
          this.data = data.records
        }
      })
    }
  }
}
</script>

<style>
    .cell-blue{
        background-color:red!important;
        /* color: red!important; */
        /* cursor: pointer; */
    }
</style>
