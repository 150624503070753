<template>
  <basic-container>
    <div style="font-size: 20px; margin-top: 30px; margin-bottom: 10px;"> 复查指标 </div>
    <avue-crud :option="option" :data="data" :page.sync="page" ref="crud" v-model="form"
      :permission="permissionList"
      @search-change="searchChange" @search-reset="searchReset" @refresh-change="refreshChange"
      @selection-change="selectionChange" @on-load="onLoad" :before-open="beforeOpen"
      @row-update="rowUpdate" @row-save="rowSave" @row-del="rowDel">
    </avue-crud>
    <div style="font-size: 20px; margin-top: 30px; margin-bottom: 10px;"> 专病检查项目 </div>
    <avue-crud :option="option1" :data="data1" :page.sync="page1" ref="crud" v-model="form1"
      :permission="permissionList"
      @search-change="searchChange" @search-reset="searchReset" @refresh-change="refreshChange"
      @selection-change="selectionChange" @on-load="onLoad" :before-open="beforeOpen"
      @row-update="rowUpdate" @row-save="rowSave1" @row-del="rowDel">
    </avue-crud>
    <div style="font-size: 20px; margin-top: 30px; margin-bottom: 10px;"> 复查项目 </div>
    <avue-crud :option="option2" :data="data2" :page.sync="page2" ref="crud" v-model="form2"
      :permission="permissionList"
      @search-change="searchChange" @search-reset="searchReset" @refresh-change="refreshChange"
      @selection-change="selectionChange" @on-load="onLoad" :before-open="beforeOpen"
      @row-update="rowUpdate" @row-save="rowSave2" @row-del="rowDel">
    </avue-crud>
  </basic-container>
</template>

<script>
import { getList, getList1, getList2, submit, remove, getDetail } from '@/api/health/followUpPlanNew'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      form1: {},
      form2: {},
      wxUserId: 0,
      schemeId: 0,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      page1: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      page2: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        addBtn: true,
        menuWidth: 300,
        column: [
          {
            label: '项目',
            prop: 'project',
            cell: true,
            span: 24,
            rules: [{
              required: true,
              message: '请输入项目'
            }]
          },
          {
            label: '结果',
            prop: 'result',
            cell: true,
            span: 24,
            rules: [{
              required: true,
              message: '请输入结果'
            }]
          },
          {
            label: '参考值',
            prop: 'rtValue',
            cell: true,
            span: 24,
            rules: [{
              required: true,
              message: '请输入参考值'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          }
        ]
      },
      option1: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        addBtn: true,
        menuWidth: 300,
        column: [
          {
            label: '系统',
            prop: 'system',
            type: 'select',
            cell: true,
            span: 24,
            dicData: [
              {
                label: '循环系统',
                value: '循环系统'
              },
              {
                label: '呼吸系统',
                value: '呼吸系统'
              },
              {
                label: '消化系统',
                value: '消化系统'
              },
              {
                label: '代谢内分泌系统',
                value: '代谢内分泌系统'
              },
              {
                label: '泌尿生殖系统',
                value: '泌尿生殖系统'
              },
              {
                label: '血液肿瘤系统',
                value: '血液肿瘤系统'
              },
              {
                label: '运动系统',
                value: '运动系统'
              },
              {
                label: '神经系统',
                value: '神经系统'
              },
              {
                label: '情绪睡眠',
                value: '情绪睡眠'
              }
            ],
            rules: [{
              required: true,
              message: '请输入系统'
            }]
          },
          {
            label: '项目',
            prop: 'project',
            cell: true,
            span: 24,
            rules: [{
              required: true,
              message: '请输入项目'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          }
        ]
      },
      option2: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        addBtn: true,
        menuWidth: 300,
        column: [
          {
            label: '项目',
            prop: 'project',
            cell: true,
            span: 24,
            rules: [{
              required: true,
              message: '请输入项目'
            }]
          },
          {
            label: '频次',
            prop: 'frequency',
            cell: true,
            span: 24,
            rules: [{
              required: true,
              message: '请输入频次'
            }]
          },
          {
            label: '复查时间',
            prop: 'reviewTime',
            cell: true,
            span: 24,
            rules: [{
              required: true,
              message: '请输入复查时间'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            addDisplay: false,
            editDisabled: true,
            span: 24,
            cell: false
          }
        ]
      },
      data: [],
      data1: [],
      data2: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qa_add,
        viewBtn: this.permission.qa_qa_view,
        delBtn: this.permission.qa_qa_delete,
        editBtn: this.permission.qa_qa_edit
      }
    }
  },
  created () {
    this.wxUserId = this.$route.query.wxUserId
    this.schemeId = this.$route.query.id
  },
  methods: {
    rowSave (row, done, loading) {
      row.wxUserId = this.wxUserId
      row.schemeId = this.schemeId
      row.type = '1'
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowSave1 (row, done, loading) {
      row.wxUserId = this.wxUserId
      row.schemeId = this.schemeId
      row.type = '2'
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowSave2 (row, done, loading) {
      row.wxUserId = this.wxUserId
      row.schemeId = this.schemeId
      row.type = '3'
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = { wxUserId: this.wxUserId, schemeId: this.schemeId }) {
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        console.log('测试')
        console.log(res)
        console.log(res.data)
      })
      getList1(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page1.total = data.total
        this.data1 = data.records
      })
      getList2(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page2.total = data.total
        this.data2 = data.records
      })
    }
  }
}
</script>

<style></style>
