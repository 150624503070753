<template>
    <basic-container>
        <avue-crud
                :option="option"
                :data="data"
                :page.sync="page"
                ref="crud"
                @row-del="rowDel"
                v-model="form"
                :permission="permissionList"
                @row-update="rowUpdate"
                @row-save="rowSave"
                :before-open="beforeOpen"
                @search-change="searchChange"
                @search-reset="searchReset"
                @refresh-change="refreshChange"
                @selection-change="selectionChange"
                @on-load="onLoad">
            <template slot="menuLeft">
                <el-button
                        type="danger"
                        size="small"
                        icon="el-icon-delete"
                        plain
                        v-if="permission.qa_qa_delete"
                        @click="handleDelete">删 除
                </el-button>
            </template>
            <template slot-scope="scope" slot="menu">
                <el-button type="text" icon="el-icon-s-operation" size="small" @click.native="toItem(scope.row)">评估项
                </el-button>
                <el-button type="text" icon="el-icon-s-operation" size="small" @click.native="toRule(scope.row)">计分设置
                </el-button>
                <el-button type="text" icon="el-icon-s-operation" size="small" @click.native="toPgpz(scope.row)">评估设置
                </el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { getList, getDetail, submit, remove } from '@/api/qa/qa'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        addBtn: false,
        editBtn: false,
        addRowBtn: true,
        cellBtn: true,
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        menuWidth: 300,
        column: [
          {
            label: '名称',
            prop: 'name',
            cell: true,
            rules: [{
              required: true,
              message: '请输入名称'
            }]
          },
          {
            label: '启用状态',
            prop: 'status',
            type: 'radio',
            cell: true,
            dicUrl: '/sys/dict/code/enable_status',
            value: 1,
            rules: [{
              required: true,
              message: '请选择启用状态'
            }]
          },
          {
            label: '是否自动评分',
            prop: 'isCount',
            type: 'radio',
            cell: true,
            dicUrl: '/sys/dict/code/is_count',
            value: 0
          },
          {
            label: '答题类型',
            prop: 'type',
            type: 'select',
            cell: true,
            dicUrl: '/sys/dict/code/qa_type',
            value: 0
          },
          {
            label: '排序',
            prop: 'orderNum',
            width: 80,
            cell: true,
            rules: [{
              required: true,
              message: '请输入排序'
            }]
          },
          {
            label: '描述',
            prop: 'des',
            cell: true,
            span: 24,
            rules: [{
              required: true,
              message: '请输入描述'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qa_add,
        viewBtn: this.permission.qa_qa_view,
        delBtn: this.permission.qa_qa_delete,
        editBtn: this.permission.qa_qa_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    toItem (row) {
      this.$router.push({
        path: '/qa/qaitem',
        query: { qaId: row.id }
      })
    },
    toRule (row) {
      this.$router.push({
        path: '/qa/qarule',
        query: { qaId: row.id }
      })
    },
    toPgpz (row) {
      this.$router.push({
        path: '/qa/qapgpz',
        query: { qaId: row.id }
      })
    },
    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = {}) {
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>

<style>
</style>
