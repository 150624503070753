<template>
  <div>
    <el-row>
      <div class="formContent">
        <div class="formInput" v-for="(item,index) in innerTags" :key="index">
          <div class="formInputInd">{{ index + 1 }}</div>
          <el-tooltip class="item" effect="dark" :content="item" placement="top">
          <el-input @input="changeValue($event,index)" class="formInputBox" v-model="innerTags[index]">{{ item }}</el-input>
          </el-tooltip>
          <div class="formInputBtn">
            <el-button-group style="float: right;">
              <el-button @click="remove(index)" type="primary"
                         icon="el-icon-delete"></el-button>
            </el-button-group>
          </div>
        </div>
        <el-input  placeholder="录入完成按回车新增" class="formInputBox"  v-model="newTag"    @keyup.enter.native="addNew" @blur="addNew">
          <template slot="prepend">内容录入：</template>
        </el-input>

      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'TagsInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    addTagOnKeys: {
      type: Array,
      default: () => [13, 188, 9]
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    size: String
  },
  data () {
    return {
      newTag: '',
      innerTags: this.value ? this.value.split(';') : []
    }
  },
  watch: {
    value () {
      this.innerTags = this.value ? this.value.split(';') : []
    }
  },
  methods: {
    // focusTagInput () {
    //   if (this.readOnly || !this.$el.querySelector('.tag-input')) {
    //   } else {
    //     this.$el.querySelector('.tag-input').focus()
    //   }
    // },
    changeValue (e, index) {
      this.innerTags[index] = e
      this.tagChange()
    },

    addNew (e) {
      if (e && (!(e.keyCode === 13)) && (e.type !== 'blur')) {
        return
      }
      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }
      let addSuccess = false
      if (this.addTag(this.newTag.trim())) {
        addSuccess = true
      }
      if (addSuccess) {
        this.tagChange()
        this.newTag = ''
      }
    },
    addTag (tag) {
      tag = tag.trim()
      if (tag && !this.innerTags.includes(tag)) {
        this.innerTags.push(tag)
        return true
      }
      return false
    },
    remove (index) {
      this.innerTags.splice(index, 1)
      this.tagChange()
    },
    // removeLastTag () {
    //   if (this.newTag) {
    //     return
    //   }
    //   this.innerTags.pop()
    //   this.tagChange()
    // },
    tagChange () {
      this.$emit('input', this.innerTags.join(';'))
    }
  }
}
</script>

<style scoped lang="scss">
  .input-tag-wrapper {
    position: relative;
    font-size: 14px;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    outline: none;
    padding: 0 10px 0 5px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
  }

  .el-tag {
    margin-right: 4px;
  }

  .tag-input {
    background: transparent;
    border: 0;
    font-size: 14px;
    height: 40px;
    outline: none;
    padding-left: 0;
    width: 100px;
  }

  .el-input-tag--mini {
    .tag-input {
      height: 28px;
    }

    line-height: 28px;
  }

  .el-input-tag--small {
    .tag-input {
      height: 32px;
    }

    line-height: 32px;
  }

  .el-input-tag--medium {
    .tag-input {
      height: 36px;
    }

    line-height: 36px;
  }

  .memberTit {
    color: #5a88d3;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .memberTit::before {
    content: '';
    border-left: 6px solid #5a88d3;
    margin-right: 20px;
    background: #5a88d3;
  }

  .free_tit_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
  }

  .free_tit {
    display: inline-block;
    height: 50px;
    padding: 10px 0px;
  }

  .free_tit_text {
    padding: 0px 20px;
    z-index: 1;
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0F75EB;
  }

  .free_tit_bg {
    height: 27px;
    margin-top: -20px;
    margin-left: 39px;
    z-index: 0;
    background: rgba(61, 184, 255, 0.3);
    border-radius: 10px;
  }

  .formContent {
    padding: 20px;
  }

  .formInput {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .formInputBox {
    flex: 1;
  }

  .formInputInd {
    font-size: 18px;
    color: #fff;
    background: #0F75EB;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 30px;
    height: 30px;
    box-shadow: 0 3px 0 0 rgba(61, 184, 255, 0.3);;
  }

  .formInputBtn {
    width: 70px;
  }

</style>
