<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        :page.sync="page"
        @refresh-change="refreshChange"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.sys_user_delete"
            @click="handleDelete">删 除
        </el-button>
        <el-button
            type="primary"
            size="small"
            plain
            v-if="permission.sys_user_reset"
            icon="el-icon-refresh"
            @click="handleReset">密码重置
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getList, getUser, remove, resetPassword, submit } from '@/api/sys/user'
import { mapGetters } from 'vuex'

export default {
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {},
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      init: {
        roleTree: [],
        deptTree: []
      },
      option: {
        tip: false,
        border: true,
        index: true,
        selection: true,
        viewBtn: true,
        dialogHeight: 450,
        column: [
          {
            label: '用户名',
            prop: 'username',
            search: true,
            rules: [{
              required: true,
              message: '请输入用户名'
            }]
          },

          {
            label: '密码',
            prop: 'password',
            hide: true,
            editDisplay: false,
            viewDisplay: false,
            rules: [{ required: true, validator: validatePass, trigger: 'blur' }]
          },
          {
            label: '确认密码',
            prop: 'password2',
            hide: true,
            editDisplay: false,
            viewDisplay: false,
            rules: [{ required: true, validator: validatePass2, trigger: 'blur' }]
          },
          {
            label: '用户昵称',
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              message: '请输入用户昵称'
            }]
          },
          {
            label: '所属角色',
            prop: 'roleIds',
            multiple: true,
            type: 'select',
            dicUrl: '/sys/role/dict',
            dataType: 'string',
            rules: [{
              required: true,
              message: '请选择所属角色',
              trigger: 'click'
            }]
          },
          {
            label: '所属部门',
            prop: 'deptId',
            type: 'tree',
            dicUrl: '/sys/dept/tree',
            dataType: 'string',
            props: {
              value: 'id',
              label: 'name'
            },
            rules: [{
              required: true,
              message: '请选择所属部门',
              trigger: 'click'
            }]
          },
          {
            label: '所属渠道',
            prop: 'channel',
            type: 'tree',
            dicUrl: '/sys/channel/dict',
            dataType: 'string',
            rules: [{
              required: true,
              message: '请选择所属渠道',
              trigger: 'click'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_user_add, false),
        viewBtn: this.vaildData(this.permission.sys_user_view, false),
        delBtn: this.vaildData(this.permission.sys_user_delete, false),
        editBtn: this.vaildData(this.permission.sys_user_edit, false)
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    rowSave (row, loading, done) {
      submit(row).then(() => {
        loading()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }, () => {
        done()
      })
    },
    rowUpdate (row, index, loading, done) {
      submit(row).then(() => {
        loading()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }, () => {
        done()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params, done)
    },
    selectionChange (list) {
      this.selectionList = list
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    handleReset () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择账号密码重置为123456?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return resetPassword(this.ids)
        })
        .then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getUser(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    currentChange (currentPage) {
      this.page.currentPage = currentPage
    },
    sizeChange (pageSize) {
      this.page.pageSize = pageSize
    },
    onLoad (page, params = {}, done) {
      getList(page.currentPage, page.pageSize, params).then(res => {
        console.log('test getList')
        console.log(res)
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        if (done) {
          done()
        }
      })
    }
  }
}
</script>

<style>
</style>
