import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/health/bpmeterdata/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getList0 = (current, size, params) => {
  return request({
    url: '/health/bpmeterdata/list0',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getList01 = (current, size, params) => {
  return request({
    url: '/health/bpmeterdata/list01',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getList1 = (current, size, params) => {
  return request({
    url: '/health/bpmeterdata/list1',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/health/bpmeterdata/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/health/bpmeterdata/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/health/bpmeterdata/submit',
    method: 'post',
    data: row
  })
}

export const getClassifyFree = (params) => {
  return request({
    url: '/bp/mic/micClassifyByDate',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getAvgByDayFree = (params) => {
  return request({
    url: '/bp/mic/micAvgByDate',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const submitForm = (row) => {
  return request({
    url: '/health/bpreport/submitFormS',
    method: 'post',
    data: row
  })
}

// 测量次数汇总
export const cnt = (params) => {
  return request({
    url: '/health/bpreport/cnt',
    method: 'get',
    params: {
      ...params
    }
  })
}

// export const bpAnalysis = (params) => {
//   return request({
//     url: '/health/bpreport/bpAnalysis',
//     method: 'get',
//     params: {
//       ...params
//     }
//   })
// }
//
export const bpDataValue = (params) => {
  return request({
    url: '/bp/mic/avgByBp',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const bpDataValueNew = (params) => {
  return request({
    url: '/bp/mic/avgByBpNew',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const heDataValueNew = (params) => {
  return request({
    url: '/bp/mic/avgByHeNew',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const bpZcReport = (row) => {
  return request({
    url: '/health/bpreport/bpZcReport',
    method: 'post',
    data: row
  })
}

export const getSerachList = (current, size, params) => {
  return request({
    url: '/health/bpmeterdata/serachList',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getSerachList1 = (current, size, params) => {
  return request({
    url: '/health/bpmeterdata/serachList1',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const bpAnalysis = (params) => {
  return request({
    url: '/health/bpreport/bpAnalysis',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const bpDataReport = (params) => {
  return request({
    url: '/health/bpreport/bpDataReport',
    method: 'get',
    params: {
      ...params
    }
  })
}

