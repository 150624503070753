<template>
    <basic-container>
        <avue-crud :option="option"
                   :data="data"
                   :page.sync="page"
                   ref="crud"
                   @row-del="rowDel"
                   v-model="form"
                   :permission="permissionList"
                   @row-update="rowUpdate"
                   @row-save="rowSave"
                   :before-open="beforeOpen"
                   @search-change="searchChange"
                   @search-reset="searchReset"
                   @refresh-change="refreshChange"
                   @selection-change="selectionChange"
                   @on-load="onLoad">
            <template slot="scoreForm">
                <div>
                    <el-card shadow="hover" class="box-card" v-for="(item,index) in form.scoreItems" :key="index">
                        <div class="clearfix">
                            <span>{{index+1 +'. '+ item.name}}</span>
                            <el-button style="float: right; padding: 3px 0" type="text">分数：{{item.score}}
                            </el-button>
                        </div>
                    </el-card>
                </div>
            </template>
            <template slot="cardForm">
                <div>
                    <el-card shadow="hover" class="box-card" v-for="(item,index) in form.itemList" :key="index">
                        <div slot="header" class="clearfix">
                            <span>{{index+1 +'. '+ item.itemContent}}<span style="padding-left: 10px;color: #e4393c;">{{'('+(item.isRequire?'必填':'非必填')+')'}}</span></span>
                            <el-button style="float: right; padding: 3px 0" type="text">正确答案：{{item.solution}}
                            </el-button>
                        </div>
                        <div v-if="item.itemType == 1">
                            <div v-for="(child,i) in item.itemOptions" :key="i" :class="{ itemActive: child.checked}">
                                {{child.optionKey +'. ' +child.optionContent }}（分值：{{child.optionVal}}）
                            </div>
                        </div>
                        <div v-if="item.itemType == 2">
                            <div>
                                {{item.answer}}
                            </div>
                        </div>
                        <div v-if="item.itemType == 3">
                            <div>
                                {{item.answer}}
                            </div>
                        </div>
                    </el-card>
                </div>
            </template>
            <template slot="menuForm">
                <el-button type="primary" icon="el-icon-check" size="small" plain @click="$refs.crud.rowUpdate()">提交评估
                </el-button>
            </template>
            <template slot-scope="scope" slot="menu">
                <el-button type="text" icon="el-icon-s-operation" size="small" @click.native="toItem(scope.row)">评估报表
                </el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { getList, getDetail, submit, remove, getDiabetesDetail } from '@/api/qa/qaanswer'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      params: {
        status: 2
      },
      selectionList: [],
      option: {
        searchMenuSpan: 8,
        tip: false,

        border: true,
        index: true,
        columnBtn: false,
        refreshBtn: false,
        saveBtn: false,
        updateBtn: false,
        cancelBtn: true,
        addBtn: false,
        editBtn: true,
        editBtnText: '评估',
        delBtn: false,
        selection: true,
        column: [
          /* {
               label: '试题',
               prop: 'qaName',
               detail: true,
               search: true,
               },*/
          {
            label: '试题',
            prop: 'qaId',
            detail: true,
            search: true,
            type: 'select',
            dicUrl: 'qa/qa/all',
            props: {
              value: 'id',
              label: 'name'
            }
          },
          {
            label: '用户',
            prop: 'jkName',
            detail: true,
            search: true
          },
          {
            label: '年龄',
            prop: 'jkAge',
            detail: true
          },
          {
            label: '性别',
            prop: 'jkSex',
            type: 'select',
            dicUrl: '/sys/dict/code/sex',
            detail: true
          },
          {
            label: '阳性项',
            prop: 'yxxCount',
            detail: true,
            hide: true
          },
          {
            label: '阳性项和',
            prop: 'yxxVal',
            detail: true,
            hide: true
          },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            dicUrl: '/sys/dict/code/qa_answer_status',
            search: true,
            searchValue: 2,
            detail: true,
            change: ({ value }) => {
              this.params.status = value
              this.onLoad(this.page)
            }
          },
          {
            label: '评估',
            prop: 'assess',
            type: 'textarea',
            hide: true,
            span: 24
          },
          {
            label: '计分项',
            prop: 'score',
            formslot: true,
            hide: true,
            span: 24
          },
          {
            label: '评估项',
            prop: 'card',
            formslot: true,
            hide: true,
            span: 24
          },
          {
            label: '提交日期',
            prop: 'updateTime',
            type: 'date',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            addDisplay: false,
            editDisplay: false
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.qa_qaanswer_add,
        viewBtn: this.permission.qa_qaanswer_view,
        delBtn: this.permission.qa_qaanswer_delete,
        editBtn: this.permission.qa_qaanswer_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    toItem (row) {
      console.log(row)
      this.$router.push({
        path: '/qa/qarecord',
        query: { qaId: row.qaId, uid: row.userId }
      })
    },
    check () {

    },
    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.params = {
        status: 1
      }
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.params = params
      this.onLoad(this.page)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.params = data.searchForm
      this.onLoad(this.page)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    onLoad (page) {
      this.params.channel = window.localStorage.getItem('channel')
      getList(page.currentPage, page.pageSize, this.params).then(res => {
        console.log('test getList')
        console.log(res)
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        if (this.form.qaId === '1460415994191233026') {
          getDiabetesDetail(this.form.id).then(res => {
            this.form = res.data
            console.log('test')
            console.log(res.data)
          })
        } else {
          getDetail(this.form.id).then(res => {
            this.form = res.data
            console.log('test111')
            console.log(res.data)
          })
        }
      }
      done()
    }

  }
}
</script>
<style>
    .box-card {
        margin-bottom: 10px;
    }

    .itemActive {
        color: #5a88d3;
    }
</style>
