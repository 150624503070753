<template>
  <basic-container>
    <el-container style="background: #fff;padding: 20px; overflow-y: auto;width: 100%">
      <el-form :model="reportFrom" ref="reportFrom" class="reportFrom">
        <el-form-item label="">
          <el-col :span="24">
            <el-collapse accordion style="width: 100%;">
              <el-collapse-item title="初始方案" name="1">
                <!--初始方案-->
                <el-card class="box-card">
                  <el-descriptions title="初始方案" direction="vertical" :column="4" border>
                    <el-descriptions-item label="记录时间" :span="4">
                      <el-date-picker v-model="reportFrom.initialPlan.recordTimeS" type="datetime" placeholder="选择日期时间">
                      </el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template slot="label">
                        药物方案
                        <div class="addYwfa" @click="addFa(1)">+ 新增用药</div>
                      </template>
                      <el-table :data="reportFrom.initialPlan.initialPlanList" style="width: 100%">
                        <el-table-column label="化学名" width="180">
                          <template slot-scope="scope">
                            <el-input v-model="scope.row.chemical" placeholder="请输入化学名"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="商品名" width="180">
                          <template slot-scope="scope">
                            <el-input v-model="scope.row.product" placeholder="请输入商品名"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="规格" width="180">
                          <template slot-scope="scope">
                            <el-input v-model="scope.row.specifications" placeholder="请输入规格"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="单次剂量" width="180">
                          <template slot-scope="scope">
                            <el-input v-model="scope.row.singleDose" placeholder="请输入单次剂量"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="服用频次" width="180">
                          <template slot-scope="scope">
                            <el-input v-model="scope.row.takingFrequency" placeholder="请输入服用频次"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="操作">
                          <template slot-scope="scope">
                            <el-button size="mini" type="danger"
                              @click="handleDelete(scope.$index, scope.row,1)">删除</el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-descriptions-item>

                  </el-descriptions>
                </el-card>
              </el-collapse-item>
              <el-collapse-item title="用药方案调整" name="2">
                <!--用药方案调整-->
                <el-card shadow="always" class="box-card" v-for="(item,index) in reportFrom.initialPlanChange"
                  :key="index">
                  <el-descriptions direction="vertical" :column="4" border>
                    <template slot="title">
                      第<span>{{index+1}}</span>次用药方案调整
                    </template>
                    <template slot="extra">
                      <el-button v-if="reportFrom.initialPlanChange.length == (index+1)" @click="addList" type="primary"
                        size="small">+ 新增药物方案调整</el-button>
                      <el-button v-if="reportFrom.initialPlanChange.length != 1" @click="deleteList(index)"
                        type="danger" size="small">- 删除药物方案调整</el-button>
                    </template>
                    <el-descriptions-item label="调整时间" :span="4">
                      <el-date-picker v-model="item.adjustTime" type="datetime" placeholder="选择日期时间">
                      </el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item :span="4">
                      <template slot="label">
                        药物方案
                        <div class="addYwfa" @click="addFa(2,index)">+ 新增用药</div>
                      </template>
                      <el-table :data="item.initialPlanList" style="width: 100%">
                        <el-table-column label="化学名" width="180">
                          <template slot-scope="scope">
                            <el-input v-model="scope.row.chemical" placeholder="请输入化学名"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="商品名" width="180">
                          <template slot-scope="scope">
                            <el-input v-model="scope.row.product" placeholder="请输入商品名"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="规格" width="180">
                          <template slot-scope="scope">
                            <el-input v-model="scope.row.specifications" placeholder="请输入规格"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="单次剂量" width="180">
                          <template slot-scope="scope">
                            <el-input v-model="scope.row.singleDose" placeholder="请输入单次剂量"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="服用频次" width="180">
                          <template slot-scope="scope">
                            <el-input v-model="scope.row.takingFrequency" placeholder="请输入服用频次"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="操作">
                          <template slot-scope="scope">
                            <el-button size="mini" type="danger"
                              @click="handleDelete(scope.$index, scope.row,2,index)">删除</el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-descriptions-item>
                    <el-descriptions-item label="配送时间" :span="4">
                      <el-date-picker v-model="item.deliveryTime" type="datetime" placeholder="选择日期时间">
                      </el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item :span="4">
                      <template slot="label">
                        配送
                        <div class="addYwfa" @click="addFa(3,index)">+ 新增配送</div>
                      </template>
                      <el-table :data="item.deliveryOneList" style="width: 100%">
                        <el-table-column label="配送" width="180">
                          <template slot-scope="scope">
                            <el-input v-model="scope.row.distribution" placeholder="请输入配送"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="开始服药日期" width="180">
                          <template slot-scope="scope">
                            <el-input v-model="scope.row.startMedication" placeholder="请输入开始服药日期"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="操作">
                          <template slot-scope="scope">
                            <el-button size="mini" type="danger"
                              @click="handleDelete(scope.$index, scope.row,3,index)">删除</el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-descriptions-item>
                  </el-descriptions>
                </el-card>
              </el-collapse-item>

              <el-collapse-item title="配送药物提醒" name="3">
                <el-date-picker v-model="reportFrom.timeTask" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-collapse-item>
            </el-collapse>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button style="text-align: center;" type="primary" @click="submit('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </el-container>
  </basic-container>
</template>

<script>
  import { getDetail, getSubmit } from '@/api/health/drugplan'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        reportFrom: {
          initialPlan: {
            recordTimeS: '', // 记录时间
            initialPlanList: [
              {
                chemical: '', // 化学名
                product: '', // 商品名
                specifications: '', // 规格
                singleDose: '', // 单次剂量
                takingFrequency: ''// 服用频次
              }
            ]
          },
          initialPlanChange: [ // 药物方案调整
            {
              adjustTime: '', // 调整时间
              initialPlanList: [ // 调整药物方案
                {
                  chemical: '', // 化学名
                  product: '', // 商品名
                  specifications: '', // 规格
                  singleDose: '', // 单次剂量
                  takingFrequency: ''// 服用频次
                }
              ],
              deliveryTime: '', // 配送时间
              deliveryOneList: [ // 配送方案
                {
                  distribution: '', // 配送
                  startMedication: ''// 开始服药时间
                }
              ]
            }
          ],
          timeTask: '',// 配送药物提醒
        },
        form: {},
        data: [],
        drugForm: {}
      }
    },
    computed: {
      ...mapGetters(['permission'])
    },
    created() {
      this.reportFrom.name = this.$route.query.jkName
      this.reportFrom.age = this.$route.query.jkAge
      this.reportFrom.sex = this.$route.query.jkSex
      this.reportFrom.wxUserId = this.$route.query.wxUserId
      this.wxUserId = this.$route.query.wxUserId
      this.onLoad()
    },
    methods: {
      // 药物方案删除
      handleDelete(index, row, type, itemIndex) {
        const t = this
        if (type === 1) {
          t.reportFrom.initialPlan.initialPlanList.splice(index, 1)
        } else if (type === 2) {
          t.reportFrom.initialPlanChange[itemIndex].initialPlanList.splice(index, 1)
        } else if (type === 3) {
          t.reportFrom.initialPlanChange[itemIndex].deliveryOneList.splice(index, 1)
        } else {
          t.$message({
            type: 'error',
            message: '删除失败!'
          })
        }
      },

      // 新增一条药物
      addFa(type, index) {
        const t = this
        if (type === 1) {
          const data = {
            chemical: '', // 化学名
            product: '', // 商品名
            specifications: '', // 规格
            singleDose: '', // 单次剂量
            takingFrequency: ''// 服用频次
          }
          t.reportFrom.initialPlan.initialPlanList.push(data)
        } else if (type === 2) {
          const data = {
            chemical: '', // 化学名
            product: '', // 商品名
            specifications: '', // 规格
            singleDose: '', // 单次剂量
            takingFrequency: ''// 服用频次
          }
          t.reportFrom.initialPlanChange[index].initialPlanList.push(data)
        } else if (type === 3) {
          const data = {
            distribution: '', // 配送
            startMedication: ''// 开始服药时间
          }
          t.reportFrom.initialPlanChange[index].deliveryOneList.push(data)
        } else {
          t.$message({
            type: 'error',
            message: '新增失败!'
          })
        }
      },

      // 新增一条药物方案
      addList() {
        const t = this
        const data = {
          adjustTime: '', // 调整时间
          initialPlanList: [ // 调整药物方案
            {
              chemical: '', // 化学名
              product: '', // 商品名
              specifications: '', // 规格
              singleDose: '', // 单次剂量
              takingFrequency: ''// 服用频次
            }
          ],
          deliveryTime: '', // 配送时间
          deliveryOneList: [ // 配送方案
            {
              distribution: '', // 配送
              startMedication: ''// 开始服药时间
            }
          ]
        }
        t.reportFrom.initialPlanChange.push(data)
      },

      deleteList(index) {
        const t = this
        t.reportFrom.initialPlanChange.splice(index, 1)
      },
      handleBack() {
        this.$router.back()
      },
      searchReset() {
        this.onLoad(this.page)
      },
      searchChange(params, done) {
        this.onLoad(this.page, params)
        done()
      },
      selectionChange(list) {
        this.selectionList = list
      },
      refreshChange(data) {
        this.onLoad(this.page, data.searchForm)
      },
      onLoad() {
        getDetail(this.reportFrom.wxUserId).then(res => {
          this.reportFrom = res.data
        })
      },
      submit() {
        debugger
        const t = this
        t.reportFrom.wxUserId = this.wxUserId
        getSubmit(t.reportFrom).then(() => {
          t.$message({
            type: 'success',
            message: '操作成功!'
          })
        }).catch(() => {
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .reportFrom {
    width: 100%;
  }

  .addYwfa {
    padding: 5px 20px;
    border-radius: 20px;
    text-align: center;
    background: #0F75EB;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    margin-left: 20px;
  }

  .addYwfa:hover {
    background: #6fb3f3;
    cursor: pointer;
  }

  .box-card {
    margin-bottom: 40px;
  }
</style>