<template>
  <basic-container>
    <div style="padding: 20px 0;">
      <i style="margin-right: 4px;" class="el-icon-time"></i>报告隶属时间段：
      <el-date-picker
          v-model="dateValue"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :readonly="true">
      </el-date-picker>
    </div>
    <div>
      <avue-crud
          :option="option"
          :data="data"
          :page.sync="page"
          ref="crud"
          v-model="form"
          :permission="permissionList"
          @search-change="searchChange"
          @search-reset="searchReset"
          @refresh-change="refreshChange"
          @selection-change="selectionChange"
          @on-load="onLoad">
      </avue-crud>
    </div>
  </basic-container>
</template>

<script>
import { getList } from '@/api/health/ecgmeterdata'
import { mapGetters } from 'vuex'
import { getWeek } from '@/api/sys/code'
// import { bpAnalysis, bpDataReport, cnt } from '@/api/health/bpmeterdata'

export default {
  data () {
    return {
      orgOptions: {},
      form: {},
      dateValue: [],
      tabType: 1,
      picList: null,
      linList: null,
      params: {
        wxUserId: '',
        week: '',
        jkStatus: ''
        // type: '1'
      },
      weekDate: '',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      free: '',
      freeOptions: {
        // 普通图片上传
        customConfig: {}, // wangEditor编辑的配置
        oss: 'ali',
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-shanghai',
          endpoint: 'oss-cn-shanghai.aliyuncs.com',
          accessKeyId: 'LTAI4GGYDGZjaZQyshkjPBJH',
          accessKeySecret: '5O9VCIRDa6AqHtVj2NDiMUO9Uv2GDe',
          bucket: 'health-planet'
        }
      },
      pay: '',
      payOptions: {
        // 普通图片上传
        action: 'https://avuejs.com/imgupload',
        customConfig: {}, // wangEditor编辑的配置
        props: {
          res: 'data',
          url: 'url'
        },
        // 阿里云oss配置
        ali: {
          region: 'oss-cn-beijing',
          endpoint: 'oss-cn-beijing.aliyuncs.com',
          accessKeyId: '',
          accessKeySecret: '',
          bucket: 'avue'
        }
      },
      selectionList: [],
      option: {
        addBtn: false,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        cancelBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: 'id',
            prop: 'id'
          },
          {
            label: '标题',
            prop: 'title'
          },
          {
            label: '心电',
            prop: 'ecg'
          },
          {
            label: '心电图详情',
            prop: 'details'
          },
          {
            label: '测量时间',
            prop: 'meterDate'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.health_bsmeterdata_add,
        viewBtn: this.permission.health_bsmeterdata_view,
        delBtn: this.permission.health_bsmeterdata_delete,
        editBtn: this.permission.health_bsmeterdata_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  created () {
    this.params.wxUserId = this.$route.query.wxUserId || '' // eslint-disable-line no-unused-vars
    this.params.week = this.$route.query.meterDate || '' // eslint-disable-line no-unused-vars
    this.params.jkStatus = this.$route.query.jkStatus || ''
    getWeek(this.$route.query.meterDate).then(res => {
      this.dateValue = [res.data.monday, res.data.sunday]
    })
  },
  mounted () {

  },
  methods: {
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    onLoad (page) {
      getList(page.currentPage, page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        console.log(data)
      })
    }
  }
}

</script>

<style>
.memberTit{
  color: #5a88d3;
  font-size: 16px;
  margin-bottom: 20px;
}
.memberTit::before{
  content: '';
  border-left: 6px solid #5a88d3;
  margin-right: 20px;
  background: #5a88d3;
}
.free_tit_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

.free_tit {
  display: inline-block;
  height: 50px;
  padding: 10px 0px;
}

.free_tit_text {
  padding: 0px 20px;
  z-index: 1;
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #0F75EB;
}

.free_tit_bg {
  height: 27px;
  margin-top: -20px;
  margin-left: 39px;
  z-index: 0;
  background: rgba(61, 184, 255, 0.3);
  border-radius: 10px;
}

.formContent {
  padding: 20px;
}

.formInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.formInputBox {
  flex: 1;
}

.formInputInd {
  font-size: 18px;
  color: #fff;
  background: #0F75EB;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 30px;
  height: 30px;
  box-shadow: 0 3px 0 0 rgba(61, 184, 255, 0.3);;
}

.formInputBtn {
  width: 120px;
  margin-left: 20px;
}
</style>
