<template>
    <el-container style="height: 100vh; border: 1px solid #eee">
        <el-aside width="400px" style="background-color:#fff;position: relative;height: 100%">
            <phone-report :reportForm="reportForm" class="phone"></phone-report>
        </el-aside>
        <el-container style="background: #fff;padding: 20px; overflow-y: auto">
            <avue-form ref="form" v-model="reportForm" :option="reportOption" @submit="submit">
                <template slot-scope="{row}" slot="zlcl">
                    <tags-input v-model="row.zlcl" size="small"></tags-input>
                </template>
                <template slot-scope="{row}" slot="zljy">
                    <tags-input v-model="row.zljy" size="small"></tags-input>
                </template>
                <template slot="ywfa">
                    <tags-input v-model="reportForm.ywfa" size="small"></tags-input>
                </template>
                <template slot="fywfa">
                    <tags-input v-model="reportForm.fywfa" size="small"></tags-input>
                </template>
                <template slot="szzb">
                    <tags-input v-model="reportForm.szzb" size="small"></tags-input>
                </template>
                <template slot="checkUrl">
                    <div class="demo-image__preview">
                      <template v-for="url in reportForm.checkUrl">
                        <el-image v-if="isPdf(url)" @click="showPdf(url)"
                                style="padding: 5px; width: 100px; height: 100px"
                                :key="url" :src="pdfImg" id="img1" >
                                <!--:preview-src-list=" reportForm.checkUrl"-->
                        </el-image>
                      </template>
                      <template v-for="url in reportForm.checkUrl">
                        <el-image v-if="!isPdf(url)"
                                style="padding: 5px; width: 100px; height: 100px"
                                :key="url" :src="url" id="img1" :preview-src-list="reportForm.checkUrl">
                                <!--:preview-src-list=" reportForm.checkUrl"-->
                        </el-image>
                      </template>
                    </div>
                </template>
            </avue-form>
            <el-button class="zcBtn" @click="zcBtn">暂存</el-button>
        </el-container>
    </el-container>
</template>

<script>

import { getDetail as reportDetail, report } from '@/api/health/report'
import TagsInput from '@/components/tags-input/index'
import PhoneReport from '@/components/phone-report/index'
import {
  mapGetters
} from 'vuex'
export default {
  components: { TagsInput, PhoneReport },
  data () {
    return {
      reportId: 0,
      sizeValue: 'small',
      reportForm: {},
      pdfImg: 'https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/goods/20221119/file1593846911062904832.png'
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    reportOption () {
      return {
        size: this.sizeValue,
        submitText: '解读',
        emptyBtn: false,
        column: [
          {
            label: '姓名',
            prop: 'jkName',
            detail: true
          },
          {
            label: '年龄',
            prop: 'jkAge',
            detail: true
          },
          {
            label: '性别',
            prop: 'jkSex',
            type: 'select',
            dicData: [{
              label: '男',
              value: '1'
            }, {
              label: '女',
              value: '2'
            }],
            detail: true
          },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            search: true,
            searchValue: 1,
            detail: true,
            dicData: [{
              label: '已申请',
              value: 1
            }, {
              label: '已解读',
              value: 2
            }]
          },
          {
            label: '体检报告',
            prop: 'checkUrl',
            hide: true,
            editDisabled: true,
            dataType: 'array',
            type: 'upload',
            formslot: true,
            propsHttp: {
              res: 'data.0'
            },
            span: 24,
            listType: 'picture-card',
            detail: true
          },
          {
            label: '健康状态',
            prop: 'jkzt',
            type: 'rate',
            showText: true,
            texts: ['病危', '不健康', '良好', '健康', '很健康']
          },
          {
            label: '循环系统',
            prop: 'xhxt',
            type: 'rate',
            showText: true,
            texts: ['正常', '正常', '低风险', '中风险', '高风险']
          },
          {
            label: '呼吸系统',
            prop: 'hxxt',
            type: 'rate',
            showText: true,
            texts: ['正常', '正常', '低风险', '中风险', '高风险']
          },
          {
            label: '代谢内分泌',
            prop: 'dxnfm',
            type: 'rate',
            showText: true,
            texts: ['正常', '正常', '低风险', '中风险', '高风险']
          },
          {
            label: '运动系统',
            prop: 'ydxt',
            type: 'rate',
            showText: true,
            texts: ['正常', '正常', '低风险', '中风险', '高风险']
          },
          {
            label: '眼耳鼻喉',
            prop: 'yebh',
            type: 'rate',
            showText: true,
            texts: ['正常', '正常', '低风险', '中风险', '高风险']
          },
          {
            label: '心理',
            prop: 'xl',
            type: 'rate',
            showText: true,
            texts: ['正常', '正常', '低风险', '中风险', '高风险']
          },
          {
            label: '泌尿生殖系统',
            prop: 'mnxt',
            type: 'rate',
            showText: true,
            texts: ['正常', '正常', '低风险', '中风险', '高风险']
          },
          {
            label: '消化系统',
            prop: 'xhuaxt',
            type: 'rate',
            showText: true,
            texts: ['正常', '正常', '低风险', '中风险', '高风险']
          },
          {
            label: '血液肿瘤',
            prop: 'xyzlxt',
            type: 'rate',
            showText: true,
            texts: ['正常', '正常', '低风险', '中风险', '高风险']
          },
          {
            label: '既往史',
            prop: 'jws'
          },
          {
            label: '药物史',
            prop: 'yws'
          },
          {
            label: '非药物方案',
            prop: 'fywfa',
            formslot: true,
            minRows: 10,
            span: 24
          }
          // {
          //   label: '药物方案',
          //   prop: 'ywfa',
          //   formslot: true,
          //   minRows: 10,
          //   span: 24
          // },
          // {
          //   label: '随诊指标',
          //   prop: 'szzb',
          //   formslot: true,
          //   minRows: 10,
          //   span: 24
          // }
        ],
        group: [
          {
            icon: 'el-icon-info',
            label: '健康风险',
            collapse: false,
            prop: 'jkfxList',
            column: [
              {
                label: '健康风险',
                prop: 'jkfxList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    console.log('---')
                    done({
                      reportId: this.reportId
                    })
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    {
                      width: 200,
                      label: '诊断系统',
                      prop: 'zdxt'
                    },
                    {
                      width: 350,
                      label: '风险描述',
                      type: 'textarea',
                      prop: 'fxms'
                    },
                    // {
                    //   width: 200,
                    //   label: '诊断',
                    //   prop: 'zd'
                    // },
                    {
                      width: 200,
                      label: '类型',
                      prop: 'type',
                      type: 'select',
                      dicData: [{
                        label: '主要',
                        value: 1
                      }, {
                        label: '次要',
                        value: 2
                      }]
                    }
                  ]
                }
              }
            ]
          },
          {
            icon: 'el-icon-info',
            label: '报告名词解释',
            collapse: false,
            prop: 'mcjsList',
            column: [
              {
                label: '报告名词解释',
                prop: 'mcjsList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    done({
                      reportId: this.reportId
                    })
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    {
                      width: 200,
                      label: '名词',
                      prop: 'mc'
                    },
                    {
                      width: 350,
                      label: '描述',
                      type: 'textarea',
                      prop: 'ms'
                    }
                  ]
                }
              }
            ]
          },
          {
            icon: 'el-icon-info',
            label: '异常项目',
            collapse: false,
            prop: 'cgList',
            column: [
              {
                label: '异常项目',
                prop: 'cgList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    done({
                      reportId: this.reportId
                    })
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    {
                      width: 200,
                      label: '项',
                      prop: 'zdylx'
                    },
                    {
                      width: 200,
                      label: '项分类',
                      prop: 'lxx'
                    },
                    {
                      width: 200,
                      label: '分类值',
                      prop: 'lxz'
                    }
                  ]
                }
              }
            ]
          },
          {
            icon: 'el-icon-info',
            label: '诊疗信息',
            collapse: false,
            prop: 'zlList',
            column: [
              {
                label: '诊疗信息',
                prop: 'zlList',
                type: 'dynamic',
                span: 24,
                children: {
                  align: 'center',
                  headerAlign: 'center',
                  rowAdd: (done) => {
                    done({
                      reportId: this.reportId
                    })
                  },
                  rowDel: (row, done) => {
                    done()
                  },
                  column: [
                    {
                      label: '编号',
                      prop: 'id',
                      hide: true
                    },
                    {
                      width: 200,
                      label: '诊疗类型',
                      prop: 'type'
                    },
                    {
                      width: 200,
                      label: '诊断',
                      prop: 'zd',
                      type: 'textarea'
                    },
                    {
                      width: 400,
                      label: '诊疗策略',
                      prop: 'zlcl',
                      formslot: true
                    },
                    {
                      width: 400,
                      label: '诊疗建议',
                      formslot: true,
                      prop: 'zljy'
                    },
                    {
                      width: 200,
                      label: '药物方案',
                      prop: 'yczb',
                      type: 'textarea'
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    }
  },
  filters: {
    sex (d) {
      if (d === '1') {
        return '男'
      } else {
        return '女'
      }
    }
  },
  created () {
    this.reportId = this.$route.query.id
    this.reportForm.jkName = this.$route.query.jkName
    this.reportForm.jkAge = this.$route.query.jkAge
    this.reportForm.jkSex = this.$route.query.jkSex
    this.onLoad()
  },
  methods: {
    submit (form, done) {
      if (this.reportForm.status === 2) {
        this.$message.error('只有未解读状态才能解读报告')
        return
      }
      this.reportForm.id = this.reportId
      this.reportForm.status = 2
      report(this.reportForm).then(() => {
        done()
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        done()
      })
    },
    showPdf (url) {
      // console.log(e)
      // location.href=e
      window.open(url, '_blank')
    },
    isPdf (url) {
      const z = url.split('.')
      return z[z.length - 1] === 'pdf'
    },
    zcBtn (form, done) {
      if (this.reportForm.status === 2) {
        this.$message.error('只有未解读状态才能解读报告')
        return
      }
      this.reportForm.id = this.reportId
      this.reportForm.status = 3
      report(this.reportForm).then(() => {
        done()
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
      })
    },
    openUrl (url) {
      // window.open(url);
      console.log(url)
      const newWindow = window.open('')
      newWindow.document.body.innerHTML = '<img src="' + url + '" alt="">'
    },
    onLoad () {
      reportDetail(this.reportId).then(res => {
        this.reportForm = res.data
        if (this.reportForm.cgList.length === 0) {
          this.reportForm.cgList = [
            { reportId: this.reportId, zdylx: '血压', lxx: '收缩压', lxz: 'mmHg' },
            { reportId: this.reportId, zdylx: '血压', lxx: '舒张压', lxz: 'mmHg' },
            { reportId: this.reportId, zdylx: '一般检查', lxx: '心率', lxz: '次/分' },
            { reportId: this.reportId, zdylx: '一般检查', lxx: 'BMI', lxz: 'kg/m²' },
            { reportId: this.reportId, zdylx: '血常规', lxx: '白细胞计数', lxz: '*10^9/L' },
            { reportId: this.reportId, zdylx: '血常规', lxx: '红细胞计数', lxz: '*10^12/L' },
            { reportId: this.reportId, zdylx: '血常规', lxx: '血红蛋白', lxz: ' g/L' },
            { reportId: this.reportId, zdylx: '血常规', lxx: '淋巴细胞计数', lxz: '*10^9/L' },
            { reportId: this.reportId, zdylx: '肝功能', lxx: '谷丙转氨酶（ALT）', lxz: 'U/L' },
            { reportId: this.reportId, zdylx: '肝功能', lxx: '谷草转氨酶（AST）', lxz: 'U/L' },
            { reportId: this.reportId, zdylx: '肾功能', lxx: '肌酐', lxz: 'mmol/L' },
            { reportId: this.reportId, zdylx: '肾功能', lxx: '静脉血糖', lxz: 'mmol/L' },
            { reportId: this.reportId, zdylx: '血脂四项', lxx: '总胆固醇', lxz: 'mmol/L' },
            { reportId: this.reportId, zdylx: '血脂四项', lxx: '甘油三酯', lxz: 'mmol/L' },
            { reportId: this.reportId, zdylx: '血脂四项', lxx: '低密度脂蛋白胆固醇', lxz: 'mmol/L' },
            { reportId: this.reportId, zdylx: '血脂四项', lxx: '高密度脂蛋白胆固醇', lxz: 'mmol/L' },
            { reportId: this.reportId, zdylx: '糖化血红蛋白', lxx: '糖化血红蛋白', lxz: '%' }
            // { reportId: this.reportId, zdylx: '抑郁评分', lxx: '抑郁自评量表', lxz: '分' },
            // { reportId: this.reportId, zdylx: '抑郁评分', lxx: '汉密尔顿抑郁量表', lxz: '分' },
            // { reportId: this.reportId, zdylx: '焦虑评分', lxx: '焦虑自评量表', lxz: '分' },
            // { reportId: this.reportId, zdylx: '焦虑评分', lxx: '汉密尔顿焦虑量表', lxz: '分' }
          ]
        }
      })
    }
  }

}
</script>
<style>
    .phone {
        position: absolute;
        top: 50%;
        margin-top: -360px;
        left: 50%;
        margin-left: -181px;
    }

    .zcBtn {
        position: fixed;
        bottom: 50px;
        right: 40px;
        width: 90px;
        height: 90px;
        background: #5a88d3;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 90px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 0px rgba(0, 0, 0, .1);
        font-size: 18px;
    }

    .el-image-viewer__wrapper{
      right: 63% !important;
    }

</style>
